import React from 'react';

const PageNotFound = (props) => {
    return (
        <div className="page-not-found-component">
            <div className="no-result-svg-wrap">
                <img alt="Page not found" src={require("../assets/images/no-result-found2.png")} />
                <span className="unauthorised-result-text">{props.message || 'You are not authorized to access this feature'}</span>
            </div>
        </div>
    )
}
export default PageNotFound;