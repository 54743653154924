import { useState } from 'react';

const useSidebarHooks = () => {
    const [sidebar, setSidebar] = useState(false);
    const [mobMenu, setMobMenu] = useState(false);
    const [menuOpen, setMenuOpen] = useState('');

    const sidebarCollapse = () => {
        setSidebar(true);
    }
    const overlaySidebar = () => {
        setSidebar(false);
    }
    const mobileMenu = () => {
        mobMenu ? setMobMenu(false) : setMobMenu(true);
    }
    const openMenu = (section = '') => {
        switch (section) {
            case 'collection':
                if (menuOpen === 'collection') {
                    setMenuOpen('')
                } else {
                    setMenuOpen('collection')
                }
                break;
            case 'setting':
                if (menuOpen === 'setting') {
                    setMenuOpen('')
                } else {
                    setMenuOpen('setting')
                }
                break;
            default:
                setMenuOpen('')
                break;
        }
    }
    return {
        sidebarCollapse,
        overlaySidebar,
        mobileMenu,
        sidebar,
        mobMenu,
        openMenu,
        menuOpen
    };
}
export default useSidebarHooks;
