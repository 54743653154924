import React, { Component } from "react";

import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

class PrivateRoute extends Component {

    /**
     * Check if user is loggedin or not.
     * 
     * @return Boolean
     */
    isLoggedIn = () => {
        const { appState = {} } = this.props
        if (appState?.isLoggedIn !== undefined && appState?.isLoggedIn === true && appState?.user && appState?.user?.auth_token && appState?.user?.auth_token !== "") {
            return true
        } else {
            return false
        }
    }
    render() {
        if (this.isLoggedIn() === true) {
            return <Route {...this.props} />
        } else {
            localStorage.setItem('previousPath', this.props.location.pathname);
            return <Redirect to="/login" />
        }
    }
}

const mapStateToProps = state => {
    return {
        appState: state.app.appState,
    }
}
export default connect(mapStateToProps)(PrivateRoute);
