import React, { useContext } from 'react';
import AppContext from 'store/AppContext';
import { getFirstWord } from 'utility/helper';
import { decryptData } from 'utility/helper'

const GreetUser = () => {
    const { languageObj = {} } = useContext(AppContext)
    let local = decryptData(localStorage.getItem('appState'));
    let userName = "", firstName = "";
    if (local !== null) {
        userName = local["user"]["name"];
        firstName = getFirstWord(userName);
    }
    return (
        <div className="greet-text-section">
            <h5 className="page-heading"> {`${firstName}'s`} {languageObj.translate('Dashboard.1')}  </h5>
        </div>
    )
}
export default GreetUser;