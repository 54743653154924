import React, {useContext} from "react";
import AppContext from 'store/AppContext';

const ViewUserProfile = (props) => {
    const { editProfile, userDetail = {} } = props
    const { languageObj } = useContext(AppContext)

    return (
        <div className="user-info-card">
            <div className="user-info-header">
                <div className="user-info-img-wrap">

                    <img className="user-info-avtar" alt="" src={userDetail.profile_pic_url || require(`../../assets/images/user.png`)} />
                    <div className="user-text-wrap">
                        <span className="title">{userDetail.name || "NA"}</span>
                        <span className="subtitle">{userDetail.assigned_roles ? userDetail.assigned_roles.join(', ') : ""}</span>
                    </div>
                </div>
                <button type="button" className="ezi-btn btn-riple user-profile-edit" onClick={editProfile}>{languageObj.translate('collection.edit')}</button>
            </div>
            <div className="user-info-body">
                <div className="user-info-col">
                    <div className="lbl-control">
                        <label>{languageObj.translate('settings.first_name')}</label>
                        <div className="val-control">
                            <span className="fld-value">{userDetail.first_name || "NA"}</span>
                        </div>
                    </div>
                    <div className="lbl-control">
                        <label>{languageObj.translate('settings.last_name')}</label>
                        <div className="val-control">
                            <span className="fld-value">{userDetail.last_name || "NA"}</span>
                        </div>
                    </div>
                    <div className="lbl-control">
                        <label>{languageObj.translate('settings.primary_email')}</label>
                        <div className="val-control">
                            <span className="fld-value">{userDetail.email || "NA"}</span>
                        </div>
                    </div>
                    <div className="lbl-control">
                        <label>{languageObj.translate('settings.mobile_number')}</label>
                        <div className="val-control">
                            <span className="fld-value">{userDetail.cell_number || "NA"}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewUserProfile;