/**
 * Get Feature Access All Application.
 * 
 * @param {Object} accessControl All Accesses
 * @returns {Object} All Features Access.
 */
export const appAccess = (accessControl = null) => {
    return {
        /** Peoples Directory */
        view_peoples: (accessControl && accessControl["peoples"] !== undefined && accessControl["peoples"].includes("READ")),

        view_employee: (accessControl && accessControl["employee"] !== undefined && accessControl["employee"].includes("READ")),
        view_customer: (accessControl && accessControl["customer"] !== undefined && accessControl["customer"].includes("READ")),
        view_others: (accessControl && accessControl["others"] !== undefined && accessControl["others"].includes("READ")),

        delete_employee: (accessControl && accessControl["employee"] !== undefined && accessControl["employee"].includes("DELETE")),
        delete_customer: (accessControl && accessControl["customer"] !== undefined && accessControl["customer"].includes("DELETE")),
        delete_others: (accessControl && accessControl["others"] !== undefined && accessControl["others"].includes("DELETE")),

        edit_employee: (accessControl && accessControl["employee"] !== undefined && accessControl["employee"].includes("UPDATE")),
        edit_customer: (accessControl && accessControl["customer"] !== undefined && accessControl["customer"].includes("UPDATE")),
        edit_others: (accessControl && accessControl["others"] !== undefined && accessControl["others"].includes("UPDATE")),

        create_employee: (accessControl && accessControl["employee"] !== undefined && accessControl["employee"].includes("CREATE")),
        create_customer: (accessControl && accessControl["customer"] !== undefined && accessControl["customer"].includes("CREATE")),
        create_others: (accessControl && accessControl["others"] !== undefined && accessControl["others"].includes("CREATE")),

        /** Theme and Subthemes */
        create_theme: (accessControl && accessControl["themes"] !== undefined && accessControl["themes"].includes("CREATE")),
        update_theme: (accessControl && accessControl["themes"] !== undefined && accessControl["themes"].includes("UPDATE")),
        delete_theme: (accessControl && accessControl["themes"] !== undefined && accessControl["themes"].includes("DELETE")),
        /** Templates */
        analyse_template: (accessControl && accessControl["templates"] !== undefined && accessControl["templates"].includes("READ")),
        create_template: (accessControl && accessControl["templates"] !== undefined && accessControl["templates"].includes("CREATE")),
        update_template: (accessControl && accessControl["templates"] !== undefined && accessControl["templates"].includes("UPDATE")),
        delete_template: (accessControl && accessControl["templates"] !== undefined && accessControl["templates"].includes("DELETE")),
        /** Survey */
        create_survey: (accessControl && accessControl["survey"] !== undefined && accessControl["survey"].includes("CREATE")),
        edit_survey: (accessControl && accessControl["survey"] !== undefined && accessControl["survey"].includes("UPDATE")),
        delete_survey: (accessControl && accessControl["survey"] !== undefined && accessControl["survey"].includes("DELETE")),
        /** Poll */
        create_poll: (accessControl && accessControl["poll"] !== undefined && accessControl["poll"].includes("CREATE")),
        edit_poll: (accessControl && accessControl["poll"] !== undefined && accessControl["poll"].includes("UPDATE")),
        delete_poll: (accessControl && accessControl["poll"] !== undefined && accessControl["poll"].includes("DELETE")),
        /** User & Roles */
        users: (accessControl && accessControl["users"] !== undefined && accessControl["users"].includes("READ")),
        roles: (accessControl && accessControl["roles"] !== undefined && accessControl["roles"].includes("READ")),
        profiles: (accessControl && accessControl["profiles"] !== undefined && accessControl["profiles"].includes("READ")),

        /** Root Dashboard */
        analyze_survey: (accessControl && accessControl["survey"] !== undefined && accessControl["survey"].includes("READ")),
        user_manage: (accessControl && accessControl["account_management"] !== undefined && accessControl["account_management"].includes("READ")),

        /** Users */
        add_user: (accessControl && accessControl["users"] !== undefined && accessControl["users"].includes("CREATE")),
        edit_user: (accessControl && accessControl["users"] !== undefined && accessControl["users"].includes("UPDATE")),
        delete_user: (accessControl && accessControl["users"] !== undefined && accessControl["users"].includes("DELETE")),

        /** Roles */
        add_role: (accessControl && accessControl["roles"] !== undefined && accessControl["roles"].includes("CREATE")),
        edit_role: (accessControl && accessControl["roles"] !== undefined && accessControl["roles"].includes("UPDATE")),
        delete_role: (accessControl && accessControl["roles"] !== undefined && accessControl["roles"].includes("DELETE")),
        view_role: (accessControl && accessControl["roles"] !== undefined && accessControl["roles"].includes("READ")),

        /** Profile */
        add_profile: (accessControl && accessControl["profiles"] !== undefined && accessControl["profiles"].includes("CREATE")),
        edit_profile: (accessControl && accessControl["profiles"] !== undefined && accessControl["profiles"].includes("UPDATE")),
        delete_profile: (accessControl && accessControl["profiles"] !== undefined && accessControl["profiles"].includes("DELETE")),
        view_profile: (accessControl && accessControl["profiles"] !== undefined && accessControl["profiles"].includes("READ")),

        /**Company Profile */
        update_company: (accessControl && accessControl["company_profile"] !== undefined && accessControl["company_profile"].includes("UPDATE")),

        /**SMS settings */
        create_sms_setting: (accessControl && accessControl["sms_setting"] !== undefined && accessControl["sms_setting"].includes("CREATE")),
        edit_sms_setting: (accessControl && accessControl["sms_setting"] !== undefined && accessControl["sms_setting"].includes("UPDATE")),

        /**Report Dashboard */
        create_report: (accessControl && accessControl["report_dashboard"] !== undefined && accessControl["report_dashboard"].includes("CREATE")),
        edit_report: (accessControl && accessControl["report_dashboard"] !== undefined && accessControl["report_dashboard"].includes("UPDATE")),
        delete_report: (accessControl && accessControl["report_dashboard"] !== undefined && accessControl["report_dashboard"].includes("DELETE")),
        analyse_report: (accessControl && accessControl["report_dashboard"] !== undefined && accessControl["report_dashboard"].includes("READ")),

        /**Survey Workflow */
        create_survey_workflow: (accessControl && accessControl["survey_workflow"] !== undefined && accessControl["survey_workflow"].includes("CREATE")),
        edit_survey_workflow: (accessControl && accessControl["survey_workflow"] !== undefined && accessControl["survey_workflow"].includes("UPDATE")),
        delete_survey_workflow: (accessControl && accessControl["survey_workflow"] !== undefined && accessControl["survey_workflow"].includes("DELETE")),
        analyse_survey_workflow: (accessControl && accessControl["survey_workflow"] !== undefined && accessControl["survey_workflow"].includes("READ")),

        /**KPI settings */
        create_kpi_settings: (accessControl && accessControl["kpi_setting"] !== undefined && accessControl["kpi_setting"].includes("CREATE")),
        edit_kpi_settings: (accessControl && accessControl["kpi_setting"] !== undefined && accessControl["kpi_setting"].includes("UPDATE")),
        delete_kpi_settings: (accessControl && accessControl["kpi_setting"] !== undefined && accessControl["kpi_setting"].includes("DELETE")),
        analyse_kpi_settings: (accessControl && accessControl["kpi_setting"] !== undefined && accessControl["kpi_setting"].includes("READ")),

        /**Recurring settings */
        create_recurring_settings: (accessControl && accessControl["recurring_setting"] !== undefined && accessControl["recurring_setting"].includes("CREATE")),
        edit_recurring_settings: (accessControl && accessControl["recurring_setting"] !== undefined && accessControl["recurring_setting"].includes("UPDATE")),
        delete_recurring_settings: (accessControl && accessControl["recurring_setting"] !== undefined && accessControl["recurring_setting"].includes("DELETE")),
        analyse_recurring_settings: (accessControl && accessControl["recurring_setting"] !== undefined && accessControl["recurring_setting"].includes("READ")),

        /**SMTP settings */
        create_smtp_setting: (accessControl && accessControl["smtp_setting"] !== undefined && accessControl["smtp_setting"].includes("CREATE")),
        edit_smtp_setting: (accessControl && accessControl["smtp_setting"] !== undefined && accessControl["smtp_setting"].includes("UPDATE")),
        delete_smtp_setting: (accessControl && accessControl["smtp_setting"] !== undefined && accessControl["smtp_setting"].includes("DELETE")),
        analyse_smtp_setting: (accessControl && accessControl["smtp_setting"] !== undefined && accessControl["smtp_setting"].includes("READ")),

        /**Google settings */
        create_google_setting: (accessControl && accessControl["google_setting"] !== undefined && accessControl["google_setting"].includes("CREATE")),
        edit_google_setting: (accessControl && accessControl["google_setting"] !== undefined && accessControl["google_setting"].includes("UPDATE")),
        delete_google_setting: (accessControl && accessControl["google_setting"] !== undefined && accessControl["google_setting"].includes("DELETE")),
        analyse_google_setting: (accessControl && accessControl["google_setting"] !== undefined && accessControl["google_setting"].includes("READ")),


        /**Whatsapp settings */
        create_whatsapp_setting: (accessControl && accessControl["whatsapp_setting"] !== undefined && accessControl["whatsapp_setting"].includes("CREATE")),
        edit_whatsapp_setting: (accessControl && accessControl["whatsapp_setting"] !== undefined && accessControl["whatsapp_setting"].includes("UPDATE")),

        /**Slack settings */
        create_slack_setting: (accessControl && accessControl["slack_setting"] !== undefined && accessControl["slack_setting"].includes("CREATE")),
        edit_slack_setting: (accessControl && accessControl["slack_setting"] !== undefined && accessControl["slack_setting"].includes("UPDATE")),

        /**Collections */
        // create_collections: (accessControl && accessControl["collections"] !== undefined && accessControl["collections"].includes("CREATE")),
        // edit_collections: (accessControl && accessControl["collections"] !== undefined && accessControl["collections"].includes("UPDATE")),
        // delete_collections: (accessControl && accessControl["collections"] !== undefined && accessControl["collections"].includes("DELETE")),
        // analyse_collections: (accessControl && accessControl["collections"] !== undefined && accessControl["collections"].includes("READ")),


        /**Action Plan */
        create_action_plan: (accessControl && accessControl["action_planning"] !== undefined && accessControl["action_planning"].includes("CREATE")),
        edit_action_plan: (accessControl && accessControl["action_planning"] !== undefined && accessControl["action_planning"].includes("UPDATE")),
        delete_action_plan: (accessControl && accessControl["action_planning"] !== undefined && accessControl["action_planning"].includes("DELETE")),
        analyse_action_plan: (accessControl && accessControl["action_planning"] !== undefined && accessControl["action_planning"].includes("READ")),


        /**Raw Data */
        create_raw_data: (accessControl && accessControl["raw_data"] !== undefined && accessControl["raw_data"].includes("CREATE")),
        edit_raw_data: (accessControl && accessControl["raw_data"] !== undefined && accessControl["raw_data"].includes("UPDATE")),
        delete_raw_data: (accessControl && accessControl["raw_data"] !== undefined && accessControl["raw_data"].includes("DELETE")),
        analyse_raw_data: (accessControl && accessControl["raw_data"] !== undefined && accessControl["raw_data"].includes("READ")),
        
        /**Participant feedback */
        create_participant_feedback: (accessControl && accessControl["participant_feedback"] !== undefined && accessControl["participant_feedback"].includes("CREATE")),
        edit_participant_feedback: (accessControl && accessControl["participant_feedback"] !== undefined && accessControl["participant_feedback"].includes("UPDATE")),
        delete_participant_feedback: (accessControl && accessControl["participant_feedback"] !== undefined && accessControl["participant_feedback"].includes("DELETE")),
        analyse_participant_feedback: (accessControl && accessControl["participant_feedback"] !== undefined && accessControl["participant_feedback"].includes("READ")),

        /**Score Sheet */
        create_score_sheet: (accessControl && accessControl["score_sheet"] !== undefined && accessControl["score_sheet"].includes("CREATE")),
        edit_score_sheet: (accessControl && accessControl["score_sheet"] !== undefined && accessControl["score_sheet"].includes("UPDATE")),
        delete_score_sheet: (accessControl && accessControl["score_sheet"] !== undefined && accessControl["score_sheet"].includes("DELETE")),
        analyse_score_sheet: (accessControl && accessControl["score_sheet"] !== undefined && accessControl["score_sheet"].includes("READ")),
        
        /**Presentation */
        create_presentation: (accessControl && accessControl["presentation"] !== undefined && accessControl["presentation"].includes("CREATE")),
        edit_presentation: (accessControl && accessControl["presentation"] !== undefined && accessControl["presentation"].includes("UPDATE")),
        delete_presentation: (accessControl && accessControl["presentation"] !== undefined && accessControl["presentation"].includes("DELETE")),
        analyse_presentation: (accessControl && accessControl["presentation"] !== undefined && accessControl["presentation"].includes("READ")),


        /**Report Insight */
        report_insight_create: (accessControl && accessControl["report_insight"] !== undefined && accessControl["report_insight"].includes("CREATE")),
        report_insight_edit: (accessControl && accessControl["report_insight"] !== undefined && accessControl["report_insight"].includes("UPDATE")),
        report_insight_delete: (accessControl && accessControl["report_insight"] !== undefined && accessControl["report_insight"].includes("DELETE")),
        report_insight_analyse: (accessControl && accessControl["report_insight"] !== undefined && accessControl["report_insight"].includes("READ")),

        /**LifeCycle Access */
        lifecycle_create: (accessControl && accessControl["lifecycle"] !== undefined && accessControl["lifecycle"].includes("CREATE")),
        lifecycle_edit: (accessControl && accessControl["lifecycle"] !== undefined && accessControl["lifecycle"].includes("UPDATE")),
        lifecycle_delete: (accessControl && accessControl["lifecycle"] !== undefined && accessControl["lifecycle"].includes("DELETE")),
        lifecycle_analyse: (accessControl && accessControl["lifecycle"] !== undefined && accessControl["lifecycle"].includes("READ")),

    }
}