import React, { Fragment, useState, useEffect } from 'react';
import App from "./App"
import { connect } from "react-redux";
import * as AppActions from "store/actions";
import Http from "axios";
import PageNotFound from 'components/PageNotFound';
import AppLoading from 'components/AppLoading';
import configURL from 'config/config';
import FormData from 'utility/AppFormData';
import FeedbackApp from "./FeedbackApp";


const Container = ({ dispatchVenderInfo }) => {
    const [loading, setLoading] = useState(true)
    const [isVerified, setIsVerified] = useState(false)
    const [themeColor, setThemeColor] = useState()
    const [copyright, setCopyright] = useState(false)
    const [copyRightText, setCopyRightText] = useState()
    const [loginImage, setLoginImage] = useState()
    const [feedbackPlatform, setFeedbackPlatform] = useState(false)
    const [accountID, setAccountID] = useState(false)
    const [microsoftLogin, setMicrosoftLogin] = useState()

    /**
     * Update Document title and favicon
     * @param {String} title 
     * @param {String} logo
     */
    const updateAppMeta = (title = null, logo = null) => {
        if (logo && logo !== "") {
            let link = document.querySelector("link[rel*='icon']")
            link.rel = 'icon';
            link.href = logo
        }
        if (title && title !== "") {
            document.title = title
        }
    }

    /**
     * Vendor verification
     */
    const verifyVendorDetails = () => {
        let vendorName;
        if (window.location.host || window.location.hostname) {
            vendorName = window.location.hostname
            if(vendorName === "testcom.app.teamqaizenx.com")
            {
                window.location.href = "https://changeurl.app.teamqaizenx.com"
            }
            if (!vendorName) vendorName = window.location.host
            let webAddress = vendorName.split('.')
            let feedbackUser = webAddress.includes("feedback")
            if(feedbackUser)
            {
                setFeedbackPlatform(true)
            }
            vendorName = vendorName.replace("feedback","app")
            let formData = new FormData()
            formData.append('type', 'company')
            formData.append('name', vendorName)
            Http.post(configURL.verify_vendor, formData).then(response => {
                if (response.data.success && response.data.success === true) {
                    setLoading(false)
                    setIsVerified(true)
                    updateAppMeta(response.data.vendorName, response.data.logoUrl)
                    dispatchVenderInfo({ name: response.data.vendorName, logo: response.data.logoUrl, webaddress: vendorName, shortLogo: response.data.short_company_logo})
                    setThemeColor(response.data.accountThemeColor ? response.data.accountThemeColor : response.data.partnerThemeColor)
                    setCopyright(response.data?.copyright || false)
                    setCopyRightText(response.data?.companyPoweredBy)
                    setLoginImage(response.data?.loginPageImg)
                    setAccountID(response.data?.account_id)
                    setMicrosoftLogin(response.data?.sso)
                } else {
                    setLoading(false)
                    setIsVerified(false)
                }
            }).catch(err => {
                console.log(err);
                setLoading(false)
                setIsVerified(false)
            })
        }
    }

    const isElectron = () => {
        // Renderer process
        if (typeof window !== 'undefined' && typeof window.process === 'object' && window.process.type === 'renderer') {
            return true;
        }
    
        // Main process
        if (typeof process !== 'undefined' && typeof process.versions === 'object' && !!process.versions.electron) {
            return true;
        }
    
        // Detect the user agent when the `nodeIntegration` option is set to true
        if (typeof navigator === 'object' && typeof navigator.userAgent === 'string' && navigator.userAgent.indexOf('Electron') >= 0) {
            return true;
        }
    
        return false;
    }

    useEffect(verifyVendorDetails, []);
    return (    
        <Fragment>
            {(!loading && !isVerified) && <PageNotFound message='Page not found' />}
            {(isVerified && !feedbackPlatform) && <App name="Qaizenx" themeColor={themeColor} copyright={copyright} copyRightText={copyRightText} loginImage={loginImage} />}
            {(isVerified && feedbackPlatform) && <FeedbackApp name="Qaizenx Feedback" themeColor={themeColor} copyright={copyright} copyRightText={copyRightText} loginImage={loginImage} isElectron={isElectron()} accountID={accountID} microsoftLogin={microsoftLogin} />}
            {loading && <AppLoading message='Loading' />}
        </Fragment>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        dispatchVenderInfo: (info) => dispatch(AppActions.initVendor(info))
    }
}
export default connect(null, mapDispatchToProps)(Container);