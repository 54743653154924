import axios from "axios";
import { toast } from 'react-toastify';
import { APP_FIELD_NAME, APP_ID } from "constants/constants";
import { decryptData } from 'utility/helper'

let notificationTimer = null;
const Axios = axios.create();
var errorList = [];
/**
 * Here Modify request data if needed like Login token, company ids
 */
Axios.interceptors.request.use(request => {

    const appStore = decryptData(localStorage.getItem("appState"))
    const language = localStorage.getItem("i18nextLng");
    if (appStore && appStore.user && appStore.user !== null) {
        if(appStore?.platform === "feedback")
        {
            let { auth_token = ""} = appStore.user;
            // request.data.append("platform", 'feedback');
            request.headers["Authorization"] = `Bearer ${auth_token}`;
        }
        else
        {
            let { auth_token = "", company_id = "", id = "", partner_id = "" } = appStore.user;
            if (request.data instanceof FormData) {
                request.data.append("company_id", company_id);
                request.data.append("partner_id", partner_id);
                request.data.append("user_id", id);
                request.data.append("language_id", language);
                request.data.append(APP_FIELD_NAME, APP_ID);
            } else {
                request.data = new FormData();
                request.data.append("company_id", company_id)
                request.data.append("user_id", id);
                request.data.append("language_id", language);
                request.data.append("partner_id", partner_id);
                request.data.append(APP_FIELD_NAME, APP_ID);
            }
            request.headers["Authorization"] = `Bearer ${auth_token}`;
        }
       
    } else {
        let returnURL = window.location.href;
        clearTimeout(notificationTimer);
        notificationTimer = setTimeout(() => {
            toast.warn("Login Expired redirecting to login page.");
            if(returnURL)
            {
                window.location = "/login/?redirectto="+returnURL;
            }
            else
            {
                window.location = "/login";
            }
        }, 100);
    }
    return request;
});

/**
 * Intercept Responses and check if response status os OK.
 */
Axios.interceptors.response.use(response => {
    /**
     * Added temperory solution to check expired data.
     */
    errorList = [];
    return response;
}, error => {
    clearTimeout(notificationTimer);
    if (!error.response) {
        notificationTimer = setTimeout(() => {
            console.log(error)
            // toast.warn("Server is not responding please try after some time.")
        }, 300);
    }

    if (error.response && error.response.status) {
        const { data = {}, status = 500, config = {} } = error.response
        let isElectron = false;
        for (var fdata of config.data.entries()) {
           if(fdata[0] === "isElectron" && (fdata[1] === 'true' || fdata[1] === true))
            {
                isElectron = true;    
            } 
        }

        switch (status) {
            case 401:
                if (!errorList.includes(401)) {
                    toast.info(data.message || "Login expired");
                    setTimeout(() => {
                        localStorage.setItem("isExpire", true);
                        window.location = "/login";
                    }, 400);
                    errorList.push(401);
                }
                break;
            case 403:
                if (!errorList.includes(403)) {
                    toast.info(data.message || "Login expired");
                    setTimeout(() => {
                        localStorage.setItem("isExpire", true);
                        window.location = "/login";
                    }, 400);
                    errorList.push(403);
                }
                break;
            case 500:
                if (!errorList.includes(500) && !isElectron) {
                    notificationTimer = setTimeout(() => {
                        toast.warn(data.message || "Something is not right here.");
                    }, 300);
                    errorList.push(500);
                }
                break;
            default:
                break
        }
    }
    return Promise.reject(error);
});

export default Axios;
