export const CUSTOM_LANGUAGES = {
    hi: {
        label: "हिन्दी",
        localeStrings: {
            pagePrevText: "पिछला",
            pageNextText: "अगला",
            completeText: "पूर्ण",
            startSurveyText: "शुरू करें",
            previewText: "पूर्वावलोकन",
            editText: "संपादित करें",
            clearCaption: "मिटाएं"
        }
    },
    mh: {
        label: "मराठी",
        localeStrings: {
            pagePrevText: "मागचे",
            pageNextText: "पुढे",
            completeText: "पूर्ण",
            startSurveyText: "सुरु करा",
            previewText: "पूर्वावलोकन",
            editText: "सुधारणे",
            clearCaption: "पुसून टाका"
        }
    },
    gu: {
        label: "ગુજરાતી",
        localeStrings: {
            pagePrevText: "પૂર્વાવલોકન",
            pageNextText: "આગળ",
            completeText: "પૂર્ણ",
            startSurveyText: "શરૂઆત",
            previewText: "પૂર્વાવલોકન",
            editText: "સંપાદિત કરો",
            clearCaption: "ભુસવું"
        }
    },
    ta: {
        label: "தமிழ்",
        localeStrings: {
            pagePrevText: "முந்தையது",
            pageNextText: "அடுத்தது",
            completeText: "முழுமை",
            startSurveyText: "தொடங்கு",
            previewText: "முன்னோட்ட",
            editText: "தொகு",
            clearCaption: "அழி"
        }
    },
    te: {
        label: "తెలుగు",
        localeStrings: {
            pagePrevText: "ఇంతకు ముంద",
            pageNextText: "తరువాత",
            completeText: "పూర్తయింది",
            startSurveyText: "ప్రారంభించండి",
            previewText: "పరిదృశ్యం",
            editText: "సవరించండి",
            clearCaption: "క్లియర్"
        }
    },
    pa: {
        label: "ਪੰਜਾਬੀ",
        localeStrings: {
            pagePrevText: "ਪਿਛਲਾ",
            pageNextText: "ਅਗਲਾ",
            completeText: "ਮੁਕੰਮਲ",
            startSurveyText: "ਸ਼ੁਰੂ",
            previewText: "ਝਲਕ",
            editText: "ਸੰਪਾਦਿਤ ਕਰੋ",
            clearCaption: "ਮਿਟਾਓ"
        }
    },
    as: {
        label: "অসমীয়া",
        localeStrings: {
            pagePrevText: "পূববৰ্তী",
            pageNextText: "পৰবৰ্তী",
            completeText: "পূর্ণ",
            startSurveyText: "আৰম্ভ",
            previewText: "পূৰ্বলোকন",
            editText: "সম্পাদনা",
            clearCaption: "পৰিষ্কাৰ"
        }
    },
    or: {
        label: "ଓଡିଆ",
        localeStrings: {
            pagePrevText: "ପୂର୍ବବର୍ତ୍",
            pageNextText: "ପରବର୍ତ୍ତି",
            completeText: "ସଂପୂର୍ଣ୍ଣ",
            startSurveyText: "ଆରମ୍ଭ",
            previewText: "ପୂର୍ବାବଲୋକନ",
            editText: "ସମ୍ପାଦନ",
            clearCaption: "ଲିଭାନ୍ତୁ"
        }
    },
    ml: {
        label: "മലയാളം",
        localeStrings: {
            pagePrevText: "മുൻപത്തേത്",
            pageNextText: "അടുത്തത്",
            completeText: "പൂർത്തിയായി",
            startSurveyText: "ആരംഭിക്കുക",
            previewText: "പിവു",
            editText: "എഡിറ്റുചെയ്യുക",
            clearCaption: "മായ്ക്കുക"
        }
    },
    kn: {
        label: "ಕನ್ನಡ",
        localeStrings: {
            pagePrevText: "ಹಿಂದಿನ",
            pageNextText: "ಸಮೀಪ",
            completeText: "ಪೂರ್ಣಗೊಂಡಿದೆ",
            startSurveyText: "ಪ್ರಾರಂಭ",
            previewText: "ಮುನ್ನೋಟ",
            editText: "ತಿದ್ದು",
            clearCaption: "ಅಳಿಸು"
        }
    },
    bn: {
        label: "বাংলা",
        localeStrings: {
            pagePrevText: "পূর্ববর্তী",
            pageNextText: "পরবর্তী",
            completeText: "সম্পূর্ণ",
            startSurveyText: "শুরু করুন",
            previewText: "পূর্বরূপ",
            editText: "সম্পাদনা করুন",
            clearCaption: "পরিষ্কার "
        }
    },
    sl: {
        label: "Sinhala",
        localeStrings: {
            pagePrevText: "කලින්",
            pageNextText: "ඊළඟ",
            completeText: "සම්පූර්ණයි",
            startSurveyText: "පටන් ගත්තා",
            previewText: "පෙරදසුන",
            editText: "සංස්කරණය කරන්න",
            clearCaption: "පැහැදිලිව"
        }
    },
    kok: {
        label: "Konkani",
        localeStrings: {
            pagePrevText: "आदलें",
            pageNextText: "फुडलो",
            completeText: "पुराय",
            startSurveyText: "सुरू केल्लें",
            previewText: "पूर्वावलोकन करचें",
            editText: "संपादन करचें",
            clearCaption: "स्पश्टपणान सांगलां"
        }
    },
    ne: {
        label: "Nepali",
        localeStrings: {
            pagePrevText: "पुरानो",
            pageNextText: "अर्को",
            completeText: "सम्पूर्ण",
            startSurveyText: "सुरु भयो",
            previewText: "पूर्वावलोकन गर्नुहोस्",
            editText: "सम्पादन गर्नुहोस्",
            clearCaption: "स्पष्ट रुपमा भनिएको छ"
        }
    },
   ku: {
        label: "کوردی",
        localeStrings: {
            pagePrevText: "پێشتر",
            pageNextText: "دواتر",
            completeText: "تەواو کردنی راپرسیەکە",
            startSurveyText: "دەست پێ دەکات",
            previewText: "پێشبینین",
            editText: "دەستکاری",
            clearCaption: "سپاڵ"
        }
    },
    ar: {
        label: "العربية",
        localeStrings: {
            pagePrevText: "السابق",
            pageNextText: "التالي",
            completeText: "إكمال الاستبيان",
            startSurveyText: "بداية",
            previewText: "معاينة",
            editText: "مسح",
            clearCaption: "تعديل"
        }
    },

}

export const LOCALES_CUSTOM_STRINGS = {
    unanswered_question: {
        "en": "It seems that some of the questions are left unanswered. Please review it again.",
        "bn": "দেখে মনে হচ্ছে যে কয়েকটি প্রশ্নের উত্তর ছাড়াই রয়ে গেছে। আবার এটি পর্যালোচনা করুন।",
        "kn": "ಕೆಲವು ಪ್ರಶ್ನೆಗಳಿಗೆ ಉತ್ತರಿಸದೆ ಉಳಿದಿದೆ ಎಂದು ತೋರುತ್ತದೆ. ದಯವಿಟ್ಟು ಅದನ್ನು ಮತ್ತೊಮ್ಮೆ ಪರಿಶೀಲಿಸಿ.",
        "ml": "ചില ചോദ്യങ്ങൾക്ക് ഉത്തരം ലഭിച്ചിട്ടില്ലെന്ന് തോന്നുന്നു. ഇത് വീണ്ടും അവലോകനം ചെയ്യുക.",
        "or": "ଏଥିରୁ ଜଣାପଡୁଛି ଯେ କିଛି ପ୍ରଶ୍ନ ଉତ୍ତରହୀନ ରହିଯାଇଛି | ଦୟାକରି ଏହାକୁ ପୁନର୍ବାର ସମୀକ୍ଷା କରନ୍ତୁ.",
        "as": "দেখে মনে হচ্ছে যে কয়েকটি প্রশ্নের উত্তর ছাড়াই রয়ে গেছে। আবার এটি পর্যালোচনা করুন.",
        "pa": "ਅਜਿਹਾ ਲਗਦਾ ਹੈ ਕਿ ਕੁਝ ਪ੍ਰਸ਼ਨ ਉੱਤਰ ਰਹਿ ਗਏ ਹਨ. ਕਿਰਪਾ ਕਰਕੇ ਇਸਦੀ ਦੁਬਾਰਾ ਸਮੀਖਿਆ ਕਰੋ.",
        "te": "కొన్ని ప్రశ్నలకు సమాధానం ఇవ్వబడలేదని తెలుస్తోంది. దయచేసి దీన్ని మళ్ళీ సమీక్షించండి.",
        "ta": "சில கேள்விகளுக்கு விடை காணப்படவில்லை என்று தெரிகிறது. அதை மீண்டும் மதிப்பாய்வு செய்யவும்.",
        "gu": "એવું લાગે છે કે કેટલાક પ્રશ્નોના જવાબો બાકી છે. કૃપા કરી તેની ફરી સમીક્ષા કરો.",
        "mh": "असे दिसते की काही प्रश्न अनुत्तरित राहिले आहेत. कृपया त्याचे पुन्हा पुनरावलोकन करा.",
        "hi": "ऐसा लगता है कि कुछ प्रश्न अनुत्तरित रह गए हैं। कृपया इसकी पुन: समीक्षा करें।",
        "sl": "සමහර ප්‍රශ්නවලට පිළිතුරු නොමැතිව ඉතිරි වී ඇති බව පෙනේ. කරුණාකර එය නැවත සමාලෝචනය කරන්න.",
        "kok": "कांय प्रस्न जाप नासतना उरल्यात अशें दिसता. उपकार करून ताचो परतून नियाळ घेयात.",
        "ne": "कतिपय प्रश्नहरु अनुत्तरित नै रहेको देखिन्छ । कृपया यसलाई पुन: समीक्षा गर्नुहोस्।",
        "ku":"به نظر میرسد که برخی از سوالها بی پاسخ ماندهاند. لطفاً آنها را مرور کنید.",
        "ar":"يبدو أن بعض الأسئلة لم يتم الرد عليها. يرجى مراجعتها مرة أخرى.",
    }
}
export const QUESTION_PROPERTY_VISIBILITY = {
    nps_rating: ["title", "description", "isRequired", "visible", 'visibleIf', 'readOnly', 'hasParentQuestion', 'hasComment', 'commentText', 'showClearButton', 'isOverriding', 'hasCalculation', 'doNotShowOnReports', 'displayInReports', 'isFixedQuestion', 'isOutcomeStatement', 'isKeyDrivers', 'isReason', 'isEmailQuestion', 'isExcludedFromGm'],
    rating_csat: ["title", "description", "isRequired", "visible", 'visibleIf', 'readOnly', 'hasParentQuestion', 'hasComment', 'commentText', 'showClearButton', 'isOverriding', 'hasCalculation', 'csatScores', 'doNotShowOnReports', 'displayInReports', 'isFixedQuestion', 'isOutcomeStatement', 'isKeyDrivers', 'isReason', 'isEmailQuestion', 'isExcludedFromGm'],
    smily: ["title", "description", "isRequired", "visible", 'visibleIf', 'readOnly', 'hasParentQuestion', 'hasComment', 'commentText', 'showClearButton', 'isOverriding', 'hasCalculation', 'doNotShowOnReports', 'displayInReports', 'isFixedQuestion', 'isOutcomeStatement', 'isKeyDrivers', 'isReason', 'isExcludedFromGm'],
    yesno: ["title", "description", "isRequired", "visible", 'visibleIf', 'readOnly', 'hasParentQuestion', 'valueName', 'defaultValue', 'showClearButton', 'doNotShowOnReports', 'displayInReports', 'isFixedQuestion', 'isOutcomeStatement', 'isKeyDrivers', 'isReason'],
    sortablelist: ['isIdentifier', 'isObject', 'isFixedQuestion', 'isOutcomeStatement', 'isKeyDrivers', 'isReason'],
    barrating: ['isIdentifier', 'isObject', 'isFixedQuestion', 'isOutcomeStatement', 'isKeyDrivers', 'isReason', 'isExcludedFromGm'],
    hiv2: ["title", "description", "isRequired", "visible", 'visibleIf', 'readOnly', 'hasParentQuestion', 'hasComment', 'commentText', 'showClearButton', 'isOverriding', 'isIdentifier', 'hasCalculation', 'choices', 'isObject', 'isFixedQuestion', 'isOutcomeStatement', 'isKeyDrivers', 'isReason', 'isExcludedFromGm'],
    camera: ["title", "description", "isRequired", "visible", 'visibleIf', 'readOnly', 'hasParentQuestion', 'hasComment', 'commentText', 'showClearButton', 'isOverriding', 'choices', 'hasCalculation', 'doNotShowOnReports', 'displayInReports', 'isFixedQuestion', 'isOutcomeStatement', 'isKeyDrivers', 'isReason', 'isExcludedFromGm'],
}

export const SHOWOVERRIDINGCONDITION = ["NPS-V3", "CSAT-V3", "CSAT-V5", "HI-V3", 'HI-V4', 'Pulse', "ESAT-V3"]
export const TENSCALEQUESTIONALLOWED = ["NPS-V3"]
export const SECRETPASS = "XkhZG4fW2t2W";
export const SHOWISOOJECTCONDITION = ["CHECKLIST"];

export const ESCORTFETCHLIST = [{ "question1": "104658", "question2": "Shalik Ram Banjare", "question3": "Agri Machinery Business Division" }]
