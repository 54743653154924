import React, { useState, useRef, useEffect, useContext } from 'react';
import useOutsideClick from 'hooks/useOutsideClick';
import { useHistory } from "react-router-dom";
import Axios from "utility/Axios";
import configURL from "config/config";
import AppContext from 'store/AppContext';
// import { BiEnvelope } from "react-icons/bi";

const NavbarNotifications = () => {
    const [notificationOpen, setNotificationOpen] = useState(false)
    const [notifications, setNotifications] = useState([])
    const notificationRef = useRef()
    const history = useHistory();
    const MINUTE_MS = 60000;
    const { languageObj } = useContext(AppContext)

    useOutsideClick(notificationRef, () => {
        notificationOpen && setNotificationOpen(false)
    })

    const getAllNotifications = () => {
        let formData = new FormData()
        formData.append("per_page", 5)
        formData.append("status", 'unread')
        Axios.post(configURL.get_notifications, formData).then(response => {
            if (response.data.success === true) {
                setNotifications(response.data.results)
            }
        }).catch(err => {
            console.log(err);
        })
    }

    // const handleReadAll = (notificationsTypeID) => {
    //     // const notificationIds = notifications.map(el => el.id)
    //     let formData = new FormData()
    //     if (notificationsTypeID) {
    //         formData.append("notification_type_id", notificationsTypeID)
    //     }
    //     // Axios.post(configURL.update_notifications, formData).catch(err => {
    //     Axios.post(configURL.update_notifications, formData).catch(err => {
    //         console.log(err);
    //     })
    //     setNotificationOpen(false)
    // }

    const allNotifications = (item) => {
        setNotificationOpen(false)
        history.push({
            pathname: '/notifications/',
            notification_type_id: item
        })
    }


    useEffect(() => {
        if (!notificationOpen) {
            //setNotifications([])
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notificationOpen])

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         getAllNotifications()
    //     }, MINUTE_MS);
    //     return () => clearInterval(interval);
    // }, [])

    return (
        <div className={`notification ${(notificationOpen) ? 'notifications' : ''}`} ref={notificationRef}>
            <span className={`noti-icon ${notifications.length > 0 ? 'has-noti' : ''}`} onClick={() => setNotificationOpen(true)}></span>
            <div className={`notifications-wrapper`}>
                <ul className="notifications-lists">
                    {notifications.length > 0 && notifications.map((el, i) => (
                        <li key={i}>
                            <div className="messages-wrapper">
                                {/* <div>
                                    <span className="read-mark"><BiEnvelope title="Mark all read" /></span>
                                </div> */}
                                <div className="notifi-count">
                                    <span className="user-response" onClick={() => allNotifications(el.notification_type_id)}>{el.title}</span>
                                    <span className='count'>{el.count}</span>
                                </div>
                                {/* <div><span className="read-mark" onClick={() => handleReadAll(el.notification_type_id)}  ><BiEnvelope title="Mark all read" /></span></div> */}
                                {/* <div className="user-response"> */}
                                {/* <p className="user-name">{el.user_name || 'Anonymous'}</p> */}
                                {/* <span className="user-msg">{el.title} </span> */}
                                {/* </div> */}
                            </div>
                        </li>
                    ))}
                    {notifications.length === 0 && <p className="no-result">{languageObj.translate('user.no_new_notifications')}</p>}
                    {/* <div className="load-more-container">
                        {notifications.length > 0 && <button className="mark-read" type="button" onClick={handleReadAll}>Mark all read</button>}
                        <button type="button" className="view-all" onClick={() => {
                            setNotificationOpen(false)
                            history.push('/notifications')
                        }}>View all</button>
                    </div> */}
                    <div className='text-center btn-view-block'>
                        <button type="button" onClick={() => allNotifications("ALL")} className='btn-view'>{languageObj.translate('user.view_all')}</button>
                    </div>
                </ul>

            </div>
        </div >
    )
}

export default NavbarNotifications