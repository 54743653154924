import React from 'react'

const PeopleDirectory = React.lazy(() => import('pages/PeopleDirectory/PeopleDirectory'))
const BulkUpload = React.lazy(() => import('pages/BulkUpload/BulkUpload'))
const EmployeeAdd = React.lazy(() => import('pages/AddSingle/EmployeeAdd'))
const Identifier = React.lazy(() => import('pages/Identifier/Identifier'))
const ValidateData = React.lazy(() => import('pages/ValidateData/ValidateData'))
const Dashboard = React.lazy(() => import('pages/Dashboard/Dashboard'))
const UserProfile = React.lazy(() => import('pages/Profile/UserProfile'))
const Themes = React.lazy(() => import('pages/Themes/Themes'))
const Subthemes = React.lazy(() => import('pages/Themes/Subthemes'))
const UsersRoles = React.lazy(() => import('pages/UsersRoles/UsersRoles'))
const Users = React.lazy(() => import('pages/UsersRoles/Users/Users'))
const UsersView = React.lazy(() => import('pages/UsersRoles/Users/UsersView'))
const SaveUser = React.lazy(() => import('pages/UsersRoles/Users/SaveUser'))
const Profiles = React.lazy(() => import('pages/UsersRoles/Profiles/Profiles'))
const Roles = React.lazy(() => import('pages/UsersRoles/Roles/Roles'))
const CustomerAdd = React.lazy(() => import('pages/AddSingle/CustomerAdd'))
const OtherContactAdd = React.lazy(() => import('pages/AddSingle/OtherContactAdd'))
const OnlineUpdate = React.lazy(() => import('pages/OnlineUpdate/OnlineUpdate'))
const BulkUpdate = React.lazy(() => import('pages/BulkUpdate/BulkUpdate'))
const SurveyDashboard = React.lazy(() => import('pages/Survey/SurveyDashboard'))
const SurveyCategory = React.lazy(() => import('pages/Survey/SurveyCategory'))
const CategoryAdd = React.lazy(() => import('pages/Survey/CategoryAdd'))
const SurveyAdd = React.lazy(() => import('pages/Survey/SurveyAdd'))
const SurveyCreator = React.lazy(() => import('pages/Survey/SurveyCreator'))
const SurveyPreview = React.lazy(() => import('pages/Survey/SurveyPreview/SurveyPreview'))
const TemplateDashboard = React.lazy(() => import('pages/Template/Dashboard/TemplateDashboard'))
const TemplateCategory = React.lazy(() => import('pages/Template/Category/TemplateCategory'))
const AddCategory = React.lazy(() => import('pages/Template/Category/AddCategory'))
const TemplateAdd = React.lazy(() => import('pages/Template/Dashboard/TemplateAdd'))
const CategoryTemplateDashboard = React.lazy(() => import('pages/Template/Category/CategoryTemplates'))
const CategorySurveyDashboard = React.lazy(() => import('pages/Survey/CategorySurveyDashboard/CategorySurveyDashboard'))
const TemplateBuilder = React.lazy(() => import('pages/Template/Builder/TemplateBuilder'))
const SurveyReport = React.lazy(() => import('pages/Dashboard/SurveyReport/SurveyReport'))
const ProfileDetails = React.lazy(() => import('pages/UsersRoles/Profiles/ProfileDetails'))
const SaveRole = React.lazy(() => import('pages/UsersRoles/Roles/SaveRole'))
const RoleView = React.lazy(() => import('pages/UsersRoles/Roles/RoleView'))
const CustomerExperience = React.lazy(() => import('pages/Report/CustomerExperience'))
const CompanyProfile = React.lazy(() => import('pages/CompanyProfile/CompanyProfile'))
const FeatureDeveloped = React.lazy(() => import('components/FeatureDeveloped'))
const ReportDashboard = React.lazy(() => import('pages/Survey/Report/ReportDashboard'))
const FeedbackDashboard = React.lazy(() => import('pages/Survey/Feedback/FeedbackDashboard'))
const SurveyWorkflow = React.lazy(() => import('pages/SurveyWorkflow/SurveyWorkflow'))
const SaveSurveyWorkflow = React.lazy(() => import('pages/SurveyWorkflow/SaveSurveyWorkflow'))
const WorkflowSettings = React.lazy(() => import('pages/SurveyWorkflow/WorkflowSettings'))
const Notifications = React.lazy(() => import('pages/Notifications/Notifications'))
const KpiSettings = React.lazy(() => import('pages/KpiSettings/KpiSettings'))
const SaveKpiSetting = React.lazy(() => import('pages/KpiSettings/SaveKpiSetting'))
const ExpressSurvey = React.lazy(() => import('pages/ExpressSurvey/ExpressSurvey'))
const RecurringSurvey = React.lazy(() => import('pages/RecurringSurvey/RecurringSurvey'))
const SaveRecurringDetails = React.lazy(() => import('pages/RecurringSurvey/SaveRecurringDetails'))
const RecurrenceDetails = React.lazy(() => import('pages/RecurringSurvey/RecurrenceDetails'))
const ActionPlan = React.lazy(() => import('pages/ActionPlan/ActionPlan'))
const SurveyActionPlanDashboard = React.lazy(() => import('pages/Survey/CategorySurveyDashboard/SurveyActionPlanDashboard'))
const SMTPSetting = React.lazy(() => import('pages/SMTPSetting/SMTPSetting'))
const WhatsAppSetting = React.lazy(() => import('pages/WhatsAppSetting/WhatsAppSetting'))
const GoogleReview = React.lazy(() => import('pages/GoogleReview/GoogleReview'))
const Feedback = React.lazy(() => import('pages/Report/Feedback'))
const WebSmsSetting = React.lazy(() => import('pages/WebSmsSetting/WebSmsSetting'))
const DashboardCeo = React.lazy(() => import('pages/DashboardCeo/DashboardCeo'))
const UserBulkUpdate = React.lazy(() => import('pages/UsersRoles/Users/BulkUpload/BulkUpload'))
const TwitterTweet = React.lazy(() => import('pages/TwitterTweet/TwitterTweet'))
const TemplateStyle = React.lazy(() => import('pages/TemplateStyle/TemplateStyle'))
const ChecklistDashboard = React.lazy(() => import('pages/Checklist/ChecklistDashboard'))
const BulkDelete = React.lazy(() => import('pages/BulkDelete/BulkDelete'))
const LifeCycle = React.lazy(() => import('pages/LifeCycle/LifeCycle'))
const Joyashboard = React.lazy(() => import('pages/Dashboard/JoyDashboard'))
const BulkRawDataUpload = React.lazy(() => import('pages/FeedbackSurvey/BulkRawDataUpload'))
const SlackSetting = React.lazy(() => import('pages/SlackSetting/SlackSetting'))
const CategoryPollsDashboard = React.lazy(() => import('pages/Polls/CategoryPollsDashboard/CategoryPollsDashboard'))
const PollDashboard = React.lazy(() => import('pages/Polls/PollDashboard'))
const PollCategory = React.lazy(() => import('pages/Polls/PollCategory'))
const PollAdd = React.lazy(() => import('pages/Polls/PollAdd'))
const PollCreator = React.lazy(()=>import('pages/Polls/PollCreator'))
const PollPreview = React.lazy(() => import('pages/Polls/PollPreview/PollPreview'))
const SSOSetting = React.lazy(() => import('pages/SSOSetting/SSOSetting'))
const Stressreport = React.lazy(() => import('pages/Report/ReportType/Standard/StressIndex/Stressreport'))
const Chat = React.lazy(()=> import('../pages/Chat/Chat'))
const SurveyBuilder = React.lazy(() => import('pages/Survey/SurveyBuilder'))
const ExpressBuilder = React.lazy(()=> import ('pages/Survey/ExpressBuilder'))

const router = [
    { path: '/', component: Dashboard, skip_access: true },
    { path: '/people-directory', component: PeopleDirectory, access_page: "peoples", skip_access: false, min_permission: "READ" },
    { path: '/bulk-upload/employee', component: BulkUpload, access_page: "employee", skip_access: false, min_permission: "CREATE" },
    { path: '/validated-data/employee', component: ValidateData, access_page: "employee", skip_access: false, min_permission: "CREATE" },
    { path: '/bulk-upload/customer', component: BulkUpload, access_page: "customer", skip_access: false, min_permission: "CREATE" },
    { path: '/validated-data/customer', component: ValidateData, access_page: "customer", skip_access: false, min_permission: "CREATE" },
    { path: '/bulk-upload/other', component: BulkUpload, access_page: "others", skip_access: false, min_permission: "CREATE" },
    { path: '/validated-data/other', component: ValidateData, access_page: "others", skip_access: false, min_permission: "CREATE" },
    { path: '/online-update/employee', component: OnlineUpdate, access_page: "employee", skip_access: false, min_permission: "UPDATE" },
    { path: '/online-update/customer', component: OnlineUpdate, access_page: "customer", skip_access: false, min_permission: "UPDATE" },
    { path: '/online-update/other', component: OnlineUpdate, access_page: "others", skip_access: false, min_permission: "UPDATE" },
    { path: '/bulk-update/employee', component: BulkUpdate, access_page: "employee", skip_access: false, min_permission: "UPDATE" },
    { path: '/bulk-update/customer', component: BulkUpdate, access_page: "customer", skip_access: false, min_permission: "UPDATE" },
    { path: '/bulk-update/other', component: BulkUpdate, access_page: "others", skip_access: false, min_permission: "UPDATE" },
    { path: '/identifier/employee', component: Identifier, access_page: "employee", skip_access: true, min_permission: "READ" },
    { path: '/identifier/customer', component: Identifier, access_page: "customer", skip_access: true, min_permission: "READ" },
    { path: '/identifier/other', component: Identifier, access_page: "others", skip_access: true, min_permission: "READ" },
    { path: '/employee-add', component: EmployeeAdd, access_page: "employee", skip_access: false, min_permission: "CREATE" },
    { path: '/themes', component: Themes, access_page: "themes", skip_access: false, min_permission: "READ" },
    { path: '/subthemes/:category_id/:theme_id', component: Subthemes, access_page: "themes", skip_access: false, min_permission: "READ" },
    { path: '/users-roles', component: UsersRoles, access_page: "account_management", skip_access: false, min_permission: "READ" },
    { path: '/users', component: Users, access_page: "users", skip_access: false, min_permission: "READ" },
    { path: '/user/view/:user_id', component: UsersView, access_page: "users", skip_access: false, min_permission: "READ" },
    { path: '/user/edit/:user_id', component: SaveUser, access_page: "users", skip_access: false, min_permission: "UPDATE" },
    { path: '/user/create', component: SaveUser, access_page: "users", skip_access: false, min_permission: "CREATE" },
    { path: '/profiles', component: Profiles, access_page: "profiles", skip_access: false, min_permission: "READ", feedback_access: true },
    { path: '/profile/edit/:profile_id', component: ProfileDetails, access_page: "profiles", skip_access: false, min_permission: "READ", feedback_access: true },
    { path: '/profile/create', component: ProfileDetails, access_page: "profiles", skip_access: false, min_permission: "CREATE" },
    { path: '/roles', component: Roles, access_page: "roles", skip_access: false, min_permission: "READ" },
    { path: '/role/view/:role_id', component: RoleView, access_page: "roles", skip_access: false, min_permission: "READ" },
    { path: '/role/edit/:role_id', component: SaveRole, access_page: "roles", skip_access: false, min_permission: "UPDATE" },
    { path: '/role/create-new', component: SaveRole, access_page: "roles", skip_access: false, min_permission: "CREATE" },
    { path: '/customer-add', component: CustomerAdd, access_page: "customer", skip_access: false, min_permission: "CREATE" },
    { path: '/other-contacts-add', component: OtherContactAdd, access_page: "others", skip_access: false, min_permission: "CREATE" },

    { path: '/survey-dashboard', component: SurveyDashboard, access_page: "survey", skip_access: false, min_permission: "READ" },
    { path: '/survey-dashboard/add-category', component: SurveyCategory, access_page: "survey", skip_access: false, min_permission: "CREATE" },
    { path: '/survey-dashboard/categories', component: SurveyCategory, access_page: "survey", skip_access: false, min_permission: "READ" },
    { path: '/survey-dashboard/add-new-category', component: CategoryAdd, access_page: "survey", skip_access: false, min_permission: "CREATE" },
    { path: '/survey-dashboard/add-survey/:category_id', component: SurveyAdd, access_page: "survey", skip_access: false, min_permission: "CREATE" },
    { path: '/survey-dashboard/edit-survey/:category_id/:survey_id', component: SurveyBuilder, access_page: "survey", skip_access: false, min_permission: "UPDATE" },
    { path: '/survey-dashboard/category-survey-dashboard', component: CategorySurveyDashboard, access_page: "survey", skip_access: false, min_permission: "READ" },
    { path: '/survey-dashboard/survey-builder/:category_id/:survey_id', component: SurveyBuilder, access_page: "survey", skip_access: false, min_permission: "CREATE" },
    { path: '/survey-preview', component: SurveyPreview, access_page: "survey", skip_access: false, min_permission: "CREATE" },
    { path: '/template-dashboard', component: TemplateDashboard, access_page: "templates", skip_access: false, min_permission: "READ" },
    { path: '/template-dashboard/template-category', component: TemplateCategory, access_page: "templates", skip_access: false, min_permission: "READ" },
    { path: '/template-dashboard/add-category', component: AddCategory, access_page: "templates", skip_access: false, min_permission: "CREATE" },
    { path: '/template-dashboard/template-add/:category_id', component: TemplateAdd, access_page: "templates", skip_access: false, min_permission: "CREATE" },
    { path: '/template-dashboard/category-templates', component: CategoryTemplateDashboard, access_page: "templates", skip_access: false, min_permission: "READ" },
    { path: '/template-dashboard/edit-template/:category_id/:template_id', component: TemplateAdd, access_page: "templates", skip_access: false, min_permission: "UPDATE" },
    { path: '/template-dashboard/template-questions/:category_id', component: TemplateBuilder, access_page: "templates", skip_access: false, min_permission: "CREATE" },
    { path: '/template-dashboard/template-questions/:category_id/:template_id', component: TemplateBuilder, access_page: "templates", skip_access: false, min_permission: "UPDATE" },
    { path: '/customer-experience/:survey_id', component: CustomerExperience, access_page: "report_dashboard", skip_access: false, min_permission: "READ" },
    { path: '/reports', component: ReportDashboard, access_page: "report_dashboard", skip_access: false, min_permission: "READ" },
    { path: '/survey-list', component: FeedbackDashboard, skip_access: true, feedback_access: true },
    { path: '/survey-report', component: SurveyReport, access_page: "survey", skip_access: false, min_permission: "READ" },
    { path: '/user-profile', component: UserProfile, skip_access: true, feedback_access: true, dashboard_ceo: true },
    { path: '/support', component: FeatureDeveloped, skip_access: true },
    { path: '/company-profile', component: CompanyProfile, access_page: "company_profile", skip_access: false, min_permission: "READ" },
    { path: '/survey-workflow', component: SurveyWorkflow, access_page: "survey_workflow", skip_access: false, min_permission: "READ" },
    { path: '/survey-workflow/save/:workflow_id?', component: SaveSurveyWorkflow, access_page: "survey_workflow", skip_access: false, min_permission: "CREATE" },
    { path: '/survey-workflow/save-settings/:workflow_id', component: WorkflowSettings, access_page: "survey_workflow", skip_access: false, min_permission: "CREATE" },
    { path: '/feature-developed', component: FeatureDeveloped, skip_access: true, },
    { path: '/notifications', component: Notifications, skip_access: true, feedback_access: true },
    { path: '/kpi-settings', component: KpiSettings, access_page: "kpi_setting", skip_access: false, min_permission: "READ" },
    { path: '/kpi-settings/save/:kpi_id?', component: SaveKpiSetting, access_page: "kpi_setting", skip_access: false, min_permission: "CREATE" },
    { path: '/express-survey/:survey_id', component: ExpressBuilder, access_page: "survey", skip_access: false, min_permission: "UPDATE" },
    { path: '/recurring-setting', component: RecurringSurvey, access_page: "recurring_setting", skip_access: false, min_permission: "READ" },
    { path: '/recurring-setting/save/:recurring_id?', component: SaveRecurringDetails, access_page: "recurring_setting", skip_access: false, min_permission: "CREATE" },
    { path: '/recurring-setting/save-recurrence/:recurring_id', component: RecurrenceDetails, access_page: "recurring_setting", skip_access: false, min_permission: "CREATE" },
    { path: '/action-plan', component: SurveyActionPlanDashboard, access_page: "action_planning", skip_access: false, min_permission: "READ" },
    { path: '/action-plan/:company_id?/:survey_id?/:card_id?', component: ActionPlan, access_page: "action_planning", skip_access: false, min_permission: "READ" },
    { path: '/smtp-settings', component: SMTPSetting, access_page: "smtp_setting", skip_access: false, min_permission: "READ" },
    { path: '/google-review', component: GoogleReview, access_page: "google_setting", skip_access: false, min_permission: "READ" },
    { path: '/feedback', component: Feedback, skip_access: true, feedback_access: true },
    { path: '/whatsapp-settings', component: WhatsAppSetting, access_page: "whatsapp_setting", skip_access: false, min_permission: "READ" },
    { path: '/websms-settings', component: WebSmsSetting, access_page: "sms_setting", skip_access: false, min_permission: "READ" },
    { path: '/dashboard-ceo', component: DashboardCeo, skip_access: true, dashboard_ceo: true },
    { path: '/user/bulk-update', component: UserBulkUpdate, access_page: "users", skip_access: false, min_permission: "CREATE" },
    { path: '/twitter-tweet', component: TwitterTweet, skip_access: true },
    { path: '/style', component: TemplateStyle, access_page: "survey", skip_access: false, min_permission: "CREATE" },
    { path: '/checklist-dashboard', component: ChecklistDashboard, skip_access: true },
    { path: '/bulk-delete/employee', component: BulkDelete, access_page: "employee", skip_access: false, min_permission: "UPDATE" },
    { path: '/bulk-delete/customer', component: BulkDelete, access_page: "customer", skip_access: false, min_permission: "UPDATE" },
    { path: '/bulk-delete/other', component: BulkDelete, access_page: "others", skip_access: false, min_permission: "UPDATE" },
    { path: '/life-cycle/:lifecycle_id?', component: LifeCycle, access_page: "lifecycle", skip_access: false, min_permission: "READ" },
    { path: '/joy-o-meter', component: Joyashboard, skip_access: true },
    { path: '/bulk-raw-data-upload/:survey_id?', component: BulkRawDataUpload, skip_access: true },
    { path: '/slack-settings', component: SlackSetting, skip_access: true },
    { path: '/poll-dashboard', component: PollDashboard, access_page: "survey", skip_access: false, min_permission: "READ" },
    { path: '/poll-dashboard/add-category', component: PollCategory, access_page: "survey", skip_access: false, min_permission: "CREATE" },
    { path: '/poll-dashboard/categories', component: PollCategory, access_page: "survey", skip_access: false, min_permission: "READ" },
    { path: '/poll-dashboard/add-new-category', component: CategoryAdd, access_page: "survey", skip_access: false, min_permission: "CREATE" },
    { path: '/poll-dashboard/add-poll/', component: PollAdd, access_page: "survey", skip_access: false, min_permission: "CREATE" },
    { path: '/poll-dashboard/edit-poll/:survey_id', component: PollCreator, access_page: "survey", skip_access: false, min_permission: "UPDATE" },
    { path: '/poll-dashboard/category-poll-dashboard', component: CategoryPollsDashboard, access_page: "survey", skip_access: false, min_permission: "READ" },
    { path: '/poll-dashboard/poll-builder/:survey_id', component: PollCreator, access_page: "survey", skip_access: false, min_permission: "CREATE" },
    { path: '/poll-preview', component: PollPreview, access_page: "survey", skip_access: false, min_permission: "CREATE" },
    { path: '/sso-settings', component: SSOSetting, skip_access: true },
    { path: '/stressindex', component: Stressreport, skip_access: true},
    { path: '/chat', component: Chat, skip_access: true, min_permission: "UPDATE" },
    { path: '/survey-dashboard/survey-builder/:category_id/:survey_id', component: SurveyBuilder, access_page: "survey", skip_access: false, min_permission: "CREATE" },
    { path: '/survey-dashboard/edit-survey/:category_id/:survey_id', component: SurveyBuilder, access_page: "survey", skip_access: false, min_permission: "UPDATE" },
]

export default router