export const PUBLISHING_CHANNELS_TXT = {
    webEmail: "Email Invite",
    webSms: "SMS Invite",
    emailEmbed: "Email Embed",
    webEmbed: "Web Embed",
    webLink: "Web Link",
    qrCode: "QR Code",
    whatsApp: "WhatsApp"
}
export const APP_ID = "8fac2b08-3ec3-40d1-b944-1e10bf8efce1";
export const APP_FIELD_NAME = 'application_id';
export const GOOGLE_CAPTCHA_KEY = '6LdipOYUAAAAAClshAbp8AQJ3VeU1cbE8p_BeWCQ';

export const PERMISSION_DATA = {
    survey: { permissions: ["READ", "CREATE", "UPDATE", "DELETE"], label: 'surveys' },
    poll: { permissions: ["READ", "CREATE", "UPDATE", "DELETE"], label: 'poll' },
    templates: { permissions: ["READ", "CREATE", "UPDATE", "DELETE"], label: 'templates' },
    themes: { permissions: ["READ", "CREATE", "UPDATE", "DELETE"], label: 'themes' },
    users: { permissions: ["READ", "CREATE", "UPDATE", "DELETE"], label: 'users' },
    account_management: { permissions: ["READ", "CREATE", "UPDATE", "DELETE"], label: 'Users_roles' },
    roles: { permissions: ["READ", "CREATE", "UPDATE", "DELETE"], label: 'roles' },
    peoples: { permissions: ["READ", "CREATE", "UPDATE", "DELETE"], label: 'peoples' },
    employee: { permissions: ["READ", "CREATE", "UPDATE", "DELETE"], label: 'employees' },
    customer: { permissions: ["READ", "CREATE", "UPDATE", "DELETE"], label: 'customers' },
    others: { permissions: ["READ", "CREATE", "UPDATE", "DELETE"], label: 'others' },
    setting: { permissions: ["READ", "CREATE", "UPDATE", "DELETE"], label: 'settings' },
    profiles: { permissions: ["READ", "CREATE", "UPDATE", "DELETE"], label: 'profiles' },
    report_dashboard: { permissions: ["READ", "CREATE", "UPDATE", "DELETE"], label: 'report_dashboard' },
    company_profile: { permissions: ["READ", "CREATE", "UPDATE", "DELETE"], label: 'company_profile' },
    action_planning: { permissions: ["READ", "CREATE", "UPDATE", "DELETE"], label: 'action_planning' },
    sms_setting: { permissions: ["READ", "CREATE", "UPDATE", "DELETE"], label: 'sms_setting' },
    // setting: { permissions: ["READ", "CREATE", "UPDATE", "DELETE"], label: 'Settings' }
    // collections: { permissions: ["READ", "CREATE", "UPDATE", "DELETE"], label: 'Collection' },
    survey_workflow: { permissions: ["READ", "CREATE", "UPDATE", "DELETE"], label: 'survey_workflows' },
    smtp_setting: { permissions: ["READ", "CREATE", "UPDATE", "DELETE"], label: 'smtp_setting' },
    recurring_setting: { permissions: ["READ", "CREATE", "UPDATE", "DELETE"], label: 'recurring_setting' },
    kpi_setting: { permissions: ["READ", "CREATE", "UPDATE", "DELETE"], label: 'kpi_setting' },
    whatsapp_setting: { permissions: ["READ", "CREATE", "UPDATE", "DELETE"], label: 'whatsApp_setting' },
    slack_setting: { permissions: ["READ", "CREATE", "UPDATE", "DELETE"], label: 'slack_setting' },
    google_setting: { permissions: ["READ", "CREATE", "UPDATE", "DELETE"], label: 'google_setting' },
    raw_data: { permissions: ["READ", "CREATE", "UPDATE", "DELETE"], label: 'raw_data' },
    score_sheet: { permissions: ["READ", "CREATE", "UPDATE", "DELETE"], label: 'score_sheet' },
    presentation: { permissions: ["READ", "CREATE", "UPDATE", "DELETE"], label: 'presentation' },
    feedback: { permissions: ["READ", "CREATE", "UPDATE", "DELETE"], label: 'feedback' },
    participant_feedback: { permissions: ["READ", "CREATE", "UPDATE", "DELETE"], label: 'participant_feedback' },
    lifecycle: { permissions: ["READ", "CREATE", "UPDATE", "DELETE"], label: 'lifecycle' },
}

export const DISPLAYDATA = [
    {
        menu: ["INSIGHT",
            "EXIT_REASON",
            "REPORT_ANALYSIS",
            "NPS_SCORES",
            "THEME_ANALYSIS",
            "QUESTION_ANALYSIS",
            "BENCHMARK_ANALYSIS",
            "STATISTICAL_ANALYSIS",
            "OVERVIEW",
            "CULTUREMAP",
            "COMPARATORS",
            "STATEMENTWISE",
            "DETAILTREND",
            "MOOD_O_METER",
            "RESPONSE_RATE",
            "RESPONDENT_REPORT",
            "EMPLOYEE_COMMENTS",
            "DOWNLOAD_REPORT",
            "PLAY_DASHBOARD"], label: 'Report', slug: "report"
    },
]

export const DISPLAYDATA_MAP = [
    {
        PULSE: ["INSIGHT", "REPORT_ANALYSIS", "QUESTION_ANALYSIS", "BENCHMARK_ANALYSIS", "STATISTICAL_ANALYSIS", "RESPONSE_RATE", "DOWNLOAD_REPORT","DOWNLOAD_SCORE_SHEET", "CULTUREMAP"],
        CPULSE: ["INSIGHT", "REPORT_ANALYSIS", "QUESTION_ANALYSIS", "STATISTICAL_ANALYSIS", "RESPONSE_RATE", "RESPONDENT_REPORT", "DOWNLOAD_REPORT", "CULTUREMAP"],
        CSATV3: ["INSIGHT", "REPORT_ANALYSIS", "THEME_ANALYSIS", "QUESTION_ANALYSIS", "STATISTICAL_ANALYSIS", "RESPONSE_RATE", "RESPONDENT_REPORT", "DOWNLOAD_REPORT", "CULTUREMAP"],
        CSATV5: ["INSIGHT", "REPORT_ANALYSIS", "THEME_ANALYSIS", "QUESTION_ANALYSIS", "STATISTICAL_ANALYSIS", "RESPONSE_RATE", "RESPONDENT_REPORT", "DOWNLOAD_REPORT", "CULTUREMAP"],
        ESATV3: ["INSIGHT", "REPORT_ANALYSIS", "QUESTION_ANALYSIS", "STATISTICAL_ANALYSIS", "RESPONSE_RATE","RESPONDENT_REPORT","DOWNLOAD_REPORT"],
        ESATPULSE: ["INSIGHT", "REPORT_ANALYSIS", "QUESTION_ANALYSIS", "BENCHMARK_ANALYSIS", "STATISTICAL_ANALYSIS", "RESPONSE_RATE", "RESPONDENT_REPORT", "DOWNLOAD_REPORT", "CULTUREMAP"],
        EXIT: ["INSIGHT", "REPORT_ANALYSIS", "QUESTION_ANALYSIS", "STATISTICAL_ANALYSIS", "RESPONSE_RATE", "RESPONDENT_REPORT", "DOWNLOAD_REPORT","DOWNLOAD_SCORE_SHEET", "CULTUREMAP"],
        HIV3: ["INSIGHT", "REPORT_ANALYSIS", "STATISTICAL_ANALYSIS", "RESPONSE_RATE", "DOWNLOAD_REPORT", "CULTUREMAP"],
        HIV4: ["INSIGHT", "REPORT_ANALYSIS", "STATISTICAL_ANALYSIS", "RESPONSE_RATE", "DOWNLOAD_REPORT", "CULTUREMAP"],
        NPSV3: ["INSIGHT", "REPORT_ANALYSIS", "QUESTION_ANALYSIS", "STATISTICAL_ANALYSIS", "RESPONSE_RATE", "RESPONDENT_REPORT", "DOWNLOAD_REPORT", "CULTUREMAP"],
        PMF: ["INSIGHT", "REPORT_ANALYSIS", "QUESTION_ANALYSIS", "STATISTICAL_ANALYSIS", "RESPONSE_RATE", "RESPONDENT_REPORT", "DOWNLOAD_REPORT", "CULTUREMAP"],
        SSAT: ["INSIGHT", "REPORT_ANALYSIS", "QUESTION_ANALYSIS", "STATISTICAL_ANALYSIS", "RESPONSE_RATE", "RESPONDENT_REPORT", "DOWNLOAD_REPORT", "CULTUREMAP"],
        WELLNESSV2: ["INSIGHT", "REPORT_ANALYSIS","BENCHMARK_ANALYSIS", "STATISTICAL_ANALYSIS", "RESPONSE_RATE", "DOWNLOAD_REPORT", "CULTUREMAP"],
        HIMOOD: ["INSIGHT", "REPORT_ANALYSIS", "RESPONSE_RATE", "DOWNLOAD_REPORT", "CULTUREMAP"],
        PULSETENSCALE: ["REPORT_ANALYSIS", "NPS_SCORES", "DOWNLOAD_REPORT", "CULTUREMAP"],
        ENPS: ["INSIGHT", "REPORT_ANALYSIS", "QUESTION_ANALYSIS", "PLAY_DASHBOARD", "RESPONSE_RATE", "DOWNLOAD_REPORT", "CULTUREMAP"],
        DEFAULT: ["INSIGHT", "QUESTION_ANALYSIS", "RESPONSE_RATE","RESPONDENT_REPORT", "DOWNLOAD_REPORT", "CULTUREMAP"],
        WELLNESS:["RESPONSE_RATE","DOWNLOAD_REPORT", "CULTUREMAP"],
        PF16:["INSIGHT","DOWNLOAD_REPORT", "CULTUREMAP"],
        DIVERSITY:["RESPONSE_RATE","DOWNLOAD_REPORT", "CULTUREMAP"],
        INN:["INSIGHT","DOWNLOAD_REPORT", "CULTUREMAP"],
        EPA:["INSIGHT","DOWNLOAD_REPORT", "CULTUREMAP"],
        BEYONDT:["INSIGHT","DOWNLOAD_REPORT", "CULTUREMAP"],
        QUANTITATIVEAPPTITUDE:["INSIGHT","QUESTION_ANALYSIS","RESPONDENT_REPORT", "DOWNLOAD_REPORT", "CULTUREMAP"],
        CHECKLIST:["INSIGHT","QUESTION_ANALYSIS","RESPONDENT_REPORT", "DOWNLOAD_REPORT", "CULTUREMAP"],
        WELLNESSNOANONYMITY:["RESPONSE_RATE","DOWNLOAD_REPORT", "CULTUREMAP"],
        CEODASHBOARD:["OVERVIEW", "CULTUREMAP", "COMPARATORS", "STATEMENTWISE", "DETAILTREND", "MOOD_O_METER","RESPONSE_RATE", "EMPLOYEE_COMMENTS","DOWNLOAD_REPORT"],
        EXITV2:["INSIGHT", "EXIT_REASON", "QUESTION_ANALYSIS", "STATISTICAL_ANALYSIS", "RESPONSE_RATE", "RESPONDENT_REPORT", "DOWNLOAD_REPORT", "CULTUREMAP"],
        POLL: [ "QUESTION_ANALYSIS","DOWNLOAD_REPORT", "CULTUREMAP"],
        ESATPULSEWITHOUTANONYMITY: ["INSIGHT", "REPORT_ANALYSIS", "QUESTION_ANALYSIS", "BENCHMARK_ANALYSIS", "STATISTICAL_ANALYSIS",  "RESPONSE_RATE", "RESPONDENT_REPORT", "DOWNLOAD_REPORT", "CULTUREMAP"],
        STRESSINDEX:["INSIGHT", "DOWNLOAD_REPORT"]
    }
]

export const DEFAULT_VALUES_FOR_REPORT = {
    PULSE: ["insights", "questions", "enggegment", "statistics", "response", "respondent", "download"],
    CSATV3: ["insights", "questions", "analysis", "themes", "statistics", "response", "respondent", "download"],
}
export const CHANNEL_PLACEHOLDERS_EMAIL = [
    { id: 1, value: '{first_name}', label: 'First Name' },
    { id: 2, value: '{last_name}', label: 'Last Name' },
    { id: 3, value: '{survey_name}', label: 'Survey Name' },
    { id: 4, value: '{survey_link}', label: 'Survey Link' },
    { id: 5, value: '{survey_link_tiny}', label: 'Survey Tiny Link' },
    { id: 6, value: '{start_date}', label: 'Start Date' },
    { id: 7, value: '{end_date}', label: 'End Date' },
    { id: 8, value: '{participant_key}', label: 'Participant Key' },
    { id: 8, value: '{company_name}', label: 'Company Name' },
]
export const CHANNEL_PLACEHOLDERS_SMS = [
    { id: 1, value: '{first_name}', label: 'First Name' },
    { id: 2, value: '{last_name}', label: 'Last Name' },
    { id: 3, value: '{survey_name}', label: 'Survey Name' },
    { id: 4, value: '{survey_link}', label: 'Survey Link' },
    { id: 5, value: '{survey_link_tiny}', label: 'Survey Tiny Link' }
]
export const CHANNEL_PLACEHOLDERS_SUBJECT = [
    { id: 1, value: '{first_name}', label: 'first_name' },
    { id: 3, value: '{survey_name}', label: 'survey_name' }
]

export const PERSONAL_IDENTIFIERS = {
    'employee': [
        { 'name': "employeeId", 'label': "employee_id", },
        { 'name': "employeeFname", 'label': "name", },
        // { 'name': "employeeLname", 'label': "last name", },
        { 'name': "employeeEmail", 'label': "email", },
        { 'name': "mobileNumber", 'label': "phone_number", },
    ],
    'customer': [
        { 'name': "customerId", 'label': "customer_id", },
        { 'name': "customerFname", 'label': "customer_name", },
        // { 'name': "customerLname", 'label': "Customer last name", },
        { 'name': "email", 'label': "email", },
        { 'name': "mobileNumber", 'label': "phone_number", },
    ],
    'other': [
        { 'name': "otherContactId", 'label': "id", },
        { 'name': "otherContactFname", 'label': "name", },
        // { 'name': "otherContactLname", 'label': "Last name", },
        { 'name': "otherContactemail", 'label': "email", },
        { 'name': "otherContactMobileNumber", 'label': "phone_number" }
    ]
}
export const PERSONAL_INPUT_TYPES = [
    { id: 1, value: 'dropdown', label: 'Dropdown' },
    { id: 2, value: 'radio', label: 'Radio' },
    { id: 3, value: 'text', label: 'Text' },
    { id: 4, value: 'datetime', label: 'Date' }
]

export const SURVEY_DASHBOARD_CARDS = [
    { id: 1, card_name: 'Total Survey', data_label: "total_surveys", card_icon: 'template_ic', status: 'total' },
    { id: 2, card_name: 'Active Survey', data_label: "active_surveys", card_icon: 'publish_ic', status: 'active' },
    { id: 3, card_name: 'Inactive Survey', data_label: "inactive_surveys", card_icon: 'template_ic', status: 'inactive' },
    { id: 4, card_name: 'Closed Survey', data_label: "closed_surveys", card_icon: 'closed_ic', status: 'close' }
]

export const TEMPLATE_DASHBOARD_CARDS = [
    { id: 1, card_name: 'Total Templates', data_label: "total_template_count", card_icon: 'template_ic', status: "all" },
    { id: 2, card_name: 'Active Templates', data_label: "active_template_count", card_icon: 'publish_ic', status: "active" },
    { id: 3, card_name: 'Published', data_label: "published_template_count", card_icon: 'template_ic', status: "published" },
    { id: 4, card_name: 'Unpublished', data_label: "unpublished_template_count", card_icon: 'publish_ic', status: "unpublished" }
]

export const MATERIAL_COLOR_CODE = [
    "#f44336", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", "#009688", "#4caf50", "#8bc34a", "#cddc39", "#ffeb3b", "#ffc107", "#ff9800", "#ff5722", "#795548", "#9e9e9e", "#607d8b", "#b71c1c", "#880e4f", "#4a148c", "#311b92", "#1a237e", "#0d47a1", "#004d40", "#1b5e20", "#006064", "#33691e", "#827717", "#f57f17", "#ff6f00", "#e65100", "#bf360c", "#3e2723", "#212121", "#263238", "#c62828", "#ad1457", "#6a1b9a", "#4527a0", "#283593", "#1565c0", "#00695c", "#2e7d32", "#00838f", "#558b2f", "#9e9d24", "#f9a825", "#ff8f00", "#ef6c00", "#d84315", "#4e342e", "#424242", "#37474f", "#d32f2f", "#c2185b", "#7b1fa2", "#512da8", "#303f9f", "#1976d2", "#00796b", "#388e3c", "#0097a7", "#689f38", "#afb42b", "#fbc02d", "#ffa000", "#f57c00", "#e64a19", "#5d4037", "#616161", "#455a64", "#e53935", "#d81b60", "#8e24aa", "#5e35b1", "#3949ab", "#1e88e5", "#00897b", "#43a047", "#00acc1", "#7cb342", "#c0ca33", "#fdd835", "#ffb300", "#fb8c00", "#f4511e", "#6d4c41", "#757575", "#546e7a", "#ef5350", "#ec407a", "#ab47bc", "#7e57c2", "#5c6bc0", "#42a5f5", "#26a69a", "#66bb6a", "#26c6da", "#9ccc65", "#d4e157", "#ffee58", "#ffca28", "#ffa726", "#ff7043", "#8d6e63", "#bdbdbd", "#78909c", "#e57373", "#f06292", "#ba68c8", "#9575cd", "#7986cb", "#64b5f6", "#4db6ac", "#81c784", "#4dd0e1", "#aed581", "#dce775", "#fff176", "#ffd54f", "#ffb74d", "#ff8a65", "#a1887f", "#e0e0e0", "#90a4ae"
]


export const TIME_ZONES = [{
    label: "Africa/Abidjan",
    value: "Africa/Abidjan"
}, {
    label: "Africa/Accra",
    value: "Africa/Accra"
}, {
    label: "Africa/Algiers",
    value: "Africa/Algiers"
}, {
    label: "Africa/Bissau",
    value: "Africa/Bissau"
}, {
    label: "Africa/Cairo",
    value: "Africa/Cairo"
}, {
    label: "Africa/Casablanca",
    value: "Africa/Casablanca"
}, {
    label: "Africa/Ceuta",
    value: "Africa/Ceuta"
}, {
    label: "Africa/El_Aaiun",
    value: "Africa/El_Aaiun"
}, {
    label: "Africa/Johannesburg",
    value: "Africa/Johannesburg"
}, {
    label: "Africa/Juba",
    value: "Africa/Juba"
}, {
    label: "Africa/Khartoum",
    value: "Africa/Khartoum"
}, {
    label: "Africa/Lagos",
    value: "Africa/Lagos"
}, {
    label: "Africa/Maputo",
    value: "Africa/Maputo"
}, {
    label: "Africa/Monrovia",
    value: "Africa/Monrovia"
}, {
    label: "Africa/Nairobi",
    value: "Africa/Nairobi"
}, {
    label: "Africa/Ndjamena",
    value: "Africa/Ndjamena"
}, {
    label: "Africa/Sao_Tome",
    value: "Africa/Sao_Tome"
}, {
    label: "Africa/Tripoli",
    value: "Africa/Tripoli"
}, {
    label: "Africa/Tunis",
    value: "Africa/Tunis"
}, {
    label: "Africa/Windhoek",
    value: "Africa/Windhoek"
}, {
    label: "America/Adak",
    value: "America/Adak"
}, {
    label: "America/Anchorage",
    value: "America/Anchorage"
}, {
    label: "America/Araguaina",
    value: "America/Araguaina"
}, {
    label: "America/Argentina/Buenos_Aires",
    value: "America/Argentina/Buenos_Aires"
}, {
    label: "America/Argentina/Catamarca",
    value: "America/Argentina/Catamarca"
}, {
    label: "America/Argentina/Cordoba",
    value: "America/Argentina/Cordoba"
}, {
    label: "America/Argentina/Jujuy",
    value: "America/Argentina/Jujuy"
}, {
    label: "America/Argentina/La_Rioja",
    value: "America/Argentina/La_Rioja"
}, {
    label: "America/Argentina/Mendoza",
    value: "America/Argentina/Mendoza"
}, {
    label: "America/Argentina/Rio_Gallegos",
    value: "America/Argentina/Rio_Gallegos"
}, {
    label: "America/Argentina/Salta",
    value: "America/Argentina/Salta"
}, {
    label: "America/Argentina/San_Juan",
    value: "America/Argentina/San_Juan"
}, {
    label: "America/Argentina/San_Luis",
    value: "America/Argentina/San_Luis"
}, {
    label: "America/Argentina/Tucuman",
    value: "America/Argentina/Tucuman"
}, {
    label: "America/Argentina/Ushuaia",
    value: "America/Argentina/Ushuaia"
}, {
    label: "America/Asuncion",
    value: "America/Asuncion"
}, {
    label: "America/Atikokan",
    value: "America/Atikokan"
}, {
    label: "America/Bahia",
    value: "America/Bahia"
}, {
    label: "America/Bahia_Banderas",
    value: "America/Bahia_Banderas"
}, {
    label: "America/Barbados",
    value: "America/Barbados"
}, {
    label: "America/Belem",
    value: "America/Belem"
}, {
    label: "America/Belize",
    value: "America/Belize"
}, {
    label: "America/Blanc-Sablon",
    value: "America/Blanc-Sablon"
}, {
    label: "America/Boa_Vista",
    value: "America/Boa_Vista"
}, {
    label: "America/Bogota",
    value: "America/Bogota"
}, {
    label: "America/Boise",
    value: "America/Boise"
}, {
    label: "America/Cambridge_Bay",
    value: "America/Cambridge_Bay"
}, {
    label: "America/Campo_Grande",
    value: "America/Campo_Grande"
}, {
    label: "America/Cancun",
    value: "America/Cancun"
}, {
    label: "America/Caracas",
    value: "America/Caracas"
}, {
    label: "America/Cayenne",
    value: "America/Cayenne"
}, {
    label: "America/Chicago",
    value: "America/Chicago"
}, {
    label: "America/Chihuahua",
    value: "America/Chihuahua"
}, {
    label: "America/Costa_Rica",
    value: "America/Costa_Rica"
}, {
    label: "America/Creston",
    value: "America/Creston"
}, {
    label: "America/Cuiaba",
    value: "America/Cuiaba"
}, {
    label: "America/Curacao",
    value: "America/Curacao"
}, {
    label: "America/Danmarkshavn",
    value: "America/Danmarkshavn"
}, {
    label: "America/Dawson",
    value: "America/Dawson"
}, {
    label: "America/Dawson_Creek",
    value: "America/Dawson_Creek"
}, {
    label: "America/Denver",
    value: "America/Denver"
}, {
    label: "America/Detroit",
    value: "America/Detroit"
}, {
    label: "America/Edmonton",
    value: "America/Edmonton"
}, {
    label: "America/Eirunepe",
    value: "America/Eirunepe"
}, {
    label: "America/El_Salvador",
    value: "America/El_Salvador"
}, {
    label: "America/Fort_Nelson",
    value: "America/Fort_Nelson"
}, {
    label: "America/Fortaleza",
    value: "America/Fortaleza"
}, {
    label: "America/Glace_Bay",
    value: "America/Glace_Bay"
}, {
    label: "America/Goose_Bay",
    value: "America/Goose_Bay"
}, {
    label: "America/Grand_Turk",
    value: "America/Grand_Turk"
}, {
    label: "America/Guatemala",
    value: "America/Guatemala"
}, {
    label: "America/Guayaquil",
    value: "America/Guayaquil"
}, {
    label: "America/Guyana",
    value: "America/Guyana"
}, {
    label: "America/Halifax",
    value: "America/Halifax"
}, {
    label: "America/Havana",
    value: "America/Havana"
}, {
    label: "America/Hermosillo",
    value: "America/Hermosillo"
}, {
    label: "America/Indiana/Indianapolis",
    value: "America/Indiana/Indianapolis"
}, {
    label: "America/Indiana/Knox",
    value: "America/Indiana/Knox"
}, {
    label: "America/Indiana/Marengo",
    value: "America/Indiana/Marengo"
}, {
    label: "America/Indiana/Petersburg",
    value: "America/Indiana/Petersburg"
}, {
    label: "America/Indiana/Tell_City",
    value: "America/Indiana/Tell_City"
}, {
    label: "America/Indiana/Vevay",
    value: "America/Indiana/Vevay"
}, {
    label: "America/Indiana/Vincennes",
    value: "America/Indiana/Vincennes"
}, {
    label: "America/Indiana/Winamac",
    value: "America/Indiana/Winamac"
}, {
    label: "America/Inuvik",
    value: "America/Inuvik"
}, {
    label: "America/Iqaluit",
    value: "America/Iqaluit"
}, {
    label: "America/Jamaica",
    value: "America/Jamaica"
}, {
    label: "America/Juneau",
    value: "America/Juneau"
}, {
    label: "America/Kentucky/Louisville",
    value: "America/Kentucky/Louisville"
}, {
    label: "America/Kentucky/Monticello",
    value: "America/Kentucky/Monticello"
}, {
    label: "America/La_Paz",
    value: "America/La_Paz"
}, {
    label: "America/Lima",
    value: "America/Lima"
}, {
    label: "America/Los_Angeles",
    value: "America/Los_Angeles"
}, {
    label: "America/Maceio",
    value: "America/Maceio"
}, {
    label: "America/Managua",
    value: "America/Managua"
}, {
    label: "America/Manaus",
    value: "America/Manaus"
}, {
    label: "America/Martinique",
    value: "America/Martinique"
}, {
    label: "America/Matamoros",
    value: "America/Matamoros"
}, {
    label: "America/Mazatlan",
    value: "America/Mazatlan"
}, {
    label: "America/Menominee",
    value: "America/Menominee"
}, {
    label: "America/Merida",
    value: "America/Merida"
}, {
    label: "America/Metlakatla",
    value: "America/Metlakatla"
}, {
    label: "America/Mexico_City",
    value: "America/Mexico_City"
}, {
    label: "America/Miquelon",
    value: "America/Miquelon"
}, {
    label: "America/Moncton",
    value: "America/Moncton"
}, {
    label: "America/Monterrey",
    value: "America/Monterrey"
}, {
    label: "America/Montevideo",
    value: "America/Montevideo"
}, {
    label: "America/Nassau",
    value: "America/Nassau"
}, {
    label: "America/New_York",
    value: "America/New_York"
}, {
    label: "America/Nipigon",
    value: "America/Nipigon"
}, {
    label: "America/Nome",
    value: "America/Nome"
}, {
    label: "America/Noronha",
    value: "America/Noronha"
}, {
    label: "America/North_Dakota/Beulah",
    value: "America/North_Dakota/Beulah"
}, {
    label: "America/North_Dakota/Center",
    value: "America/North_Dakota/Center"
}, {
    label: "America/North_Dakota/New_Salem",
    value: "America/North_Dakota/New_Salem"
}, {
    label: "America/Nuuk",
    value: "America/Nuuk"
}, {
    label: "America/Ojinaga",
    value: "America/Ojinaga"
}, {
    label: "America/Panama",
    value: "America/Panama"
}, {
    label: "America/Pangnirtung",
    value: "America/Pangnirtung"
}, {
    label: "America/Paramaribo",
    value: "America/Paramaribo"
}, {
    label: "America/Phoenix",
    value: "America/Phoenix"
}, {
    label: "America/Port-au-Prince",
    value: "America/Port-au-Prince"
}, {
    label: "America/Port_of_Spain",
    value: "America/Port_of_Spain"
}, {
    label: "America/Porto_Velho",
    value: "America/Porto_Velho"
}, {
    label: "America/Puerto_Rico",
    value: "America/Puerto_Rico"
}, {
    label: "America/Punta_Arenas",
    value: "America/Punta_Arenas"
}, {
    label: "America/Rainy_River",
    value: "America/Rainy_River"
}, {
    label: "America/Rankin_Inlet",
    value: "America/Rankin_Inlet"
}, {
    label: "America/Recife",
    value: "America/Recife"
}, {
    label: "America/Regina",
    value: "America/Regina"
}, {
    label: "America/Resolute",
    value: "America/Resolute"
}, {
    label: "America/Rio_Branco",
    value: "America/Rio_Branco"
}, {
    label: "America/Santarem",
    value: "America/Santarem"
}, {
    label: "America/Santiago",
    value: "America/Santiago"
}, {
    label: "America/Santo_Domingo",
    value: "America/Santo_Domingo"
}, {
    label: "America/Sao_Paulo",
    value: "America/Sao_Paulo"
}, {
    label: "America/Scoresbysund",
    value: "America/Scoresbysund"
}, {
    label: "America/Sitka",
    value: "America/Sitka"
}, {
    label: "America/St_Johns",
    value: "America/St_Johns"
}, {
    label: "America/Swift_Current",
    value: "America/Swift_Current"
}, {
    label: "America/Tegucigalpa",
    value: "America/Tegucigalpa"
}, {
    label: "America/Thule",
    value: "America/Thule"
}, {
    label: "America/Thunder_Bay",
    value: "America/Thunder_Bay"
}, {
    label: "America/Tijuana",
    value: "America/Tijuana"
}, {
    label: "America/Toronto",
    value: "America/Toronto"
}, {
    label: "America/Vancouver",
    value: "America/Vancouver"
}, {
    label: "America/Whitehorse",
    value: "America/Whitehorse"
}, {
    label: "America/Winnipeg",
    value: "America/Winnipeg"
}, {
    label: "America/Yakutat",
    value: "America/Yakutat"
}, {
    label: "America/Yellowknife",
    value: "America/Yellowknife"
}, {
    label: "Antarctica/Casey",
    value: "Antarctica/Casey"
}, {
    label: "Antarctica/Davis",
    value: "Antarctica/Davis"
}, {
    label: "Antarctica/DumontDUrville",
    value: "Antarctica/DumontDUrville"
}, {
    label: "Antarctica/Macquarie",
    value: "Antarctica/Macquarie"
}, {
    label: "Antarctica/Mawson",
    value: "Antarctica/Mawson"
}, {
    label: "Antarctica/Palmer",
    value: "Antarctica/Palmer"
}, {
    label: "Antarctica/Rothera",
    value: "Antarctica/Rothera"
}, {
    label: "Antarctica/Syowa",
    value: "Antarctica/Syowa"
}, {
    label: "Antarctica/Troll",
    value: "Antarctica/Troll"
}, {
    label: "Antarctica/Vostok",
    value: "Antarctica/Vostok"
}, {
    label: "Asia/Almaty",
    value: "Asia/Almaty"
}, {
    label: "Asia/Amman",
    value: "Asia/Amman"
}, {
    label: "Asia/Anadyr",
    value: "Asia/Anadyr"
}, {
    label: "Asia/Aqtau",
    value: "Asia/Aqtau"
}, {
    label: "Asia/Aqtobe",
    value: "Asia/Aqtobe"
}, {
    label: "Asia/Ashgabat",
    value: "Asia/Ashgabat"
}, {
    label: "Asia/Atyrau",
    value: "Asia/Atyrau"
}, {
    label: "Asia/Baghdad",
    value: "Asia/Baghdad"
}, {
    label: "Asia/Baku",
    value: "Asia/Baku"
}, {
    label: "Asia/Bangkok",
    value: "Asia/Bangkok"
}, {
    label: "Asia/Barnaul",
    value: "Asia/Barnaul"
}, {
    label: "Asia/Beirut",
    value: "Asia/Beirut"
}, {
    label: "Asia/Bishkek",
    value: "Asia/Bishkek"
}, {
    label: "Asia/Brunei",
    value: "Asia/Brunei"
}, {
    label: "Asia/Chita",
    value: "Asia/Chita"
}, {
    label: "Asia/Choibalsan",
    value: "Asia/Choibalsan"
}, {
    label: "Asia/Colombo",
    value: "Asia/Colombo"
}, {
    label: "Asia/Damascus",
    value: "Asia/Damascus"
}, {
    label: "Asia/Dhaka",
    value: "Asia/Dhaka"
}, {
    label: "Asia/Dili",
    value: "Asia/Dili"
}, {
    label: "Asia/Dubai",
    value: "Asia/Dubai"
}, {
    label: "Asia/Dushanbe",
    value: "Asia/Dushanbe"
}, {
    label: "Asia/Famagusta",
    value: "Asia/Famagusta"
}, {
    label: "Asia/Gaza",
    value: "Asia/Gaza"
}, {
    label: "Asia/Hebron",
    value: "Asia/Hebron"
}, {
    label: "Asia/Ho_Chi_Minh",
    value: "Asia/Ho_Chi_Minh"
}, {
    label: "Asia/Hong_Kong",
    value: "Asia/Hong_Kong"
}, {
    label: "Asia/Hovd",
    value: "Asia/Hovd"
}, {
    label: "Asia/Irkutsk",
    value: "Asia/Irkutsk"
}, {
    label: "Asia/Jakarta",
    value: "Asia/Jakarta"
}, {
    label: "Asia/Jayapura",
    value: "Asia/Jayapura"
}, {
    label: "Asia/Jerusalem",
    value: "Asia/Jerusalem"
}, {
    label: "Asia/Kabul",
    value: "Asia/Kabul"
}, {
    label: "Asia/Kamchatka",
    value: "Asia/Kamchatka"
}, {
    label: "Asia/Karachi",
    value: "Asia/Karachi"
}, {
    label: "Asia/Kathmandu",
    value: "Asia/Kathmandu"
}, {
    label: "Asia/Khandyga",
    value: "Asia/Khandyga"
}, {
    label: "Asia/Kolkata",
    value: "Asia/Kolkata"
}, {
    label: "Asia/Krasnoyarsk",
    value: "Asia/Krasnoyarsk"
}, {
    label: "Asia/Kuala_Lumpur",
    value: "Asia/Kuala_Lumpur"
}, {
    label: "Asia/Kuching",
    value: "Asia/Kuching"
}, {
    label: "Asia/Macau",
    value: "Asia/Macau"
}, {
    label: "Asia/Magadan",
    value: "Asia/Magadan"
}, {
    label: "Asia/Makassar",
    value: "Asia/Makassar"
}, {
    label: "Asia/Manila",
    value: "Asia/Manila"
}, {
    label: "Asia/Nicosia",
    value: "Asia/Nicosia"
}, {
    label: "Asia/Novokuznetsk",
    value: "Asia/Novokuznetsk"
}, {
    label: "Asia/Novosibirsk",
    value: "Asia/Novosibirsk"
}, {
    label: "Asia/Omsk",
    value: "Asia/Omsk"
}, {
    label: "Asia/Oral",
    value: "Asia/Oral"
}, {
    label: "Asia/Pontianak",
    value: "Asia/Pontianak"
}, {
    label: "Asia/Pyongyang",
    value: "Asia/Pyongyang"
}, {
    label: "Asia/Qatar",
    value: "Asia/Qatar"
}, {
    label: "Asia/Qostanay",
    value: "Asia/Qostanay"
}, {
    label: "Asia/Qyzylorda",
    value: "Asia/Qyzylorda"
}, {
    label: "Asia/Riyadh",
    value: "Asia/Riyadh"
}, {
    label: "Asia/Sakhalin",
    value: "Asia/Sakhalin"
}, {
    label: "Asia/Samarkand",
    value: "Asia/Samarkand"
}, {
    label: "Asia/Seoul",
    value: "Asia/Seoul"
}, {
    label: "Asia/Shanghai",
    value: "Asia/Shanghai"
}, {
    label: "Asia/Singapore",
    value: "Asia/Singapore"
}, {
    label: "Asia/Srednekolymsk",
    value: "Asia/Srednekolymsk"
}, {
    label: "Asia/Taipei",
    value: "Asia/Taipei"
}, {
    label: "Asia/Tashkent",
    value: "Asia/Tashkent"
}, {
    label: "Asia/Tbilisi",
    value: "Asia/Tbilisi"
}, {
    label: "Asia/Tehran",
    value: "Asia/Tehran"
}, {
    label: "Asia/Thimphu",
    value: "Asia/Thimphu"
}, {
    label: "Asia/Tokyo",
    value: "Asia/Tokyo"
}, {
    label: "Asia/Tomsk",
    value: "Asia/Tomsk"
}, {
    label: "Asia/Ulaanbaatar",
    value: "Asia/Ulaanbaatar"
}, {
    label: "Asia/Urumqi",
    value: "Asia/Urumqi"
}, {
    label: "Asia/Ust-Nera",
    value: "Asia/Ust-Nera"
}, {
    label: "Asia/Vladivostok",
    value: "Asia/Vladivostok"
}, {
    label: "Asia/Yakutsk",
    value: "Asia/Yakutsk"
}, {
    label: "Asia/Yangon",
    value: "Asia/Yangon"
}, {
    label: "Asia/Yekaterinburg",
    value: "Asia/Yekaterinburg"
}, {
    label: "Asia/Yerevan",
    value: "Asia/Yerevan"
}, {
    label: "Atlantic/Azores",
    value: "Atlantic/Azores"
}, {
    label: "Atlantic/Bermuda",
    value: "Atlantic/Bermuda"
}, {
    label: "Atlantic/Canary",
    value: "Atlantic/Canary"
}, {
    label: "Atlantic/Cape_Verde",
    value: "Atlantic/Cape_Verde"
}, {
    label: "Atlantic/Faroe",
    value: "Atlantic/Faroe"
}, {
    label: "Atlantic/Madeira",
    value: "Atlantic/Madeira"
}, {
    label: "Atlantic/Reykjavik",
    value: "Atlantic/Reykjavik"
}, {
    label: "Atlantic/South_Georgia",
    value: "Atlantic/South_Georgia"
}, {
    label: "Atlantic/Stanley",
    value: "Atlantic/Stanley"
}, {
    label: "Australia/Adelaide",
    value: "Australia/Adelaide"
}, {
    label: "Australia/Brisbane",
    value: "Australia/Brisbane"
}, {
    label: "Australia/Broken_Hill",
    value: "Australia/Broken_Hill"
}, {
    label: "Australia/Currie",
    value: "Australia/Currie"
}, {
    label: "Australia/Darwin",
    value: "Australia/Darwin"
}, {
    label: "Australia/Eucla",
    value: "Australia/Eucla"
}, {
    label: "Australia/Hobart",
    value: "Australia/Hobart"
}, {
    label: "Australia/Lindeman",
    value: "Australia/Lindeman"
}, {
    label: "Australia/Lord_Howe",
    value: "Australia/Lord_Howe"
}, {
    label: "Australia/Melbourne",
    value: "Australia/Melbourne"
}, {
    label: "Australia/Perth",
    value: "Australia/Perth"
}, {
    label: "Australia/Sydney",
    value: "Australia/Sydney"
}, {
    label: "CET",
    value: "CET"
}, {
    label: "CST6CDT",
    value: "CST6CDT"
}, {
    label: "EET",
    value: "EET"
}, {
    label: "EST",
    value: "EST"
}, {
    label: "EST5EDT",
    value: "EST5EDT"
}, {
    label: "Etc/GMT",
    value: "Etc/GMT"
}, {
    label: "Etc/GMT+1",
    value: "Etc/GMT+1"
}, {
    label: "Etc/GMT+10",
    value: "Etc/GMT+10"
}, {
    label: "Etc/GMT+11",
    value: "Etc/GMT+11"
}, {
    label: "Etc/GMT+12",
    value: "Etc/GMT+12"
}, {
    label: "Etc/GMT+2",
    value: "Etc/GMT+2"
}, {
    label: "Etc/GMT+3",
    value: "Etc/GMT+3"
}, {
    label: "Etc/GMT+4",
    value: "Etc/GMT+4"
}, {
    label: "Etc/GMT+5",
    value: "Etc/GMT+5"
}, {
    label: "Etc/GMT+6",
    value: "Etc/GMT+6"
}, {
    label: "Etc/GMT+7",
    value: "Etc/GMT+7"
}, {
    label: "Etc/GMT+8",
    value: "Etc/GMT+8"
}, {
    label: "Etc/GMT+9",
    value: "Etc/GMT+9"
}, {
    label: "Etc/GMT-1",
    value: "Etc/GMT-1"
}, {
    label: "Etc/GMT-10",
    value: "Etc/GMT-10"
}, {
    label: "Etc/GMT-11",
    value: "Etc/GMT-11"
}, {
    label: "Etc/GMT-12",
    value: "Etc/GMT-12"
}, {
    label: "Etc/GMT-13",
    value: "Etc/GMT-13"
}, {
    label: "Etc/GMT-14",
    value: "Etc/GMT-14"
}, {
    label: "Etc/GMT-2",
    value: "Etc/GMT-2"
}, {
    label: "Etc/GMT-3",
    value: "Etc/GMT-3"
}, {
    label: "Etc/GMT-4",
    value: "Etc/GMT-4"
}, {
    label: "Etc/GMT-5",
    value: "Etc/GMT-5"
}, {
    label: "Etc/GMT-6",
    value: "Etc/GMT-6"
}, {
    label: "Etc/GMT-7",
    value: "Etc/GMT-7"
}, {
    label: "Etc/GMT-8",
    value: "Etc/GMT-8"
}, {
    label: "Etc/GMT-9",
    value: "Etc/GMT-9"
}, {
    label: "Etc/UTC",
    value: "Etc/UTC"
}, {
    label: "Europe/Amsterdam",
    value: "Europe/Amsterdam"
}, {
    label: "Europe/Andorra",
    value: "Europe/Andorra"
}, {
    label: "Europe/Astrakhan",
    value: "Europe/Astrakhan"
}, {
    label: "Europe/Athens",
    value: "Europe/Athens"
}, {
    label: "Europe/Belgrade",
    value: "Europe/Belgrade"
}, {
    label: "Europe/Berlin",
    value: "Europe/Berlin"
}, {
    label: "Europe/Brussels",
    value: "Europe/Brussels"
}, {
    label: "Europe/Bucharest",
    value: "Europe/Bucharest"
}, {
    label: "Europe/Budapest",
    value: "Europe/Budapest"
}, {
    label: "Europe/Chisinau",
    value: "Europe/Chisinau"
}, {
    label: "Europe/Copenhagen",
    value: "Europe/Copenhagen"
}, {
    label: "Europe/Dublin",
    value: "Europe/Dublin"
}, {
    label: "Europe/Gibraltar",
    value: "Europe/Gibraltar"
}, {
    label: "Europe/Helsinki",
    value: "Europe/Helsinki"
}, {
    label: "Europe/Istanbul",
    value: "Europe/Istanbul"
}, {
    label: "Europe/Kaliningrad",
    value: "Europe/Kaliningrad"
}, {
    label: "Europe/Kiev",
    value: "Europe/Kiev"
}, {
    label: "Europe/Kirov",
    value: "Europe/Kirov"
}, {
    label: "Europe/Lisbon",
    value: "Europe/Lisbon"
}, {
    label: "Europe/London",
    value: "Europe/London"
}, {
    label: "Europe/Luxembourg",
    value: "Europe/Luxembourg"
}, {
    label: "Europe/Madrid",
    value: "Europe/Madrid"
}, {
    label: "Europe/Malta",
    value: "Europe/Malta"
}, {
    label: "Europe/Minsk",
    value: "Europe/Minsk"
}, {
    label: "Europe/Monaco",
    value: "Europe/Monaco"
}, {
    label: "Europe/Moscow",
    value: "Europe/Moscow"
}, {
    label: "Europe/Oslo",
    value: "Europe/Oslo"
}, {
    label: "Europe/Paris",
    value: "Europe/Paris"
}, {
    label: "Europe/Prague",
    value: "Europe/Prague"
}, {
    label: "Europe/Riga",
    value: "Europe/Riga"
}, {
    label: "Europe/Rome",
    value: "Europe/Rome"
}, {
    label: "Europe/Samara",
    value: "Europe/Samara"
}, {
    label: "Europe/Saratov",
    value: "Europe/Saratov"
}, {
    label: "Europe/Simferopol",
    value: "Europe/Simferopol"
}, {
    label: "Europe/Sofia",
    value: "Europe/Sofia"
}, {
    label: "Europe/Stockholm",
    value: "Europe/Stockholm"
}, {
    label: "Europe/Tallinn",
    value: "Europe/Tallinn"
}, {
    label: "Europe/Tirane",
    value: "Europe/Tirane"
}, {
    label: "Europe/Ulyanovsk",
    value: "Europe/Ulyanovsk"
}, {
    label: "Europe/Uzhgorod",
    value: "Europe/Uzhgorod"
}, {
    label: "Europe/Vienna",
    value: "Europe/Vienna"
}, {
    label: "Europe/Vilnius",
    value: "Europe/Vilnius"
}, {
    label: "Europe/Volgograd",
    value: "Europe/Volgograd"
}, {
    label: "Europe/Warsaw",
    value: "Europe/Warsaw"
}, {
    label: "Europe/Zaporozhye",
    value: "Europe/Zaporozhye"
}, {
    label: "Europe/Zurich",
    value: "Europe/Zurich"
}, {
    label: "HST",
    value: "HST"
}, {
    label: "Indian/Chagos",
    value: "Indian/Chagos"
}, {
    label: "Indian/Christmas",
    value: "Indian/Christmas"
}, {
    label: "Indian/Cocos",
    value: "Indian/Cocos"
}, {
    label: "Indian/Kerguelen",
    value: "Indian/Kerguelen"
}, {
    label: "Indian/Mahe",
    value: "Indian/Mahe"
}, {
    label: "Indian/Maldives",
    value: "Indian/Maldives"
}, {
    label: "Indian/Mauritius",
    value: "Indian/Mauritius"
}, {
    label: "Indian/Reunion",
    value: "Indian/Reunion"
}, {
    label: "MET",
    value: "MET"
}, {
    label: "MST",
    value: "MST"
}, {
    label: "MST7MDT",
    value: "MST7MDT"
}, {
    label: "PST8PDT",
    value: "PST8PDT"
}, {
    label: "Pacific/Apia",
    value: "Pacific/Apia"
}, {
    label: "Pacific/Auckland",
    value: "Pacific/Auckland"
}, {
    label: "Pacific/Bougainville",
    value: "Pacific/Bougainville"
}, {
    label: "Pacific/Chatham",
    value: "Pacific/Chatham"
}, {
    label: "Pacific/Chuuk",
    value: "Pacific/Chuuk"
}, {
    label: "Pacific/Easter",
    value: "Pacific/Easter"
}, {
    label: "Pacific/Efate",
    value: "Pacific/Efate"
}, {
    label: "Pacific/Enderbury",
    value: "Pacific/Enderbury"
}, {
    label: "Pacific/Fakaofo",
    value: "Pacific/Fakaofo"
}, {
    label: "Pacific/Fiji",
    value: "Pacific/Fiji"
}, {
    label: "Pacific/Funafuti",
    value: "Pacific/Funafuti"
}, {
    label: "Pacific/Galapagos",
    value: "Pacific/Galapagos"
}, {
    label: "Pacific/Gambier",
    value: "Pacific/Gambier"
}, {
    label: "Pacific/Guadalcanal",
    value: "Pacific/Guadalcanal"
}, {
    label: "Pacific/Guam",
    value: "Pacific/Guam"
}, {
    label: "Pacific/Honolulu",
    value: "Pacific/Honolulu"
}, {
    label: "Pacific/Kiritimati",
    value: "Pacific/Kiritimati"
}, {
    label: "Pacific/Kosrae",
    value: "Pacific/Kosrae"
}, {
    label: "Pacific/Kwajalein",
    value: "Pacific/Kwajalein"
}, {
    label: "Pacific/Majuro",
    value: "Pacific/Majuro"
}, {
    label: "Pacific/Marquesas",
    value: "Pacific/Marquesas"
}, {
    label: "Pacific/Nauru",
    value: "Pacific/Nauru"
}, {
    label: "Pacific/Niue",
    value: "Pacific/Niue"
}, {
    label: "Pacific/Norfolk",
    value: "Pacific/Norfolk"
}, {
    label: "Pacific/Noumea",
    value: "Pacific/Noumea"
}, {
    label: "Pacific/Pago_Pago",
    value: "Pacific/Pago_Pago"
}, {
    label: "Pacific/Palau",
    value: "Pacific/Palau"
}, {
    label: "Pacific/Pitcairn",
    value: "Pacific/Pitcairn"
}, {
    label: "Pacific/Pohnpei",
    value: "Pacific/Pohnpei"
}, {
    label: "Pacific/Port_Moresby",
    value: "Pacific/Port_Moresby"
}, {
    label: "Pacific/Rarotonga",
    value: "Pacific/Rarotonga"
}, {
    label: "Pacific/Tahiti",
    value: "Pacific/Tahiti"
}, {
    label: "Pacific/Tarawa",
    value: "Pacific/Tarawa"
}, {
    label: "Pacific/Tongatapu",
    value: "Pacific/Tongatapu"
}, {
    label: "Pacific/Wake",
    value: "Pacific/Wake"
}, {
    label: "Pacific/Wallis",
    value: "Pacific/Wallis"
}, {
    label: "WET",
    value: "WET"
}]

export const COMMUNICATION_TOOLBAR_CONFIG = {
    link: {
        showOpenOptionOnHover: false,
        // linkCallback: (link) => {
        //     console.log(link);
        // }
    }
}
export const COMMUNICATION_TOOLBAR_CONFIG_NEW = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: ['INLINE_STYLE_BUTTONS', 'BLOCK_ALIGNMENT_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'IMAGE_BUTTON', 'HISTORY_BUTTONS'],
    INLINE_STYLE_BUTTONS: [
        { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
        { label: 'Italic', style: 'ITALIC' },
        { label: 'Underline', style: 'UNDERLINE' }
    ],
    BLOCK_TYPE_BUTTONS: [
        { label: 'UL', style: 'unordered-list-item' },
        { label: 'OL', style: 'ordered-list-item' }
    ]
}
export const RECURENCE_WEEK_DAYS = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
export const RECURENCE_MONTHS = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december']
// export const RECURENCE_TIMEFRAMES = ['weekly', 'monthly', 'yearly', 'specific_date', 'daily']
export const RECURENCE_TIMEFRAMES = ['weekly', 'monthly', 'yearly', 'daily']
export const RESPONSE_REPORT_TREE = ['Pulse', 'Wellness', 'Diversity', 'ESAT-V3', 'Wellness-V2', 'HI-V3', 'HI-V4', 'CSAT-V3', "Wellness-no-anonymity"]
export const HIDE_REPORT_FILTER = ['wellness', 'diversity', 'wellnessv2', "wellnessnoanonymity", "poll"]
export const REQUIRED_DEMOGRAFIC_REPORT_TYPE = ["Exit", "PF-16"]
export const EXITIDENTIFIER = ["date_of_joining", "date_of_exit"]
export const PF16IDENTIFIER = ["age", "gender"]
export const EXITDISABLEIDENTIFIER = ["date_of_joining", "date_of_exit"]
export const PF16DISABLEIDENTIFIER = ["age", "gender"]
export const DEFAULT_ALL_REMOVE = []
export const RECURENCE_30_60_90 = ['30 days', '60 days', '90 days']
export const CROSSTABREPORTS = [{ from: 'ESAT-V3', to: ['CSAT-V3', 'CSAT-V5'], labelfrom: 'ESAT', labelto: 'CSAT' }, { from: 'Pulse', to: ['CSAT-V3', 'CSAT-V5'], labelfrom: 'Pulse', labelto: 'CSAT' }]
export const GOOGLEDETAILS = {
    client_id: "758179522311-mkquutnfikk0450mamkj1ki1n639v5vj.apps.googleusercontent.com",
    client_secret_key: "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"
}
export const PROGRESSBAR_COLOR = [
    {
        report_type: "NPS",
        color: "Light_Green",
        min_score: 25,
        max_score: 100,
    },
    {
        report_type: "NPS",
        color: "Amber",
        min_score: -25,
        max_score: 25,
    },
    {
        report_type: "NPS",
        color: "Light_Red",
        min_score: -65,
        max_score: -25,
    },
    {
        report_type: "NPS",
        color: "Red",
        min_score: -100,
        max_score: -65,
    },
    {
        report_type: "other",
        color: "Amber",
        min_score: 30,
        max_score: 100,
    },
    {
        report_type: "other",
        color: "Amber",
        min_score: 0,
        max_score: 30,
    }
]

export const twitterComments = [{
    comment: "Great place to shape your career..... Learn with practical and supportive staff",
    name: "Amit Bankar",
    userId: "@amit",
    date: "Aug 06, 2022"
}, {
    comment: "It is very greate place to improve your performance and skills and talent.",
    name: "Avinash Devmurar",
    userId: "@avinash",
    date: "Jul 22, 2022"
}, {
    comment: "Good place for gain knowledge it's give you 100℅placement",
    name: "Vishakha Ghagre",
    userId: "@vishakha",
    date: "Jul 12, 2022"
}, {
    comment: "It's good place to shape your carrier. I told my experience in Xceller I got trained from this institute and got place in Pune MNC.",
    name: "Ashish Katre",
    userId: "@ashish",
    date: "Jun 25, 2022"
},
{
    comment: "Bro my facebook account and instagram account was hacked and he disable my account please can you help me",
    name: "sameer",
    userId: "@sameer",
    date: "Jun 22, 2022"
},
{
    comment: "Its a good option to placed in good company. Its provides both hardware and software training . better staff good teaching n healthy environment",
    name: "Priyanka Kulmethe",
    userId: "@priyanka",
    date: "May 19, 2022"
},
{
    comment: "Thanks to Xceller IT Services. Its good option to place in Good Company. Better teaching staff and with Cooperative Environment.",
    name: "Rohini Gaikwad",
    userId: "@rohini",
    date: "May 06, 2022"
},
{
    comment: "Thanks to the Xceller IT services,It's a great place to build your career and get suitable job. It was good experience with trainer.",
    name: "swati gurnule",
    userId: "@swati",
    date: "May 06, 2022"
},
{
    comment: "It’s good option to place in Good company It’s provides both options hardware and software training . Better teaching staff with cooperative and healthy environment.",
    name: "akash wagode",
    userId: "@akash",
    date: "Apr 07, 2022"
},
{
    comment: "There is a lot of desire platform for singing knowledge",
    name: "ANKIT RAHULKAR",
    userId: "@ankit",
    date: "Mar 25, 2022"
},
{
    comment: "Excellent platform to improve your skills",
    name: "Saru Tiwaskar",
    userId: "@saru",
    date: "Dec 27, 2021"
},
{
    comment: "Great Experience !! Would recommend to all those would like to set up Coaching and learn.",
    name: "piyusha bandre",
    userId: "@piyusha",
    date: "Dec 27, 2021"
},
{
    comment: "This is a great place to learn and grow.",
    name: "50_Mayank patil",
    userId: "@mayank",
    date: "Dec 16, 2021"
},
{
    comment: "Good training center for networking.Staff is very good.Training provided is very good and practical.",
    name: "Sanket Avaskar",
    userId: "@sanket",
    date: "Nov 19, 2021"
},
{
    comment: "I have done Training from Xceller and got good and satisfied job . It's very good for building your career and get suitable job",
    name: "Priya Agarwal",
    userId: "@priya",
    date: "Nov 18, 2021"
}]

export const instagramComments = [{
    comment: "Great place to shape your career..... Learn with practical and supportive staff",
    name: "Amit Bankar",
    userId: "@amit",
    date: "13w"
}, {
    comment: "It is very greate place to improve your performance and skills and talent.",
    name: "Avinash Devmurar",
    userId: "@avinash",
    date: "17w"
}, {
    comment: "Good place for gain knowledge it's give you 100℅placement",
    name: "Vishakha Ghagre",
    userId: "@vishakha",
    date: "17w"
}, {
    comment: "It's good place to shape your carrier. I told my experience in Xceller I got trained from this institute and got place in Pune MNC.",
    name: "Ashish Katre",
    userId: "@ashish",
    date: "20w"
},
{
    comment: "Bro my facebook account and twitter account was hacked and he disable my account please can you help me",
    name: "sameer",
    userId: "@sameer",
    date: "28w"
},
{
    comment: "Its a good option to placed in good company. Its provides both hardware and software training . better staff good teaching n healthy environment",
    name: "Priyanka Kulmethe",
    userId: "@priyanka",
    date: "30w"
},
{
    comment: "Thanks to Xceller IT Services. Its good option to place in Good Company. Better teaching staff and with Cooperative Environment.",
    name: "Rohini Gaikwad",
    userId: "@rohini",
    date: "30w"
},
{
    comment: "Thanks to the Xceller IT services,It's a great place to build your career and get suitable job. It was good experience with trainer.",
    name: "swati gurnule",
    userId: "@swati",
    date: "90w"
},
{
    comment: "It’s good option to place in Good company It’s provides both options hardware and software training . Better teaching staff with cooperative and healthy environment.",
    name: "akash wagode",
    userId: "@akash",
    date: "100w"
},
{
    comment: "There is a lot of desire platform for singing knowledge",
    name: "ANKIT RAHULKAR",
    userId: "@ankit",
    date: "100w"
},
{
    comment: "Excellent platform to improve your skills",
    name: "Saru Tiwaskar",
    userId: "@saru",
    date: "110w"
},
{
    comment: "Great Experience !! Would recommend to all those would like to set up Coaching and learn.",
    name: "piyusha bandre",
    userId: "@piyusha",
    date: "112w"
},
{
    comment: "This is a great place to learn and grow.",
    name: "50_Mayank patil",
    userId: "@mayank",
    date: "120w"
},
{
    comment: "Good training center for networking.Staff is very good.Training provided is very good and practical.",
    name: "Sanket Avaskar",
    userId: "@sanket",
    date: "140w"
},
{
    comment: "I have done Training from Xceller and got good and satisfied job . It's very good for building your career and get suitable job",
    name: "Priya Agarwal",
    userId: "@priya",
    date: "175w"
}]
export const REDIRECT_SURVEY_ID = ["878ab3b6-d345-4a99-b9b0-3f36ba16117f", "f3c7c3bb-aa87-4042-89da-8b67cde9cdf3", "33f9b329-569a-4c4b-a152-0c04616a1733", "5201e037-03a0-4507-b4f7-f2fff78b2938"];

export const SELF_INDICATOR_REPORTS = ["f3c7c3bb-aa87-4042-89da-8b67cde9cdf3", "33f9b329-569a-4c4b-a152-0c04616a1733", "5cf442a8-1e3c-4bc7-b0c8-9091aef42a75"];

export const REPORTWISEMENU = {
    CPULSE: ["analysis","question_analysis", "response_rate", "respondent_report"],
    CSATV3: ["analysis", "theme_analysis", "question_analysis", "response_rate", "respondent_report"],
    CSATV5: ["analysis", "theme_analysis", "question_analysis", "response_rate", "respondent_report"],
    ESATV3: ["analysis","question_analysis", "response_rate", "respondent_report"],
    ESATPULSE: ["analysis", "question_analysis","benchmark_analysis", "statistical_analysis", "response_rate", "respondent_report"],
    Exit: ["analysis","question_analysis", "response_rate", "respondent_report"],
    HIV3: ["analysis", "response_rate"],
    HIV4: ["analysis", "response_rate"],
    NPSV3: ["analysis", "question_analysis", "response_rate", "respondent_report"],
    PMF: ["analysis", "question_analysis", "response_rate", "respondent_report"],
    Pulse: ["analysis","question_analysis", "benchmark_analysis",  "statistical_analysis", "response_rate"],
    SSAT: ["analysis", "question_analysis", "response_rate", "respondent_report"],
    WellnessV2: ["analysis", "benchmark_analysis", "response_rate"],
    CHECKLIST: ["question_analysis", "inspection_report"],
    Default: ["question_analysis", "response_rate", "respondent_report"],
    QUANTITATIVEAPPTITUDE: ["question_analysis", "respondent_report"],
    EXITV2: ["exit_reason","question_analysis", "response_rate", "respondent_report"],
    ENPS: ["analysis", "question_analysis", "response_rate"],
    HIMOOD:["analysis","response_rate"],
    CEODASHBOARD:["culturemap","comparators","statementwise","detailtrend","mood_o_meter","response_rate","employee_comments"],
    // PULSETENSCALE: ["analysis", "nps_scores"], as discuss with pranay sir for "herofincorp" no need to display the hide menu option in the pulsetenscale (21/1/24)
    // Diversity:["response_rate"],
    // Wellness:["response_rate"],
    ESATPULSEWITHOUTANONYMITY: ["benchmark_analysis", "question_analysis", "statistical_analysis", "response_rate", "respondent_report"],
}

export const ShowColumn = [
    {
        name: "fname",
        label: "Name"
    },
    {
        name: "email",
        label: "Email"
    },
    {
        name: "country_code",
        label: "Country Code"
    },
    {
        name: "contact_number",
        label: "Contact Number"
    },
    {
        name: "username",
        label: "User Name"
    }
]

export const ShowColumnTabWise = {
    'employee': {
        name: "participant_id",
        label: "Participant ID"
    },
    'customer': {
        name: "participant_id",
        label: "Participant ID"
    },
    'other':{
        name: "participant_id",
        label: "Participant ID"
    }
};

export const playBoardHeader =  [
    {
        ENPS: "Participants",
        other: "Participants",
    },
    {
        ENPS: "Responses",
        other: "Responses",
    },
    // {
    //     ENPS: "Participation %",
    //     other: "Percentage %",
    // },
    {
        ENPS: "Promotors",
        other: "Negative Count",
    },
    // {
    //     ENPS: "Promotors %",
    //     other: "Promotors %",
    // },
    {
        ENPS: "Passives",
        other: "Neutral Count",
    },
    // {
    //     ENPS: "Passives %",
    //     other: "Neutral Scores %",
    // },
    {
        ENPS: "Detractors",
        other: "Positive Count",
    },
    // {
    //     ENPS: "Detractors %",
    //     other: "Positive Scores %",
    // },
    {
        ENPS: "e-NPS Score",
        other: "Score",
    },
    // {
    //     ENPS: "text",
    //     other: "text",
    // }
];
 
// polls constant
export const POLL_DASHBOARD_CARDS = [
    { id: 1, card_name: 'Total Poll', data_label: "total_polls", card_icon: 'template_ic', status: 'total' },
    { id: 2, card_name: 'Active Poll', data_label: "active_polls", card_icon: 'publish_ic', status: 'active' },
    { id: 3, card_name: 'Inactive Poll', data_label: "inactive_polls", card_icon: 'template_ic', status: 'inactive' },
    { id: 4, card_name: 'Closed Poll', data_label: "closed_polls", card_icon: 'closed_ic', status: 'close' }
] 
