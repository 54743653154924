import * as ActionType from "./actionTypes"

export const reportScreenShot = (payload = {}) => {
    return {
        type: ActionType.SET_SCREENSHOT_REF,
        payload: payload
    }
}

export const hideDownload = (payload = {}) => {
    return {
        type: ActionType.HIDE_MAIN_DOWNLOAD,
        payload: payload
    }
}

export const buValue = (payload = {}) => {
    return {
        type: ActionType.BU_VALUE,
        payload: payload
    }
}