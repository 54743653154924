import React from 'react';
import { Switch } from "react-router-dom";
import router from "./router"
import PrivateRoute from './PrivateRoute';
import { connect } from "react-redux";
import RawDataUpload from 'pages/RawDataUpload';

/**
 * Lazy Load page
 */
const PageNotFound = React.lazy(() => import('components/PageNotFound'));

const InnerRoutes = ({ appState }) => {

    const appAccesses = appState?.access_matrix || {};
    const rootPages = Object.keys(appAccesses);
    let feedbackRole = appState?.user?.assigned_roles || {};
    let dashboardCEO = appState?.user?.assigned_roles || {};

    return (
        <Switch>
            {
                router.map((route, index) => {
                    if ((feedbackRole.length === 1 && feedbackRole[0]?.includes("Feedback")) && route.feedback_access !== true) {
                        return null
                    }
                    if ((dashboardCEO.length === 1 && dashboardCEO[0]?.includes("CEO")) && route.dashboard_ceo !== true) {
                        return null
                    }
                    if (route.skip_access === false && rootPages.includes(route.access_page) && appAccesses[route.access_page] !== undefined && appAccesses[route.access_page].includes(route.min_permission) && route.component) {
                        return <PrivateRoute key={index} path={route.path} exact={true} component={route.component} />
                    }
                    if (route.skip_access === true && route.component) {
                        return <PrivateRoute key={index} path={route.path} exact={true} component={route.component} />
                    }
                    return null
                })
            }
            <PrivateRoute path="/survey/upload-raw-data/:survey_id" component={RawDataUpload} />
            <PrivateRoute component={PageNotFound} />
        </Switch>
    )
}

const mapStateToProps = state => {
    return {
        appState: state.app.appState,
    }
}

export default connect(mapStateToProps)(InnerRoutes);