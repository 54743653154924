import * as actionsTypes from "store/actions/actionTypes";

const initalState = {
    surveyName: "",
    categoryName: "",
    action: "create",
    status: "draft",
    canLaunch: false,
    surveyType: null,
    showCheckList: false,
    surveyLink: null,
    mapTheme: false,
    selectedLocales: [],
    selectedLocale: 'en',
    thankEmail: false,
    surveyLogos: { 'partner_logo': null, 'company_logo': null },
    reportType: "",
    surveyList: null,
    surveySettings: {}
}

const surveyReducer = (state = initalState, action) => {
    switch (action.type) {
        case actionsTypes.SET_SURVEY_NAME:
            return {
                ...state,
                surveyName: action.payload.surveyName
            }
        case actionsTypes.SET_SURVEY_CATEGORY:
            return {
                ...state,
                categoryName: action.payload.categoryName
            }
        case actionsTypes.SET_SURVEY_META:
            return {
                ...state,
                action: action.payload.action.toLowerCase(),
                status: action.payload.status.toLowerCase(),
            }
        case actionsTypes.SET_LAUNCHABLE:
            return {
                ...state,
                canLaunch: action.payload.launchable,
            }
        case actionsTypes.SET_SURVEY_STATUS:
            return {
                ...state,
                status: action.payload.status,
            }
        case actionsTypes.VIEW_THEME_MAP:
            return {
                ...state,
                mapTheme: action.payload.view,
            }

        case actionsTypes.VIEW_SURVEY_CHECKLIST:
            return {
                ...state,
                showCheckList: action.payload.view,
            }
        case actionsTypes.SET_SELECTED_LOCALES:
            return {
                ...state,
                selectedLocales: action.payload.locales,
            }
        case actionsTypes.SET_SELECTED_LOCALE:
            return {
                ...state,
                selectedLocale: action.payload.locale,
            }
        case actionsTypes.SET_SURVEY_LOGO:
            return {
                ...state,
                surveyLogos: action.payload.surveyLogos
            }
        case actionsTypes.SET_SURVEY_TYPE:
            return {
                ...state,
                surveyType: action.payload.type
            }
        case actionsTypes.SET_SURVEY_LINK:
            return {
                ...state,
                surveyLink: action.payload.surveyLink
            }
        case actionsTypes.SET_THANK_EMAIL:
            return {
                ...state,
                thankEmail: action.payload.thankEmail
            }
        case actionsTypes.SET_REPORT_TYPE:
            return {
                ...state,
                reportType: action.payload.reportType
            }
        case actionsTypes.SET_SURVEY_LIST:
            return {
                ...state,
                surveyList: action.payload.surveyList
            }
        case actionsTypes.SET_SURVEY_SETTING:
            return {
                ...state,
                surveySettings: action.payload.surveySettings
            }
        case actionsTypes.RESET_STATE:
            return initalState
        default:
            return state
    }
}

export default surveyReducer
