import React, { useContext, useEffect, useState } from 'react';
import '../assets/scss/sidebar.scss';
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import AppContext from 'store/AppContext';
import HumburgerMenu from '../assets/images/sidebar/Qznx_humburger_menu.svg';
import QZNXLOGO from '../assets/images/sidebar/QZNX_logo.svg';
import DDB from '../assets/images/sidebar/ddb.jpeg';

const Sidebar = ({ sidebarPara, appState, vendorInfo }) => {

    const { languageObj = {} } = useContext(AppContext);
    const appAccesses = appState?.access_matrix || {};
    const userRole = appState?.user?.assigned_roles || {};
    const rootPages = Object.keys(appAccesses);
    const collection = ["templates", "themes", "peoples", "question_bank"];
    const settings = ["company_profile", "account_management", "sms_setting", "smtp_setting", "recurring_setting", "kpi_setting", "whatsapp_setting", "slack_setting", "google_setting", "survey_workflow"];
    const [hoverSidebar, setHoverSidebar] = useState(window.innerWidth <= 768 ? false : true)
    const company_id = appState?.current_app_detail?.id || '';
    const mobileClass = window.innerWidth <= 768 ? true : false;
/**
 * Opens the menu on hover.
 *
 * @return {void} No return value.
 */
    const openMenuHover = () => {
        if (mobileClass) {
            setHoverSidebar(false);
            return
        }
        const divEl = document.getElementById('sidebar');
        if (divEl && hoverSidebar) {
            divEl.addEventListener('mouseover', () => setHoverSidebar(false));
        }
        if (divEl && !hoverSidebar) {
            divEl.addEventListener('mouseout', () => setHoverSidebar(true));
        }
    }

    /**
     * A function to handle the sidebar functionality.
     */
    const handleSidebar = () => {
        sidebarPara.openMenu('');
        sidebarPara.overlaySidebar();
    }
    
    /*eslint-disable */
    useEffect( () => {
        openMenuHover();
    },[hoverSidebar])
    return (
        <React.Fragment>
            <nav id="sidebar" className={`sidebar ${sidebarPara.sidebar && mobileClass ? "active in-mobile" : ""} in-larrge ${hoverSidebar ? "sidebarMenu" : ""}`} >
                <div className='sidebar-wrapper'>
                    <div className="sidebar-navigation">
                        <button type="button" className="humburger-button" onClick={() => {sidebarPara.overlaySidebar, sidebarPara.openMenu('')}}>
                            {hoverSidebar ? <img alt="" src={company_id === '9957e72e-9684-401c-b914-b17fec274d7c' ? DDB : vendorInfo?.logo || HumburgerMenu} className='humburger-logo sideBarhover' /> : <img alt="" src={vendorInfo?.logo || QZNXLOGO} className="humburger-logo" />
                            }
                        </button>
                    </div>
                    <ul className="sidebar-list">
                        {userRole[0]?.includes("Feedback") !== true && <li className="sidebar-item">
                            <NavLink exact activeClassName='link-active' to="/" onClick={handleSidebar} className={`side-icon dashboard ${hoverSidebar ? 'sideBarhover' : ''}`}>
                                <span className={`${hoverSidebar ? '' : 'title-display'}`}>
                                    {languageObj.translate('Dashboard.1')}
                                </span>
                            </NavLink>
                        </li>}
                        {userRole.length === 1 && userRole[0]?.includes("Feedback") &&
                            <li className={`sidebar-item`}>
                                <NavLink activeClassName='link-active' to="/survey-list" onClick={handleSidebar} className={`side-icon reports ${hoverSidebar ? 'sideBarhover' : ''}`}>
                                    <span className={`${hoverSidebar ? '' : 'title-display'}`}>FeedBack</span>
                                </NavLink>
                            </li>
                        }
                        {rootPages.includes("survey") && appAccesses["survey"] !== undefined && appAccesses["survey"].includes("READ") && userRole.includes("Feedback") !== true &&
                            <li className="sidebar-item">
                                <NavLink activeClassName='link-active' to="/survey-dashboard" onClick={handleSidebar} className={`side-icon surveys ${hoverSidebar ? 'sideBarhover' : ''}`}>
                                    <span className={`${hoverSidebar ? '' : 'title-display'}`}>{languageObj.translate('Surveys.1')}</span>
                                </NavLink>
                            </li>}
                        {rootPages.includes("poll") && appAccesses["poll"] !== undefined && appAccesses["poll"].includes("READ") && userRole.includes("Feedback") !== true &&
                            <li className="sidebar-item">
                                <NavLink activeClassName='link-active' to="/poll-dashboard/category-poll-dashboard" onClick={() => {sidebarPara.overlaySidebar, sidebarPara.openMenu('')}} className={`side-icon poll ${hoverSidebar ? 'sideBarhover' : ''}`}>
                                    <span className={`${hoverSidebar ? '' : 'title-display'}`}>{languageObj.translate('Polls.1')}</span>
                                </NavLink>
                            </li>}
                        {rootPages.includes("report_dashboard") && appAccesses["report_dashboard"] !== undefined && appAccesses["report_dashboard"].includes("READ") && userRole.includes("Feedback") !== true &&
                            <li className={`sidebar-item`}>
                                <NavLink activeClassName='link-active' to="/reports" onClick={handleSidebar} className={`side-icon reports ${hoverSidebar ? 'sideBarhover' : ''}`}>
                                    <span className={`${hoverSidebar ? '' : 'title-display'}`}>{languageObj.translate('Reports.1')}</span>
                                </NavLink>
                            </li>}
                        {rootPages.includes("lifecycle") && appAccesses["lifecycle"] !== undefined && appAccesses["lifecycle"].includes("READ") && userRole.includes("Feedback") !== true &&
                            <li className={`sidebar-item`}>
                                <NavLink activeClassName='link-active' to="/life-cycle" onClick={handleSidebar} className={`side-icon life-cycle ${hoverSidebar ? 'sideBarhover' : ''}`}>
                                    <span className={`${hoverSidebar ? '' : 'title-display'}`}>{languageObj.translate('Lifecycle.1')}</span>
                                </NavLink>
                            </li>}
                        {/* {rootPages.includes("lifecycle") && appAccesses["lifecycle"] !== undefined && appAccesses["lifecycle"].includes("READ") && userRole.includes("Feedback") !== true && */}
                            <li className={`sidebar-item`}>
                                <NavLink activeClassName='link-active' to="/joy-o-meter" onClick={() => {sidebarPara.overlaySidebar, sidebarPara.openMenu('')}} className={`side-icon joy-meter ${hoverSidebar ? 'sideBarhover' : ''}`}>
                                    <span className={`${hoverSidebar ? '' : 'title-display'}`}>{languageObj.translate('joy-o-meter.1')}</span>
                                </NavLink>
                            </li>
                        {collection.some(item => rootPages.includes(item)) &&
                            <><li className={`sidebar-item submenu ${(sidebarPara.menuOpen === 'collection') ? "active" : ""}`}>
                                <a onClick={() => sidebarPara.openMenu('collection')} className={`side-icon collection ${hoverSidebar ? 'sideBarhover' : ''}`}>
                                    <span className={`${hoverSidebar ? '' : 'title-display'}`}>{languageObj.translate('Collection.1')}</span>
                                </a>
                            </li>
                                <ul className={`p-0 sidebar-submenu-item ${(sidebarPara.menuOpen === 'collection' && !hoverSidebar) ? "collection-active" : ""}`}>
                                    {rootPages.includes("peoples") && appAccesses["peoples"] !== undefined && appAccesses["peoples"].includes("READ") && <li className="sub-sidebar-item">
                                        <NavLink activeClassName='link-active' to="/people-directory" onClick={sidebarPara.overlaySidebar} className="side-icon people">
                                            {languageObj.translate('People.1')}
                                        </NavLink>
                                    </li>}
                                    {rootPages.includes("templates") && appAccesses["templates"] !== undefined && appAccesses["templates"].includes("READ") && <li className="sub-sidebar-item">
                                        <NavLink activeClassName='link-active' to="/template-dashboard" onClick={sidebarPara.overlaySidebar} className="side-icon templates">
                                            {languageObj.translate('Templates.1')}
                                        </NavLink>
                                    </li>}

                                    {rootPages.includes("themes") && appAccesses["themes"] !== undefined && appAccesses["themes"].includes("READ") && <li className="sub-sidebar-item">
                                        <NavLink activeClassName='link-active' to="/themes" onClick={sidebarPara.overlaySidebar} className="side-icon themes">
                                            {languageObj.translate('Themes.1')}
                                        </NavLink>
                                    </li>}
                                </ul></>}
                        {settings.some(item => rootPages.includes(item)) &&
                            <><li className={`sidebar-item submenu ${(sidebarPara.menuOpen === 'setting') ? "active" : ""}`}>
                                <a onClick={() => sidebarPara.openMenu('setting')} className={`side-icon settings ${hoverSidebar ? 'sideBarhover' : ''}`}>
                                    <span className={`${hoverSidebar ? '' : 'title-display'}`}>{languageObj.translate('Settings.1')}</span>
                                </a>
                            </li>
                                <ul className={`p-0 sidebar-submenu-item ${(sidebarPara.menuOpen === 'setting' && !hoverSidebar) ? "setting-active" : ""}`}>
                                    {rootPages.includes("company_profile") && appAccesses["company_profile"] !== undefined && appAccesses["company_profile"].includes("READ") && <li className="sub-sidebar-item">
                                        <NavLink activeClassName='link-active' to="/company-profile" onClick={sidebarPara.overlaySidebar} className="side-icon company-profile">
                                            {languageObj.translate('CompanyProfile.1')}
                                        </NavLink>
                                    </li>}
                                    {rootPages.includes("account_management") && appAccesses["account_management"] !== undefined && appAccesses["account_management"].includes("READ") && <li className="sub-sidebar-item">
                                        <NavLink activeClassName='link-active' to="/users-roles" onClick={sidebarPara.overlaySidebar} className="side-icon user-roles">
                                            {languageObj.translate('UserManagement.1')}
                                        </NavLink>
                                    </li>}
                                    {rootPages.includes("survey_workflow") && appAccesses["survey_workflow"] !== undefined && appAccesses["survey_workflow"].includes("READ") && <li className="sub-sidebar-item">
                                        <NavLink activeClassName='link-active' to="/survey-workflow" onClick={sidebarPara.overlaySidebar} className="side-icon survey-workflow">
                                            {languageObj.translate('survey_workflows.1')}
                                        </NavLink>
                                    </li>}
                                    {rootPages.includes("kpi_setting") && appAccesses["kpi_setting"] !== undefined && appAccesses["kpi_setting"].includes("READ") && <li className="sub-sidebar-item">
                                        <NavLink activeClassName='link-active' to="/kpi-settings" onClick={sidebarPara.overlaySidebar} className="side-icon kpi-setting">
                                            {languageObj.translate('kpi_setting.1')}
                                        </NavLink>
                                    </li>}
                                    {rootPages.includes("recurring_setting") && appAccesses["recurring_setting"] !== undefined && appAccesses["recurring_setting"].includes("READ") && <li className="sub-sidebar-item">
                                        <NavLink activeClassName='link-active' to="/recurring-setting" onClick={sidebarPara.overlaySidebar} className="side-icon recurring">
                                            {languageObj.translate('recurring_setting.1')}
                                        </NavLink>
                                    </li>}
                                    {rootPages.includes("smtp_setting") && appAccesses["smtp_setting"] !== undefined && appAccesses["smtp_setting"].includes("READ") && <li className="sub-sidebar-item">
                                        <NavLink activeClassName='link-active' to="/smtp-settings" onClick={sidebarPara.overlaySidebar} className="side-icon smtp-setting">
                                            {languageObj.translate('smtp_setting.1')}
                                        </NavLink>
                                    </li>}
                                    {rootPages.includes("whatsapp_setting") && appAccesses["whatsapp_setting"] !== undefined && appAccesses["whatsapp_setting"].includes("READ") && <li className="sub-sidebar-item">
                                        <NavLink activeClassName='link-active' to="/whatsapp-settings" onClick={sidebarPara.overlaySidebar} className="side-icon whats-app-setting">
                                            {languageObj.translate('whatsApp_setting.1')}
                                        </NavLink>
                                    </li>}
                                    {rootPages.includes("slack_setting") && appAccesses["slack_setting"] !== undefined && appAccesses["slack_setting"].includes("READ") && <li className="sub-sidebar-item">
                                        <NavLink activeClassName='link-active' to="/slack-settings" onClick={sidebarPara.overlaySidebar} className="side-icon whats-app-setting">
                                            {languageObj.translate('SlackSetting.1')}
                                        </NavLink>
                                    </li>}
                                    {rootPages.includes("sms_setting") && appAccesses["sms_setting"] !== undefined && appAccesses["sms_setting"].includes("READ") && <li className="sub-sidebar-item">
                                        <NavLink activeClassName='link-active' to="/websms-settings" onClick={sidebarPara.overlaySidebar} className="side-icon sms-setting">
                                            {languageObj.translate('sms_setting.1')}
                                        </NavLink>
                                    </li>}
                                    {rootPages.includes("google_setting") && appAccesses["google_setting"] !== undefined && appAccesses["google_setting"].includes("READ") && <li className="sub-sidebar-item">
                                        <NavLink activeClassName='link-active' to="/google-review" onClick={sidebarPara.overlaySidebar} className="side-icon google-review-setting">
                                            {languageObj.translate('google_review_setting.1')}
                                        </NavLink>
                                    </li>}
                                    {userRole.includes("Company Admin") && <li className="sub-sidebar-item">
                                        <NavLink activeClassName='link-active' to="/sso-settings" onClick={sidebarPara.overlaySidebar} className="side-icon google-review-setting">
                                            SSO Settings
                                        </NavLink>
                                    </li>}

                                </ul></>}

                        {rootPages.includes("action_planning") && appAccesses["action_planning"] !== undefined && appAccesses["action_planning"].includes("READ") &&
                            <li className={`sidebar-item`}>
                                <NavLink activeClassName='link-active' to="/action-plan" onClick={handleSidebar} className={`side-icon action ${hoverSidebar ? 'sideBarhover' : ''}`}>
                                    <span className={`${hoverSidebar ? '' : 'title-display'}`}>{languageObj.translate('ActionPlan.1')}</span>
                                </NavLink>
                            </li>}
                        {userRole?.length > 0 && userRole.includes("Chat User") &&
                            <li className={`sidebar-item`}>
                                <NavLink activeClassName='link-active' to="/chat" onClick={() => {sidebarPara.overlaySidebar, sidebarPara.openMenu('')}} className={`side-icon chat ${hoverSidebar ? 'sideBarhover' : ''}`}>
                                    <span className={`${hoverSidebar ? '' : 'title-display'}`}>Chat</span>
                                </NavLink>
                            </li>}
                        {appState?.user?.partner_id !== "9828316c-46f1-4429-9200-c67c11c086d0" && <li className="sidebar-item">
                            <a className={`side-icon support ${hoverSidebar ? 'sideBarhover' : ''}`} href="https://support.qaizenx.com" target="_blank" rel="noopener noreferrer">
                                <span className={`${hoverSidebar ? '' : 'title-display'}`}>{languageObj.translate('Support.1')}</span>
                            </a>
                        </li>}

                    </ul>
                    {/* <div className="copyright-text">
                        © {new Date().getFullYear()} QaizenX &reg;. <br />All Rights Reserved.
                    </div> */}
                </div>
            </nav>
            {/*--------------- in mobile------------------------- */}
           
        </React.Fragment>

    )
}

const mapStateToProps = state => {
    return {
        appState: state.app.appState,
    }
}
/*eslint-enable */
export default connect(mapStateToProps)(Sidebar);

