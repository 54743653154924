import React, { useState } from 'react';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import useSidebarHooks from './hooks/SidebarNavbarHook';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { ToastContainer, toast, Zoom } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import './assets/scss/variable.scss';
import './App.scss';
import './assets/font/NunitoSans/NunitoSans.css';
import './assets/font/Montserrat/Montserrat.css';
// import './assets/font/bordeaux-medium/bordeaux.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useTranslation } from 'react-i18next';
import AppContext from './store/AppContext';
import LANGUAGES from "constants/languages";
import InnerRoutes from 'router/InnerRoutes';
import { appAccess } from 'FeatureAccess';
import EziLoader from 'components/EziLoader';
import { connect } from "react-redux";
import FeedbackChangePassword from 'pages/Profile/FeedbackChangePassword';
const FeedbackLogin = React.lazy(() => import('pages/Login/FeedbackLogin'));
const ForgotPassword = React.lazy(() => import('pages/ForgetPassword/FeedbackForgotPassword'));
const CreatePassword = React.lazy(() => import('pages/ForgetPassword/CreatePassword'));
const LoginFeedbackSurvey = React.lazy(() => import('pages/FeedbackSurvey/LoginFeedbackSurvey'));
const ParticipantLogin = React.lazy(() => import('pages/ParticipantLogin/ParticipantLogin'));
const Dashboard = React.lazy(() => import('pages/Dashboard/Dashboard'));
const ReasonModelChecklist = React.lazy(() => import('pages/Report/ReportType/ReportMenuComponent/RespondentReport/ReasonModelChecklist'))
const UserChat = React.lazy(()=> import('pages/Chat/UserChat'));
const NotificationComponent = React.lazy(() => import('components/NotificationComponent'));

function FeedbackApp({ appState, themeColor, copyright, copyRightText, loginImage, isElectron, accountID, microsoftLogin }) {
	
	/**
	 * Initial App state for login, ACL Matrix
	 */
	const [translate, setTranslate] = useTranslation();
	const [isLoading, setIsLoading] = useState(false);
	const [surveyId, setSurveyId] = useState("");
	const sidebarData = useSidebarHooks();

	/**
	 * Global Loader
	 */
	const globalLoader = () => {
		return {
			show: () => {
				setIsLoading(true)
			},
			hide: () => {
				setIsLoading(false)
			}
		}
	}

	/**
	 * Report Insight
	 */
	// 18/01/2023 - as discussed with Vibhay sir and Durgesh sir 
	// const reportInsight = appState?.access_matrix?.report_insight ? true : false


	/**
	 * Theme Color
	 */
	let companyColor = appState?.current_app_detail?.theme_color ? appState?.current_app_detail?.theme_color : appState?.current_partner_account?.theme_color
	let companyThemeColor = themeColor ? themeColor : (companyColor || '#ce2e6c')
	document.documentElement.style.setProperty("--company-theme-color", companyThemeColor);

	/**
	 * Initial Language Data.
	 */
	const languageObj = {
		curLang: localStorage.getItem("i18nextLng"),
		languages: LANGUAGES,
		changeLang: (langCode = "En") => setTranslate.changeLanguage(langCode),
		translate: translate
	}

	/**
	 * Notify Access warning
	 */
	const handleUnAuthWarn = () => {
		toast.warn(languageObj.translate("survey.you_are_not_authorized_to_access_this_feature"))
	}

	const setSurveyCurrentID = (id) => {
		setSurveyId(id);
	}

	return (
		<AppContext.Provider value={{ appState, languageObj, handleUnAuthWarn,setSurveyCurrentID, EziLoader: globalLoader(), companyThemeColor, isElectron}} >
			<Router>
				<Switch>
					<Route exact path="/login/:token?" render={props => <FeedbackLogin {...props} loginImage={loginImage} accountID={accountID} microsoftLogin={microsoftLogin}/>} />
					<Route exact path="/feedback-login/:login_token" render={props => <FeedbackLogin {...props} loginImage={loginImage}/>} />
					<Route exact path="/reset-password-link" component={ForgotPassword} />
					<Route exact path="/thank-you" render={props => <NotificationComponent {...props} message={"Thank You"} isthankyou={true} />} />
					<Route exact path="/incorrectlogindetails" render={props => <NotificationComponent {...props} message={"Incorrect Details"} isIncorrect={true} />} />
					<Route exact path="/reset-password/:user_id/:token" component={CreatePassword} />
                    <Route exact path="/feedback/:survey_id/:company_id?/:respondant_id?/:communication_medium?/:web_view_call?" component={LoginFeedbackSurvey} />
					<React.Fragment>
						{appState?.platform ? <div className="main-wrapper">
							<Navbar surveyId={surveyId} navbarPara={sidebarData}  platform="feedback" />
							<div className="inner-wrapper">
                            <Route exact path="/" render={props => <Dashboard {...props} />} />
                            <Route exact path="/change-password" component={FeedbackChangePassword} />
                            <Route exact path="/live-chat" component={UserChat} />
								{!copyright && <div className="footer-bottom">
									{copyRightText ? <p>{copyRightText}</p> : <p>&copy; {new Date().getFullYear()} QaizenX &reg;. {languageObj.translate('company_footer.1')}.</p>}
								</div>}
							</div>

							<div className={`overlay ${sidebarData.sidebar ? "active" : ""}`} onClick={sidebarData.overlaySidebar} ></div>
						</div>
                        :
                        <Redirect to="/login" />
                    }
					</React.Fragment>
				</Switch>
			</Router>
			<ToastContainer transition={Zoom} position={toast.POSITION.TOP_RIGHT} closeButton={false} hideProgressBar={true} />
			{isLoading && <EziLoader />}
		</AppContext.Provider>
	);
}

const mapStateToProps = state => {
	return {
		appState: state.app.appState,
		language: state.app.language,
	}
}

export default connect(mapStateToProps)(FeedbackApp);