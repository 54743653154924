import * as actionsTypes from "store/actions/actionTypes";

const initalState = {
    screenShotRef: [],
    fullPageScreenShotRef: "",
    buValue: null
}

const reportReducer = (state = initalState, action) => {
    switch (action.type) {
        case actionsTypes.SET_SCREENSHOT_REF:
            return {
                ...state,
                screenShotRef: action.payload.screenShotRef,
                fullPageScreenShotRef: action.payload.fullPageScreenShotRef
            }
        case actionsTypes.HIDE_MAIN_DOWNLOAD:
            return {
                ...state,
                switchpage: action.payload.switchpage,
            }
            case actionsTypes.BU_VALUE:
                return {
                    ...state,
                    buValue: action.payload,
                };
        default:
            return state
    }
}

export default reportReducer
