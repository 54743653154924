export const API_URL = 'https://serv-app.teamqaizenx.com/api/';
export const RPT_URL = 'https://rpt-api.teamqaizenx.com/api/';
export const RPT_URL_BASE = 'https://rpt-api.teamqaizenx.com/';
export const EZI_COUPON = 'https://dev-app-api.ezicoupons.online/api/';
export const IAM_URL = 'https://serv-iam.teamqaizenx.com/api/';
export const ADVANCE_REPORT_LOGIN = 'https://advancereport.teamqaizenx.com/';
export const REPORT_TYPE_NAME_FOR_POLL = 'POLL';
export const URL = 'https://serv-app.teamqaizenx.com/';
export const REPORT_TYPE_ID_FOR_POLL = 'f0a7e809-66fb-4057-a6a9-ffe482d14b25';
 
const configURL = {
	verify_vendor: `${API_URL}vendor-verification`,
	loginURL: `${API_URL}login/company`,
	logoutURL: `${API_URL}logout`,
	otp_login: `${API_URL}otp-login`,
	send_otp: `${API_URL}request-otp`,
	reset_password_link: `${API_URL}reset-password-link`,
	reset_password: `${API_URL}reset-password`,
	login_iam_url: `${API_URL}login-with-credentials`,
	verify_partner_token: `${API_URL}partner/partner-company-login`,
	demographicURL: `${API_URL}v1/company-management/getallidentifiers`,
	addEmployeeURL: `${API_URL}v1/user-directory-management/addEmployee`,
	addSinglePeople: `${API_URL}v1/company-management/addsingle`,
	OtherContactDemographicURL: `${API_URL}v1/user-directory-management/getAllOtherDemographics`,
	AddOtherContactDemographicURL: `${API_URL}v1/user-directory-management/addOther`,
	employeeFilter: `${API_URL}v1/user-management/filter/employee`,
	customerFilter: `${API_URL}v1/user-management/filter/customer`,
	otherContactFilter: `${API_URL}v1/user-management/filter/other`,
	// excelHeaderURL: `${API_URL}v1/user-directory-management/excel`,
	excelHeaderURL: `${API_URL}addParticipantController/uploadPeopleExcel`,
	excelValidation: `${API_URL}v1/user-directory-management/excelentry`,
	excelAllentry: `${API_URL}v1/user-directory-management/allentry`,
	excelValidEntry: `${API_URL}v1/user-directory-management/validentry`,
	excelInvalidEntry: `${API_URL}v1/user-directory-management/invalidentry`,
	excelUpdateRow: `${API_URL}v1/user-directory-management/updatedataifstagging/`,
	processData: `${API_URL}v1/user-directory-management/processdata/`,
	customerDemographicURL: `${API_URL}v1/company-management/getallidentifiers`,
	excelDownload: `${API_URL}v1/user-directory-management/invalidentrywriter/employee`,
	get_compnay_details: `${API_URL}v1/partner-template/get-company-details`,
	save_compnay_details: `${API_URL}v1/partner-template/company-create-update`,
	addCustomer: `${API_URL}v1/user-directory-management/addCustomer`,
	themeListing: `${API_URL}v1/theme/themesviewer`,
	subthemeListing: `${API_URL}v1/theme/subthemesviewer`,
	categoryDs: `${API_URL}v1/theme/categoryviewer`,
	// bulkUpdate: `${API_URL}v1/online-bulk-update/bulkupdate`,
	bulkUpdate: `${API_URL}updateParticipantPeople/uploadUpdatePeopleExcel`,
	onlineUpdate: `${API_URL}v1/online-bulk-update/onlineedit`,
	people_bulk_delete: `${API_URL}v2/people/bulk-delete`,
	addTheme: `${API_URL}v1/theme/addThemes`,
	updateThemeRow: `${API_URL}v1/theme/themeEditor/theme`,
	updateSubthemeRow: `${API_URL}v1/theme/themeEditor/subtheme`,
	questionBank: `${API_URL}v1/questionbank`,
	themeDropdown: `${API_URL}v1/themedropdown`,
	deleteRow: `${API_URL}v1/theme/delete-master`,
	saveSurvey: `${API_URL}v1/survey/savesurvey`,
	getQuestionMapData: `${API_URL}v1/survey/get-mappings-data`,
	saveQuestionMapData: `${API_URL}v1/survey/save-mappings-data`,
	categoryListing: `${API_URL}v1/theme/categoryview`,
	categoryViewData: `${API_URL}v1/theme/categorydataviewer`,
	categorySave: `${API_URL}v1/theme/savecategory`,
	peopleByType: `${API_URL}v1/survey/filterlisting`,
	dynamicPeopleSourceLink: `${API_URL}v1/survey/participant-template-download`,
	raw_data_download: `${API_URL}v1/survey/raw-data-template-download`,
	raw_data_upload: `${API_URL}v1/survey/raw-data-upload`,
	getpeopleByRandom: `${API_URL}v1/survey/getpeopleByRandom`,
	addParticipantsToSurvey: `${API_URL}v1/survey/addParticipants`,
	saveSurveyQustions: `${API_URL}v1/survey/savesurveyquestions`,
	getSurveyQustions: `${API_URL}v1/survey/getsurveyquestions`,
	add_api_participant: `${API_URL}v1/survey/add-api-participant`,
	surveythemeDs: `${API_URL}v1/survey/thememapping`,
	surveySubThemeDs: `${API_URL}v1/survey/subthememapping`,
	createSurvey: `${API_URL}v1/survey/surveycreator`,
	getSurveyTypes: `${API_URL}v1/survey/get-types`,
	subThemesData: `${API_URL}v1/survey/subthemedataonload`,
	themeQuestionListing: `${API_URL}v1/survey/theme/questionlisting`,
	surveyDemographicfilter: `${API_URL}v1/survey/demographicsfilter`,
	participantFilters: `${API_URL}v1/survey/participants-filter`,
	participantFiltersOptions: `${API_URL}v1/survey/participants-filter-options`,
	surveyDemographicfilterSearch: `${API_URL}v1/survey/demographicsfiltersearch`,
	surveyQuestionMapping: `${API_URL}v1/survey/surveyquestionmapping`,
	surveyLogoDelete: `${API_URL}v1/survey/delete-logo`,
	survey_users_list: `${API_URL}v2/survey/survey-access-listing`,
	save_survey_users_access: `${API_URL}v2/survey/survey-access-store`,
	get_report_access_users: `${API_URL}v2/survey/get-report-access`,
	save_report_access_users: `${API_URL}v2/survey/save-report-access`,
	publishingSettingListing: `${API_URL}v1/survey/channeltemplates`,
	saveWhatsappTemplate: `${API_URL}v1/survey/save-whatsapp-templates`,
	saveTemplate: `${API_URL}v1/survey/save-templates`,
	updateWhatsappTemplate: `${API_URL}v1/survey/update-whatsapp-template`,
	updateTemplate: `${API_URL}v1/survey/update-template`,
	deleteCommunicationTemplate: `${API_URL}v2/survey/delete-template`,
	channelUpdate: `${API_URL}v1/survey/channelupdate`,
	storeSurveySetting: `${API_URL}v1/survey/surveysettingstore`,
	getSurveySetting: `${API_URL}v1/survey/get-survey-settings`,
	surveyLaunch: `${API_URL}v1/survey/launchsurvey`,
	getSurveydata: `${API_URL}v1/survey/survey-link/get-survey-details`,
	surveyFinishLater: `${API_URL}v1/survey/finish-later`,
	surveySavePageResponse: `${API_URL}v1/survey/page-wise-response`,
	verifySurveyResponse: `${API_URL}v1/survey/check-identifier`,
	getCompanydata: `${API_URL}v1/survey/survey-link/get-company-details`,
	addtemplates: `${API_URL}v1/survey/addtemplates`,
	companyLogo: `${API_URL}v1/survey/companaylogourl`,
	createIdentifier: `${API_URL}v1/user-directory-management/addidentifier`,
	updateIdentifier: `${API_URL}v1/user-directory-management/identifierupdate`,
	deleteIdentifier: `${API_URL}v1/user-directory-management/identifierdelete`,
	addIdentifierOption: `${API_URL}v1/user-directory-management/addoption`,
	send_reminder_url: `${API_URL}v1/survey/companaylogourl`,
	save_survey_response_url: `${API_URL}v1/survey/survey-response`,
	templatedownload: `${API_URL}v1/user-directory-management/templatedownload`,
	invalidEntriesDownload: `${API_URL}v1/user-directory-management/invalidentrywriter/`,
	deleteStaggingData: `${API_URL}v1/user-directory-management/deleteStaggingData/`,
	bulkExcelDownload: `${API_URL}v1/online-bulk-update/validexceldownload`,
	template_dashboard_initials: `${API_URL}v1/survey-template/initials-data`,
	dashboard_recent_template_url: `${API_URL}v1/user-directory-management/identifierdelete`,
	save_template_url: `${API_URL}v1/survey-template/saving`,
	get_template_details_url: `${API_URL}v1/survey-template/view-templates`,
	category_templates_url: `${API_URL}v1/survey-template/category-wise-templates`,
	category_templates_metadata_url: `${API_URL}v1/survey-template/category-wise-counts`,
	get_template_builder_initals: `${API_URL}v1/survey-template/template-questions-initials`,
	getTemplateQuestionsMapping: `${API_URL}v1/survey-template/get-mappings-data`,
	saveTemplateQuestionsMapping: `${API_URL}v1/survey-template/save-mappings-data`,
	save_template_builder_setting: `${API_URL}v1/survey-template/save-template-builder`,
	save_template_question: `${API_URL}v1/survey-template/save-template-question`,
	survey_dashboard_details: `${API_URL}v1/survey/dashboard-details`,
	get_survey_name: `${API_URL}v1/survey/get-survey-name`,
	category_wise_surveys: `${API_URL}v1/survey/category-wise-surveys`,
	survey_status_update: `${API_URL}v1/survey/status-update`,
	survey_name_update: `${API_URL}v1/survey/name-update`,
	selected_participants: `${API_URL}v1/survey/selected-participants`,
	identifiers_listing: `${API_URL}v1/survey/identifiers-listing`,
	save_identifier_mapping: `${API_URL}v1/survey/save-identifier-mapping`,
	save_survey_identifier: `${API_URL}v1/survey/save-survey-identifier`,
	identifier_questions: `${API_URL}v1/survey/identifiers-questions`,
	download_response_sheet: `${API_URL}v1/survey/download-participant-sheet`,
	template_question_survey_name: `${API_URL}v1/survey-template/template-question-name`,
	rollback_survey: `${API_URL}v1/survey/rollback`,
	deleteSelectedParticipants: `${API_URL}v1/survey/delete-selected-participants`,
	delete_survey_participant: `${API_URL}v1/survey/delete-participant`,
	deleteCommunication: `${API_URL}v1/survey/delete-communication-history`,
	resetParticipantResponse: `${API_URL}v1/survey/reset-response`,
	saveChannelTemplates: `${API_URL}v1/survey/save-communication-templates`,
	delete_template: `${API_URL}v1/survey-template/deleting`,
	deletePeople: `${API_URL}v1/company-management/delete-single`,
	get_dashboard_count_graphs: `${API_URL}v1/survey/survey-count-graphs`,
	get_dashboard_survey_highlights: `${API_URL}v1/survey/recent-survey-highlights`,
	get_company_users: `${API_URL}v1/iam/get-users`,
	get_user_detail: `${API_URL}v1/iam/user-details`,
	save_user: `${API_URL}v1/iam/user`,
	save_role: `${API_URL}v1/iam/role`,
	get_company_roles: `${API_URL}v1/iam/get-roles`,
	get_company_profiles: `${API_URL}v1/iam/get-profiles`,
	delete_person: `${API_URL}v1/iam/user-delete`,
	delete_category: `${API_URL}v1/theme/category-delete`,
	edit_category: `${API_URL}v1/theme/category-update`,
	role_delete: `${API_URL}v1/iam/role-delete`,
	profile_delete: `${API_URL}v1/iam/profile-delete`,
	profile_detail: `${API_URL}v1/iam/profile-details`,
	save_profile: `${API_URL}v1/iam/profile`,
	get_role_detail: `${API_URL}v1/iam/role-details`,
	save_communicate_api: `${API_URL}v1/survey/communicate`,
	get_whatsapp_template: `${API_URL}v1/survey/get-whatsapp-template`,
	save_sample_api: `${API_URL}v1/survey/save-sampling`,
	dynamic_participant_json: `${API_URL}v1/survey/create-api-json`,
	dynamicParticipantIdentifier: `${API_URL}v1/survey/get-dynamic-participant-data`,
	search_api_participant: `${API_URL}v1/survey/search-api-participant`,
	savedynamicParticipant: `${API_URL}v1/survey/save-dynamic-participant`,
	communication_history: `${API_URL}v1/survey/communication-history`,
	create_survey_cat_by_template: `${API_URL}v1/survey-template/create-survey-category`,
	update_survey_date: `${API_URL}v1/survey/change-duration`,
	copy_survey: `${API_URL}v1/survey/copy-survey`,
	get_survey_detail: `${API_URL}v1/survey/survey-details`,
	survey_filters: `${API_URL}v1/survey/get-survey-filter-listing`,
	surveyCheckList: `${API_URL}v1/survey/overall-survey-data`,
	verification_user: `${API_URL}v1/captcha`,
	recent_activities: `${API_URL}v1/recent-activities`,
	clear_activities: `${API_URL}v1/clear-activities`,
	update_company_user: `${API_URL}v1/iam/update-user-profile`,
	getReportTypes: `${API_URL}v1/survey/get-report-types`,
	bookmark_template: `${API_URL}v2/survey/save-api-templates`,
	reply_survey_comment: `${API_URL}v1/survey/reply-survey-comment`,
	decode_preset_data: `${API_URL}v1/survey/get-decoded-survey-data`,
	communication_schedules: `${API_URL}v1/survey/schedule-communication-history`,
	survey_delete: `${API_URL}v1/survey/delete-survey`,
	get_survey_workflows: `${API_URL}v1/survey/get-survey-workflows`,
	view_workflow_detail: `${API_URL}v1/survey/view-workflow-details`,
	save_survey_workflow: `${API_URL}v1/survey/save-survey-workflow`,
	delete_survey_workflow: `${API_URL}v1/survey/delete-survey-workflow`,
	survey_workflow_stats: `${API_URL}v1/survey/get-workflow-counts`,
	get_survey_identifiers: `${API_URL}v1/survey/get-survey-identifiers`,
	get_active_surveys: `${API_URL}v1/survey/get-surveys`,
	verify_survey_member: `${API_URL}v1/survey/verify-survey-member`,
	generate_coupon: `${EZI_COUPON}promo_coupon/post_create`,
	get_notifications: `${API_URL}v1/notification/notification`,
	save_kpi: `${API_URL}v1/kpi/save`,
	get_kpi_surveys: `${API_URL}v1/kpi/get-surveys`,
	get_kpi_listing: `${API_URL}v1/kpi/listing`,
	get_kpi_counts: `${API_URL}v1/kpi/counts`,
	delete_kpi: `${API_URL}v1/kpi/delete`,
	get_kpi_detail: `${API_URL}v1/kpi/view`,
	get_recurring_details: `${API_URL}v1/recurring/get-details`,
	get_recurrings: `${API_URL}v1/recurring/get-recurrings`,
	recurring_dash_counts: `${API_URL}v1/recurring/dashboard-counts`,
	save_recurring_details: `${API_URL}v1/recurring/save-details`,
	delete_recurring: `${API_URL}v1/recurring/delete`,
	get_recurring_surveys: `${API_URL}v1/recurring/get-surveys`,
	recurring_survey_questions: `${API_URL}v1/recurring/get-questions`,
	survey_people_count: `${API_URL}v1/recurring/get-participant-count`,
	get_recurring_communication_templates: `${API_URL}v1/recurring/communication-templates`,
	update_survey_participants: `${API_URL}v1/survey/update-selected-participants`,
	create_action_task: `${API_URL}v1/actionplan/task/save`,
	task_master_list: `${API_URL}v1/actionplan/task/post-task-masters`,
	task_status: `${API_URL}v1/actionplan/task/check-action-plan-already-created/`,
	action_plan_task_list: `${API_URL}v1/actionplan/task/listing`,
	action_plan_task_details: `${API_URL}v1/actionplan/task/details/`,
	action_task_comment_create: `${API_URL}v1/actionplan/task/task-comment-save`,
	action_task_comment_delete: `${API_URL}v1/actionplan/task/task-comment-destroy/`,
	action_plan_survey_list: `${API_URL}v1/actionplan/task/survey-list`,
	ReportBenchmarkSuggestions: `${RPT_URL}theme-analysis/post-benchmark-suggestions`,
	set_communication_thank_email: `${API_URL}v1/survey/set-thank-templates`,
	post_communication_log_histories: `${API_URL}v1/survey/post-se-communication-direct-log-histories`,
	post_demographic_response_excel_download: `${RPT_URL}post-demographic-response-v3`,
	bookmark_template_active: `${API_URL}v2/survey/save-api-templates-active`,
	get_user_list: `${API_URL}v1/iam/export-user`,
	test_mail_credential: `${API_URL}v1/partner-template/test-mail`,
	survey_participant: `${API_URL}v1/survey/survey-participant`,
	survey_participant_bulk_update: `${API_URL}v1/survey/survey-participant-update`,
	google_review_list: `${API_URL}v1/company/google-review-list`,
	google_review_sentiment: `${API_URL}v1/company/google-review-sentiment`,
	google_review_count: `${API_URL}v1/company/google-review-count`,
	google_review_setting_list: `${API_URL}v1/company/google-review-setting-list `,
	google_review_setting: `${API_URL}v1/company/google-review-setting `,
	check_emailId: `${IAM_URL}check-emailId`,
	industry_type: `${API_URL}v1/survey/industry-type`,
	question_bank_list: `${API_URL}v1/partner-template/question-bank-list-company`,
	read_notification: `${API_URL}v1/notification/read-notification`,
	notifications_listing: `${API_URL}v1/notification/listing`,
	get_specific_response: `${API_URL}v2/survey/get-specific-response`,
	communication_job_details: `${API_URL}v1/company/post-communication-job-details`,
	getCountryCode: `${API_URL}v1/survey/get-country-code`,
	surveythemecloud: `${API_URL}v1/survey/surveytheme`,
	get_Sms_template: `${API_URL}v1/survey/get-sms-template`,
	Sms_setting: `${API_URL}v1/partner-template/company-create-update`,
	saveSmsTemplate: `${API_URL}v1/survey/save-sms-templates`,
	updateSmsTemplate: `${API_URL}v1/survey/update-sms-template`,
	identifierPosition: `${API_URL}v1/survey/participant-json`,
	stop_word_sentiment: `${API_URL}v1/company/stop-keyword`,
	google_review_token: `${API_URL}v1/company/google-review-token`,
	google_review_reply: `${API_URL}v1/company/google-review-reply`,
	bulkActionCreate: `${API_URL}v1/actionplan/task/create-multiple-tasks-with-parent-task`,
	save_respondent_filter: `${API_URL}v1/survey/save-access-filter`,
	get_respondent_filter: `${API_URL}v1/survey/get-access-filter`,
	Whatsapp_setting: `${API_URL}v1/partner-template/company-create-update`,
	count_survey_response: `${API_URL}v1/survey/count-survey-response`,
	getPartnerLogo: `${IAM_URL}accounts/postAccountByWebAddress`,
	task_error: `${API_URL}v1/survey/task_error`,

	// Report Side API
	ReportBenchmarkScore: `${RPT_URL}benchmark/post-benchmark-themes-score`,
	ReportBenchmarkList: `${RPT_URL}benchmark/post-benchmark-list`,

	ReportCommentsSentiment: `${RPT_URL}comments/post-comments-sentiment`,
	ReportCommentsCount: `${RPT_URL}comments/post-comments-counts`,
	ReportPostComments: `${RPT_URL}comments/post-comments-list`,
	ReportNegativeComments: `${RPT_URL}comments/post-comments-list`,

	get_kpi_widgets: `${RPT_URL}common/user-dashboard-kpis`,
	ReportIndividualResponse: `${RPT_URL}common/post-individual-response`,
	ReportInsightGaugeScores: `${RPT_URL}common/post-survey-scores`,
	ReportInsightBreakup: `${RPT_URL}common/post-score-breakup`,
	ReportInsightTrend: `${RPT_URL}common/post-trend`,
	ReportInsightReasons: `${RPT_URL}common/post-reasons`,
	ReportHistoryDetails: `${RPT_URL}common/post-survey-history-details`,
	ReportHeaderCardCount: `${RPT_URL}common/post-survey-details`,
	CheckSurveyData: `${RPT_URL}common/post-check-survey-data`,
	postAllQuestions: `${RPT_URL}common/post-survey-questions`,
	ReportGetTotalResponse: `${RPT_URL}common/post-response-count`,
	ReportIndividualResponseChecklist: `${RPT_URL}common/post-object-individual-response`,

	quantitative_apptitude_list: `${RPT_URL}customized/post-quantitative-apptitude-list`,

	ReportPostDemographics: `${RPT_URL}demographics/post-demographics`,
	post_demographic_response_v2: `${RPT_URL}demographics/post-demographic-response-rate`,

	ReportSurveyQuestion: `${RPT_URL}question-analysis/post-survey-question`,
	ReportAnalysisNewBarChart: `${RPT_URL}question-analysis/post-question-analysis`,
	ReportAnalysisDistribution: `${RPT_URL}question-analysis/post-question-analysis-trend`,

	ReportRespondentsList: `${RPT_URL}respondent/post-respondents-list`,
	ReportRespondentsListChecklist: `${RPT_URL}respondent/post-respondents-list-checklist`,
	ReportNonRespondentsList: `${RPT_URL}respondent/post-non-respondents-list`,
	ReportNonRespondentsReinvite: `${RPT_URL}respondent/post-non-respondents-reinvite`,
	PostObjectNonConformanceList: `${RPT_URL}respondent/post-object-non-conformance-list`,

	ReportStatisticsCOC: `${RPT_URL}stat-analysis/post-coc`,
	ReportDominanceList: `${RPT_URL}stat-analysis/post-dominance`,

	ReportHistoryOptionScore: `${RPT_URL}theme-analysis/post-history-theme-demographic-options-scores`,
	ReportReasonThemes: `${RPT_URL}theme-analysis/post-themes-score`,
	ReportHistoryThemeScore: `${RPT_URL}theme-analysis/post-history-themes-score`,
	ReportThemeTrend: `${RPT_URL}theme-analysis/post-theme-trend`,
	ReportQuestionTrend: `${RPT_URL}theme-analysis/post-question-trend`,
	ReportQuestionSuggestions: `${RPT_URL}theme-analysis/post-question-pracitice-suggestions`,

	raw_report_lists: `${RPT_URL}report-download/get-reports-listing`,
	create_raw_report: `${RPT_URL}report-download/create-raw-report`,
	download_raw_report: `${RPT_URL}report-download/download-raw-report`,


	BeyoundRespondentsList: `${RPT_URL}customized/reports/post-respondents-list-beyond`,
	email_report_beyond: `${RPT_URL}customized/reports/email_report_beyond`,
	post_question_all_demographic_option: `${RPT_URL}theme-analysis/post-question-all-demographic-options-scores`,

	// ReportCommentFilter
	ReportInsightFilter: `${RPT_URL}get-comment-filter`,

	// CrossTab
	ReportPostCrosstabReport: `${RPT_URL}crosstab/post-report`,
	ReportMostImpacted: `${RPT_URL}crosstab/post-most-imapcted`,

	// commonanalysis
	ReportThemeDropdwonListing: `${RPT_URL}theme-analysis/post-themes`,
	ReportInsightReason: `${RPT_URL}get-reasons`,
	ReportDemographicOptionsScores: `${RPT_URL}analysis/post-demographic-options-scores`,
	ReportDemographicOptionsPerformances: `${RPT_URL}post-demographic-options-performance`,
	ReportPostSliderColor: `${RPT_URL}analysis/post-slider-color`,
	compare_survey_list: `${API_URL}v1/survey/compare-survey-lists`,
	benchmark_survey_lists: `${API_URL}v1/survey/benchmark-survey-lists`,
	ReportOptionScore: `${RPT_URL}theme-analysis/post-theme-demographic-options-scores`,
	twitter_tweet_list: `${API_URL}v1/company/twitter-tweet-list`,
	twitter_tweet_setting_list: `${API_URL}v1/company/twitter-tweet-setting-list`,
	twitter_tweet_setting: `${API_URL}v1/company/twitter-tweet-setting`,
	twitter_tweet_token: `${API_URL}v1/company/twitter-tweet-token`,
	twitter_tweet_reply: `${API_URL}v1/company/twitter-tweet-reply`,
	twitter_tweet_count: `${API_URL}v1/company/twitter-tweet-count`,
	getStyleList: `${API_URL}v1/partner-admin/style-list`,
	survey_listing_name_update: `${API_URL}v1/survey/main-name-update`,
	ProcessParticipants: `${API_URL}v1/survey/processParticipants`,
	ParticipantTaskStatus: `${API_URL}v1/survey/task_status`,
	upload_users_excel: `${IAM_URL}iam/upload-users-excel`,
	upload_users_excel_logs: `${IAM_URL}iam/get-users-excel-logs-listing`,
	upload_users_excel_logs_download: `${IAM_URL}iam/get-users-excel-listing-data`,
	download_user_template: `${IAM_URL}iam/download-user-template`,
	DownloadParticipant: `${API_URL}v1/survey/downloadpeopleparticipants`,
	DownloadThemeTranslation: `${API_URL}v1/theme/translationdownload`,
	UploadThemeTranslation: `${API_URL}v1/theme/uploadThemeTranslations`,
	download_attachment: `${API_URL}v1/actionplan/task/download-attachment`,
	survey_feedback_uploaddata: `${API_URL}v1/survey/surveyfeedbackuploaddata`,
	getKioskSurveySetting: `${API_URL}v1/survey/get-kiosk-survey-setting`,
	getBase64toS3URL: `${API_URL}v1/survey/base64ToFileUpload`,
	respondentHeatmap: `${RPT_URL}respondent/post-respondents-heatmap`,
	statementWiseSocre: `${RPT_URL}common/post-statementwisesocre`,
	communicationMediumsVendors: `${API_URL}v1/communication_mediums_vendors`,
	updateCommunicationSettings: `${API_URL}v1/update_communication_settings`,
	upload_users_process: `${IAM_URL}iam/upload-users-process`,
	bulk_upload_task_status: `${IAM_URL}iam/task_status`,
	bulk_upload_task_error: `${IAM_URL}iam/task_error`,
	bulkemail_send: `${IAM_URL}bulkmail/generate`,
	bulk_email_process: `${IAM_URL}bulkmail/process`,
	bulk_email_status: `${IAM_URL}bulkmail/get-status`,
	get_users_filter: `${IAM_URL}iam/get_users_filter`,
	bulk_update_report_access: `${API_URL}v2/survey/bulk-update-report-access`,
	bulk_update_report_error: `${API_URL}v2/survey/bulk-update-report-error`,
	setSurveyBenchmarkMapping: `${API_URL}v1/survey/save-survey-benchmark-mapping`,
	getSurveyBenchmarkMapping: `${API_URL}v1/survey/get-survey-benchmark-mapping`,
	genrate_ti_company_login: `${IAM_URL}generate-ti-company-token`,
	genrate_advance_report_login: `${ADVANCE_REPORT_LOGIN}login.html`,
	company_token_third_party_login: `${IAM_URL}generate-company-token-third-party-login`,
	verif_token_tpi: `${API_URL}verifyTokenTpi`,
	people_bulk_upload_taskStatus: `${API_URL}addParticipantController/taskStatus`,
	people_bulk_upload_taskError: `${API_URL}addParticipantController/taskError`,
	people_bulk_upload_insertParticipants: `${API_URL}addParticipantController/insertParticipants`,
	people_bulk_uploadDeletePeopleExcel: `${API_URL}deleteParticipantPeople/uploadDeletePeopleExcel`,
	survey_response_id: `${API_URL}v1/survey/survey-response-id`,
	people_bulk_updateParticipants: `${API_URL}updateParticipantPeople/updateParticipants`,
	people_bulk_deleteParticipants: `${API_URL}deleteParticipantPeople/deleteParticipants`,
	life_cycle_surveys: `${API_URL}v1/survey/life-cycle-surveys`,
	life_cycle_save: `${API_URL}v1/survey/life-cycle-save`,
	life_cycle_delete: `${API_URL}v1/survey/life-cycle-delete`,
	life_cycle_listing: `${API_URL}v1/survey/life-cycle-listing`,
	life_cycle_view: `${RPT_URL}common/life-cycle-scores`,
	get_update_kpi: `${RPT_URL}common/update-user-dashboard-kpi`,
	life_cycle_dashboard: `${RPT_URL}common/life-cycle-dashboard`,
	user_dashboard_kpi_trend: `${RPT_URL}common/user-dashboard-kpi-trend`,
	get_emotion_today: `${API_URL}v1/survey/get-emotion-today`,
	get_emotion_all_scatter: `${API_URL}v1/survey/get-emotion-all-scatter`,
	get_emotion_all_heatmap: `${API_URL}v1/survey/get-emotion-all-heatmap`,
	get_emotion_all: `${API_URL}v1/survey/get-emotion-all`,
	selected_participants_delete: `${API_URL}v1/survey/selected-participants`,
	survey_participant_update_delete: `${API_URL}v1/survey/survey-participant-update-delete`,
	processParticipantsUpdateDelete: `${API_URL}v1/survey/processParticipantsUpdateDelete`,
	postGainsDips: `${RPT_URL}common/post-gains-dips`,
	postKeyTrends: `${RPT_URL}common/post-key-trends`,
	postHighestLowestStatement: `${RPT_URL}common/post-highest-lowest-statements`,
	postDemographicOptionScore: `${RPT_URL}common/post-demographic-option-score`,
	postDemographicComparators: `${RPT_URL}common/post-demographic-comparators`,
	postStatementwisesocretrend: `${RPT_URL}common/post-statementwisesocretrend`,
	postMoodOMeterUrl: `${RPT_URL}common/post-mood-response`,
	get_center_name: `${RPT_URL}common/get-respondent-response`,
	benchmark_statements: `${API_URL}v1/benchmark/statements`,
	get_coc: `${RPT_URL}stat-analysis/get-coc`,
	post_reasons_main_questions: `${RPT_URL}common/post-reasons-main-questions`,
	post_reasons_question_divison: `${RPT_URL}common/post-reasons-question-divison`,
	post_reasons_demographic_divison: `${RPT_URL}common/post-reasons-demographic-divison`,
	play_board: `${RPT_URL}demographics/play-board`,
	bulk_raw_data_upload: `${API_URL}v1/survey/raw-data-upload-v2`,
	add_people_responses: `${API_URL}v1/survey/add-responses`,
	process_response:`${API_URL}v1/survey/process-responses`,
	ReportStatisticsPareto: `${RPT_URL}stat-analysis/post-pareto-analysis`,
	bu_response: `${API_URL}v2/survey/get-business-units-access`,
	delete_report_access: `${API_URL}v2/survey/delete-report-access`,
	process_participants_rollup: `${API_URL}v1/survey/process-participants-rollup`,
	s3_to_base64: `${API_URL}s3-link-to-base-64`,
	// Feedback Login
	feedbackLogin: `${API_URL}v1/feedbackservice/login`,
	feedbackChangePassword: `${API_URL}v1/feedbackservice/change-password`,
	feedbackLogout: `${API_URL}v1/feedbackservice/logout`,
	feedbackSurvey: `${API_URL}v1/feedbackservice/surveys`,
	feedbackPageWiseResponse: `${API_URL}v1/feedbackservice/page-wise-response`,
	feedbackCheckIdentifier: `${API_URL}v1/feedbackservice/check-identifier`,
	feedbackSurveyResponse: `${API_URL}v1/feedbackservice/survey-response`,
	get_ai_comments: `${API_URL}v1/survey/get-comment-summary`,
	feedbackResetPassword: `${API_URL}v1/feedbackservice/forget-password`,
	feedbackSendFeedbackAuthCredentials: `${API_URL}v1/feedbackservice/send-feedback-auth-credentials`,
	genrate_data_People:`${API_URL}process-account-rollup`,
	multiple_benchmark_template_download: `${API_URL}v1/benchmark-mapping/download-excel`,
	multiple_benchmark_upload: `${API_URL}v1/benchmark-mapping/upload-excel`,
	process_participants_rollup: `${API_URL}v1/survey/process-participants-rollup`,
	postThemeCount:`${RPT_URL}theme-analysis/post-themes-count`,

	// -- START CHAT API --
	chat_get: `${API_URL}v1/survey/get-chats`,
	get_message: `${API_URL}v1/survey/get-messages`,
	send_message: `${API_URL}v1/survey/send-message`,
	update_chat_status: `${API_URL}v1/survey/update-chat-status`,
	get_user_by_company: `${API_URL}v1/iam/get-user-by-company`,
	send_pusher_data: `https://pusher.teamqaizenx.com/api/send-pusher-data`,
	pusher_auth_end_point: `https://pusher.teamqaizenx.com/api/auth-pusher`,
	chat_initiate: `${API_URL}v1/survey/chat-initiate`,
	// -- END CHAT API --
	aiChatSave : `${API_URL}v1/survey/aichat-save`,
}
export default configURL;