
import showdown from "showdown"
import { PROGRESSBAR_COLOR } from "../constants/constants"
import { SHOWOVERRIDINGCONDITION, TENSCALEQUESTIONALLOWED, SECRETPASS, ISOOJECTCONDITION, SHOWISOOJECTCONDITION } from "../constants/SurveyConstants"
import CryptoJS from "crypto-js";
// import { stripHtml } from "string-strip-html";

/**
 * Converts a given date to a string format.
 *
 * @param {Date} date - The date to be converted. If not provided, the current date will be used.
 * @return {string} The formatted date string in the format "YYYY-MM-DD HH:MM:SS".
 */
export const dateConvert = (date = null) => {
    let currentdate = date || new Date()
    return currentdate.getFullYear() + "-"
        + (currentdate.getMonth() + 1) + "-"
        + currentdate.getDate() + " "
        + currentdate.getHours() + ":"
        + currentdate.getMinutes() + ":"
        + currentdate.getSeconds();
}

export const dateConvertWithOutTime = (date = null) => {
    let currentdate = date || new Date()
    return currentdate.getFullYear() + "-"
        + (currentdate.getMonth() + 1) + "-"
        + currentdate.getDate();
}

/**
 * Returns the date in IST (Indian Standard Time) format.
 *
 * @param {Date|null} date - The date to convert to IST format. If not provided, the current date and time will be used.
 * @return {string} The date in the format "DD/MM/YYYY".
 */
export const getDateIST = (date = null) => {
    let currentdate = date || new Date()
    return currentdate.getDate() + "/"
        + (currentdate.getMonth() + 1) + "/"
        + currentdate.getFullYear();
}

/**
 * Returns the first word of a given string.
 *
 * @param {string} str - The input string.
 * @return {string} - The first word of the input string.
 */
export const getFirstWord = (str) => {
    if (str && str.length !== null) {
        let spaceIndex = str.replace(/ .*/, '');
        return spaceIndex;
    }
};

/**
 * Converts a string to title case.
 *
 * @param {string} str - The string to be converted.
 * @return {string} The string in title case.
 */
export const toTitleCase = (str) => {
    if (str && str.length !== null) {
        return str.replace(/\w\S*/g, (txt) => {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }
}

export const outlineRemove = {
    control: base => ({
        ...base,
        border: 0,
        boxShadow: "none"
    })
};

export const getNameInitials = string => {
    const names = string.split(' ');
    const initials = names.map(name => name.charAt(0).toUpperCase())
    if (initials.length > 1) {
        return `${initials[0]}${initials[initials.length - 1]}`;
    } else {
        return initials[0];
    }
}

/**
 * Compares two objects recursively and returns true if they are equal.
 *
 * @param {Object} obj1 - The first object to compare.
 * @param {Object} obj2 - The second object to compare.
 * @return {boolean} Returns true if the objects are equal, false otherwise.
 */
export const compareObject = (obj1, obj2) => {
    for (let p in obj1) {
        if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) return false;

        switch (typeof (obj1[p])) {
            case 'object':
                if (!compareObject(obj1[p], obj2[p])) return false;
                break;
            case 'function':
                if (typeof (obj2[p]) === 'undefined' || (p !== 'compareObject' && obj1[p].toString() !== obj2[p].toString())) return false;
                break;
            default:
                if (obj1[p] !== obj2[p]) return false;
        }
    }
    for (let p in obj2) {
        if (typeof (obj1[p]) === 'undefined') return false;
    }
    return true;
};

/**
 * Generates a unique identifier string.
 *
 * @return {string} The unique identifier string.
 */
export const uniqueGenerator = () => {
    let date = Date.now();
    if (date <= uniqueGenerator.previous) {
        date = ++uniqueGenerator.previous;
    } else {
        uniqueGenerator.previous = date;
    }

    return 'unq' + date.toString(20) + Math
        .floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
}

/**
 * Modifies the survey questions data.
 *
 * @param {Object} questions - The survey questions object.
 * @return {Object} The modified survey questions object.
 */
export const surveyQuestionModifier = (questions = {}) => {
    let questionsData = questions.pages || [];
    questionsData.forEach((page, index) => {
        if (questionsData[index] && questionsData[index].hasOwnProperty("elements") && questionsData[index]["elements"].length > 0) {
            questionsData[index]["elements"].forEach((item, i) => {
                /** Add Question title if not present */
                if (!questionsData[index]["elements"][i].hasOwnProperty('title')) {
                    questionsData[index]["elements"][i]['title'] = questionsData[index]["elements"][i]['name'] || null
                }
                /** Add Question id in matrix rows if not present */
                if (questionsData[index]["elements"][i]["type"] === 'matrix') {
                    if (questionsData[index]["elements"][i].hasOwnProperty("rows")) {
                        questionsData[index]["elements"][i]["rows"].forEach((mtItem, inum) => {
                            let mtOptions = {}
                            if (typeof mtItem === 'string') {
                                mtOptions['text'] = mtItem
                                mtOptions['value'] = uniqueGenerator()
                            }
                            if (typeof mtItem === 'object') {
                                let rowVal = mtItem['value']
                                mtOptions['text'] = mtItem['text']
                                if (rowVal && !rowVal.startsWith('unq')) {
                                    rowVal = uniqueGenerator()
                                }
                                if (rowVal.length <= 3 || !rowVal) {
                                    rowVal = uniqueGenerator()
                                }
                                mtOptions['value'] = rowVal
                            }
                            questionsData[index]["elements"][i]["rows"][inum] = mtOptions
                        })
                    }
                    if (questionsData[index]["elements"][i].hasOwnProperty("columns")) {
                        questionsData[index]["elements"][i]["columns"].forEach((mtItem, inum) => {
                            let mtOptions = {}
                            if (typeof mtItem === 'string') {
                                mtOptions['text'] = mtItem
                                mtOptions['value'] = uniqueGenerator()
                                questionsData[index]["elements"][i]["columns"][inum] = mtOptions;
                            }
                            if (typeof mtItem === 'object') {
                                if (mtItem['value'] === mtItem['text'] || (mtItem['value'] === "" || mtItem['text'] === "")) {
                                    let rowVal = mtItem['value']
                                    mtOptions['text'] = mtItem['text']
                                    if (rowVal && !rowVal.startsWith('unq')) {
                                        rowVal = uniqueGenerator()
                                    }
                                    if (rowVal.length <= 3 || !rowVal) {
                                        rowVal = uniqueGenerator()
                                    }
                                    mtOptions['value'] = rowVal;
                                    questionsData[index]["elements"][i]["columns"][inum] = mtOptions
                                }
                            }

                        })
                    }
                }

                /**
                 * Added text value for choices question if object not present
                 */
                let choiceTypes = ['radiogroup', 'dropdown', 'tagbox', 'checkbox', 'sortablelist', 'rating_csat', 'rating', 'boolean'];
                if (choiceTypes.includes(questionsData[index]["elements"][i]["type"])) {
                    if (questionsData[index]["elements"][i].hasOwnProperty("choices")) {
                        questionsData[index]["elements"][i]["choices"].forEach((choices, chindex) => {
                            let mtOptions = {}
                            if (typeof choices === 'string') {
                                mtOptions['text'] = choices
                                mtOptions['value'] = choices
                                questionsData[index]["elements"][i]["choices"][chindex] = mtOptions
                            }

                        })
                    }
                    if (questionsData[index]["elements"][i].hasOwnProperty("csatScores")) {
                        questionsData[index]["elements"][i]["csatScores"].forEach((csat_scores, csatindex) => {
                            let mtOptions = {}
                            if (typeof csat_scores === 'string') {
                                mtOptions['text'] = csat_scores
                                mtOptions['value'] = csat_scores
                                questionsData[index]["elements"][i]["csatScores"][csatindex] = mtOptions
                            }

                        })
                    }
                    if (questionsData[index]["elements"][i].hasOwnProperty("rateValues")) {
                        questionsData[index]["elements"][i]["rateValues"].forEach((rate_values, rateindex) => {
                            let mtOptions = {}
                            if (typeof rate_values === 'string') {
                                mtOptions['text'] = rate_values
                                mtOptions['value'] = rate_values
                                questionsData[index]["elements"][i]["rateValues"][rateindex] = mtOptions
                            }

                        })
                    }
                    if (questionsData[index]["elements"][i].hasOwnProperty("showCommentArea")) {
                        questionsData[index]["elements"][i]["hasComment"] = true;
                    }
                    if (questionsData[index]["elements"][i].hasOwnProperty("showOtherItem")) {

                        questionsData[index]["elements"][i]["hasOther"] = true;

                    }
                    if (!questionsData[index]["elements"][i].hasOwnProperty("labelTrue") || !questionsData[index]["elements"][i].hasOwnProperty("labelFalse")) {
                        if (questionsData[index]["elements"][i]["labelTrue"] === undefined || questionsData[index]["elements"][i]["labelTrue"] === "") {
                            questionsData[index]["elements"][i]["labelTrue"] = "Yes";
                        }
                        if (questionsData[index]["elements"][i]["labelFalse"] === undefined || questionsData[index]["elements"][i]["labelFalse"] === "") {
                            questionsData[index]["elements"][i]["labelFalse"] = "No";
                        }

                    }




                }
                if (questionsData[index]["elements"][i]["type"] === "datepicker") {
                    let item = [];
                    if (questionsData[index]["elements"][i].hasOwnProperty("changeMonthAndYear")) {

                        item = {
                            changeMonth: true,
                            changeYear: true,
                            yearRange: '1950:2050'
                        }
                        questionsData[index]["elements"][i]['config'] = item;

                    }
                    else {
                        questionsData[index]["elements"][i]['config'] = item;
                    }
                }
                let questionId = ["unq355988809f840d", "unq355ca934f5b98b", "unq355ca92ie539ff", "unq355ca92ej94484", "unq355cb0i533bd4e"]
                if (questionsData[index]["elements"][i]["type"] === "dropdown" && questionId.includes(questionsData[index]["elements"][i]["name"])) {
                    questionsData[index]["elements"][i]["choicesLazyLoadEnabled"] = true;
                }
                if (questionsData[index]["elements"][i]["type"] === "dropdown" && questionsData[index]["elements"][i].hasOwnProperty("lazyLoadDropdown")) {
                    questionsData[index]["elements"][i]["choicesLazyLoadEnabled"] = true;
                }
            })
        }
    })
    questions.pages = questionsData
    return questions
}

export const mapParentChild = (questions = {}, mappings = {}) => {
    if (!questions.pages || questions.pages.length === 0) {
        throw new Error('Questions Data is missing.')
    }
    let clonedQuestions = JSON.parse(JSON.stringify(questions))
    let questionsData = clonedQuestions.pages || [];
    questionsData.forEach((page, index) => {
        if (questionsData[index] && questionsData[index].hasOwnProperty("elements") && questionsData[index]["elements"].length > 0) {
            questionsData[index]["elements"].forEach((item, i) => {
                if (questionsData[index]["elements"][i]["hasParentQuestion"]) {
                    questionsData[index]["elements"][i]["parent_question_id"] = mappings[questionsData[index]["elements"][i]["id"]] || null
                }
            })
        }
    })
    clonedQuestions.pages = questionsData
    return clonedQuestions
}

export const canSaveBuilderQuestions = (questionsData = null) => {
    if (questionsData && questionsData.hasOwnProperty('pages') && questionsData.pages.length > 0) {
        const Questions = questionsData.pages.reduce((arr, el) => {
            let newEl = (el.hasOwnProperty('elements') && el['elements'].length > 0) ? el['elements'] : [];
            return arr.concat(newEl);
        }, []);

        return Questions.length > 0
    } else {
        return false
    }
}

/**
 * Retrieves identifier questions from the given questions object.
 *
 * @param {object} questions - An object containing questions data.
 * @return {array} An array of identifier questions.
 */
export const getIdentifierQuestion = (questions = {}) => {
    let questionsData = questions.pages || [];
    let identifierQuestions = []
    questionsData.forEach((page, index) => {
        if (questionsData[index] && questionsData[index].hasOwnProperty("elements") && questionsData[index]["elements"].length > 0) {
            questionsData[index]["elements"].forEach((item, i) => {
                if (item.hasOwnProperty('isIdentifier') && item['isIdentifier']) {
                    identifierQuestions.push(item)
                }
            })
        }
    })
    return identifierQuestions
}

/**
 * Retrieves personal questions from a given set of questions.
 *
 * @param {object} questions - An object containing the set of questions.
 * @return {array} - An array of personal questions.
 */
export const getPersonalQuestion = (questions = {}) => {
    let questionsData = questions.pages || [];
    let personalQuestion = []
    questionsData.forEach((page, index) => {
        if (questionsData[index] && questionsData[index].hasOwnProperty("elements") && questionsData[index]["elements"].length > 0) {
            questionsData[index]["elements"].forEach((item, i) => {
                if (item.hasOwnProperty('question_type') && item['question_type'] === 'PERSONALINFO') {
                    personalQuestion.push(item)
                }
            })
        }
    })
    return personalQuestion
}

/**
 * Generates an array of table page sizes based on the total number of items.
 *
 * @param {number} total - The total number of items.
 * @return {Array} An array of objects representing the page sizes.
 */
export const getTablePagesSizes = (total) => {
    return [
        {
            text: "25",
            value: 25
        },
        {
            text: "50",
            value: 50
        },
        {
            text: "100",
            value: 100
        },
        {
            text: "200",
            value: 200
        },
        {
            text: "500",
            value: 500
        }
    ]
}

/**
 * Converts a given Markdown text to HTML using the `showdown` library.
 *
 * @param {any} _ - an unused parameter
 * @param {Object} options - an object containing the Markdown text to be converted
 * @param {string} options.text - the Markdown text to be converted
 * @return {void}
 */
export const doMarkdown = (_, options) => {
    let converter = new showdown.Converter();
    let str = converter.makeHtml(options.text);
    if (str.indexOf("<p>") === 0) {
        str = str.substring(3);
        str = str.substring(0, str.length - 4);
    }
    options.html = str;
}

export const CkEditor_ModalEditor = {
    afterRender: function (modalEditor, htmlElement) {
        var editor = window.CKEDITOR.replace(htmlElement);
        var isUpdating = false;
        editor.on("change", function () {
            isUpdating = true;
            modalEditor.editingValue = editor.getData();
            isUpdating = false;
        });
        editor.setData(modalEditor.editingValue);
        modalEditor.onValueUpdated = function (newValue) {
            if (!isUpdating) {
                editor.setData(newValue);
            }
        };
    },
    destroy: function (modalEditor, htmlElement) {
        var instance = window.CKEDITOR.instances[htmlElement.id];
        if (instance) {
            instance.removeAllListeners();
            instance.destroy(true);
            window.CKEDITOR.remove(instance);
        }
    }
};

/**
 * Generates the progress bar color based on the response and report type.
 *
 * @param {Array} response - The response data.
 * @param {string} reportType - The type of report.
 * @return {Array} The response data with color added.
 */
export const PregressbarColor = (response, reportType) => {
    const index = response.findIndex(object => {
        return object.id === "OVERALL";
    });
    let reportTypeConst = reportType === "NPS-V3" ? 'NPS' : 'other';
    // eslint-disable-next-line array-callback-return
    response.map((item, id) => {
        if (id > index) {
            // eslint-disable-next-line array-callback-return
            PROGRESSBAR_COLOR.filter((data, index) => {
                if ((data.min_score <= item.score[1] && data.max_score >= item.score[1]) && reportTypeConst === data.report_type) {
                    response[id]["color"] = data.color;
                }
            })
        }
        else if (id === index) {
            response[id]["color"] = "Pink";
        }
        else {
            response[id]["color"] = "Green";
        }

    })
    return response;
}

/**
 * Returns the object status of a survey question.
 *
 * @param {Object} questions - an object containing survey questions
 * @param {string} reportType - the type of report
 * @return {Object} result - an object containing the success status and message
 */
export const surveyQuestionObjectStatus = (questions = {}, reportType) =>{
    let questionsData = questions.pages || [];
    let totalCount = {
        IsObjectCount: 0,
        IsIdentifier: 0,
    }
    let result = {
        success: true,
        msg: "",
    }
    if (SHOWISOOJECTCONDITION.includes(reportType)) {
        questionsData.forEach((page, index) => {
            questionsData[index]["elements"].forEach((item, i) => {
                if (questionsData[index]["elements"][i].hasOwnProperty("isObject") && questionsData[index]["elements"][i].hasOwnProperty("isIdentifier")) {
                    totalCount.IsObjectCount++;
                } else if (questionsData[index]["elements"][i].hasOwnProperty("isObject")) {
                    totalCount.IsObjectCount++;
                    totalCount.IsIdentifier++;
                }
            })
        })
        if (totalCount.IsObjectCount === 0) {
            result.success = false;
            result.msg = "Please add atleast one Object Question.";
        }
        else if (totalCount.IsObjectCount > 1) {
            result.success = false;
            result.msg = "Only one Object question enable is allowed.";
        } else if (totalCount.IsIdentifier === 1) {
            result.success = false;
            result.msg = "Please enable Is Identifier as Is Object enabled.";
        }
    }
    return result;
}

/**
 * Returns the status of the survey email questions.
 *
 * @param {object} questions - The object containing the survey questions.
 * @return {object} - The result object with success status and message.
 */
export const surveyEmailQuestionStatus = (questions = {}) =>{
    let questionsData = questions.pages || [];
    let totalCount = {
        isEmailQuestion: 0,
    }
    let result = {
        success: true,
        msg: "",
    }
    // if (SHOWISOOJECTCONDITION.includes(reportType)) {
        questionsData.forEach((page, index) => {
            questionsData[index]["elements"].forEach((item, i) => {
                if (questionsData[index]["elements"][i].hasOwnProperty("isEmailQuestion")) {
                    totalCount.isEmailQuestion++;
                }
            })
        })
        // if (totalCount.isEmailQuestion === 0) {
        //     result.success = false;
        //     result.msg = "Please add atleast one Object Question.";
        // }
        if (totalCount.isEmailQuestion > 1) {
            result.success = false;
            result.msg = 'Only one Email question enable is allowed.';
        }
    // }
    return result;
}

/**
 * Determines the overriding status of survey questions for a given report type.
 *
 * @param {Object} questions - an object containing survey questions
 * @param {string} reportType - the type of report
 * @return {Object} - an object containing the result of the function
 */
export const surveyQuestionOverridingStatus = (questions = {}, reportType) => {
    let questionsData = questions.pages || [];
    let totalCount = {
        overridingCount: 0,
        overridingMainQue: 0,
        hasCalculationError: 0,
        overridingQuestionError: 0,
        wrongQuestionError: 0
    }
    let result = {
        success: true,
        msg: "",
    }
    let reportScale = TENSCALEQUESTIONALLOWED.includes(reportType) ? 10 : 5;
    let rangeMin = TENSCALEQUESTIONALLOWED.includes(reportType) ? 0 : 1;
    if (SHOWOVERRIDINGCONDITION.includes(reportType)) {
        questionsData.forEach((page, index) => {
            if ((questionsData[index] && questionsData[index].hasOwnProperty("elements") && questionsData[index]["elements"].length > 0)) {
                questionsData[index]["elements"].forEach((item, i) => {

                    if (questionsData[index]["elements"][i]["type"] === "rating") {
                        if (questionsData[index]["elements"][i].hasOwnProperty("isOverriding")) {
                            totalCount.overridingCount++;
                            if (!TENSCALEQUESTIONALLOWED.includes(reportType)) {
                                if (questionsData[index]["elements"][i].hasOwnProperty("hasCalculation")) {
                                    if (((questionsData[index]["elements"][i].hasOwnProperty("rateMax") && questionsData[index]["elements"][i]["rateMax"] === reportScale) || (!questionsData[index]["elements"][i].hasOwnProperty("rateMax") && reportScale === 5)) && ((questionsData[index]["elements"][i].hasOwnProperty("rateMin") && questionsData[index]["elements"][i]["rateMin"] === 1) || !questionsData[index]["elements"][i].hasOwnProperty("rateMin")) && ((questionsData[index]["elements"][i].hasOwnProperty("rateStep") && questionsData[index]["elements"][i]["rateStep"] === 1) || !questionsData[index]["elements"][i].hasOwnProperty("rateStep"))) {
                                        totalCount.overridingMainQue++;
                                    }
                                    else {
                                        totalCount.overridingQuestionError++;
                                    }
                                }
                                else { totalCount.hasCalculationError++ }
                            }
                            else {
                                totalCount.wrongQuestionError++;
                            }

                        }

                    }
                    if (questionsData[index]["elements"][i]["type"] === "barrating" || questionsData[index]["elements"][i]["type"] === "radiogroup") {
                        if (questionsData[index]["elements"][i].hasOwnProperty("isOverriding")) {
                            totalCount.overridingCount++;
                            if (!TENSCALEQUESTIONALLOWED.includes(reportType)) {
                                if (questionsData[index]["elements"][i].hasOwnProperty("hasCalculation")) {
                                    if (questionsData[index]["elements"][i].hasOwnProperty("choices") && questionsData[index]["elements"][i]["choices"].length === 5) {
                                        totalCount.overridingMainQue++;
                                    }
                                    else {
                                        totalCount.overridingQuestionError++;
                                    }
                                }
                                else { totalCount.hasCalculationError++ }
                            }
                            else {
                                totalCount.wrongQuestionError++;
                            }
                        }
                    }
                    if (questionsData[index]["elements"][i]["type"] === "rating_csat") {
                        if (questionsData[index]["elements"][i].hasOwnProperty("isOverriding")) {
                            totalCount.overridingCount++;
                            if (!TENSCALEQUESTIONALLOWED.includes(reportType)) {
                                if (questionsData[index]["elements"][i].hasOwnProperty("hasCalculation")) {
                                    if (questionsData[index]["elements"][i].hasOwnProperty("csatScores") && questionsData[index]["elements"][i]["csatScores"].length !== 5) {
                                        totalCount.overridingQuestionError++;
                                    }
                                }
                                else { totalCount.hasCalculationError++ }
                            }
                            else {
                                totalCount.wrongQuestionError++;
                            }
                        }
                    }

                    if (questionsData[index]["elements"][i]["type"] === "bootstrapslider") {
                        if (questionsData[index]["elements"][i].hasOwnProperty("isOverriding")) {
                            totalCount.overridingCount++;
                            if (questionsData[index]["elements"][i].hasOwnProperty("hasCalculation")) {
                                if (((questionsData[index]["elements"][i].hasOwnProperty("rangeMax") && questionsData[index]["elements"][i]["rangeMax"] === reportScale)) && ((questionsData[index]["elements"][i].hasOwnProperty("rangeMin") && questionsData[index]["elements"][i]["rangeMin"] === rangeMin) || (!questionsData[index]["elements"][i].hasOwnProperty("rangeMin") && reportScale === 10)) && ((questionsData[index]["elements"][i].hasOwnProperty("step") && questionsData[index]["elements"][i]["step"] === 1) || !questionsData[index]["elements"][i].hasOwnProperty("step"))) {
                                    totalCount.overridingMainQue++;
                                }
                                else {
                                    totalCount.overridingQuestionError++;
                                }
                            }
                            else { totalCount.hasCalculationError++ }
                        }
                    }
                    if (TENSCALEQUESTIONALLOWED.includes(reportType)) {
                        if (questionsData[index]["elements"][i]["type"] === "smily" || questionsData[index]["elements"][i]["type"] === "emoticons" || questionsData[index]["elements"][i]["type"] === "hiv2" || questionsData[index]["elements"][i]["type"] === "camera") {
                            if (questionsData[index]["elements"][i].hasOwnProperty("isOverriding")) {
                                totalCount.overridingCount++;
                                totalCount.wrongQuestionError++;
                            }
                        }

                        if (questionsData[index]["elements"][i]["type"] === "nps_rating") {
                            if (questionsData[index]["elements"][i].hasOwnProperty("isOverriding")) {
                                totalCount.overridingCount++;
                                if (!questionsData[index]["elements"][i].hasOwnProperty("hasCalculation")) {
                                    totalCount.hasCalculationError++
                                }
                            }
                        }
                    }
                    if (!TENSCALEQUESTIONALLOWED.includes(reportType)) {
                        if (questionsData[index]["elements"][i]["type"] === "nps_rating") {
                            if (questionsData[index]["elements"][i].hasOwnProperty("isOverriding")) {
                                totalCount.overridingCount++;
                                totalCount.wrongQuestionError++;
                            }
                        }
                        if (questionsData[index]["elements"][i]["type"] === "smily" || questionsData[index]["elements"][i]["type"] === "emoticons" || questionsData[index]["elements"][i]["type"] === "hiv2" || questionsData[index]["elements"][i]["type"] === "camera") {
                            if (questionsData[index]["elements"][i].hasOwnProperty("isOverriding")) {
                                totalCount.overridingCount++;
                                if (!questionsData[index]["elements"][i].hasOwnProperty("hasCalculation")) {
                                    totalCount.hasCalculationError++
                                }
                            }
                        }
                    }
                })
            }
        })
        if (totalCount.overridingCount === 0) {
            result.success = false;
            result.msg = "Please add atleast one Overriding Question.";
        }
        else if (totalCount.overridingCount > 1) {
            result.success = false;
            result.msg = "Please add only one Overriding Question.";
        }
        else if (totalCount.wrongQuestionError > 0) {
            result.success = false;
            result.msg = "Selected overriding question type is not allowed in this report.";
        }
        else if (totalCount.hasCalculationError > 0) {
            result.success = false;
            result.msg = "Please enable 'Has calculation' property on overriding question.";
        }
        else if (totalCount.overridingQuestionError > 0) {
            result.success = false;
            result.msg = "Error in properties for overriding question.";
        }
    }
    return result;
}

/**
 * Capitalizes the first letter of each word in a given phrase.
 *
 * @param {string} phrase - The phrase to capitalize.
 * @return {string} The capitalized phrase.
 */
export const capitalizeFirstLetter = (phrase) => {
    if (phrase) {
        return phrase.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }
    else {
        return phrase;
    }

}

/**
 * Checks if the given string is encrypted using AES encryption.
 *
 * @param {string} string - The string to be checked.
 * @return {boolean} Returns true if the string is encrypted, false otherwise.
 */
export const checkEncryption = (string) => {
    const bytes = CryptoJS.AES.decrypt(string, SECRETPASS)
    try {
        const decrypted = bytes.toString(CryptoJS.enc.Utf8)
        return !!decrypted
    }
    catch (error) {
        return false
    }
}

/**
 * Decrypts the given data if it is encrypted using AES encryption.
 *
 * @param {Object} data - The data to be decrypted.
 * @return {Object} The decrypted data.
 */
export const decryptData = (data = {}) => {
    if (data !== null && checkEncryption(data)) {
        const bytes = CryptoJS.AES.decrypt(data, SECRETPASS);
        data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }
    return data;
}

/**
 * Encrypts the given data and stores it in the local storage.
 *
 * @param {any} data - The data to be encrypted.
 * @return {void} - This function does not return any value.
 */
export const encryptData = (data) => {
    let secretPass = "XkhZG4fW2t2W";
    const storeData = CryptoJS.AES.encrypt(
        JSON.stringify(data),
        secretPass
    ).toString();
    localStorage.setItem("appState", storeData)
};
export const surveyJSONModifier = (surveyJson = {}, surveyLocales) => {
    let questionsData = surveyJson.pages || [];
    questionsData.forEach((page, index) => {
        if (questionsData[index] && questionsData[index].hasOwnProperty("elements") && questionsData[index]["elements"].length > 0) {
            questionsData[index]["elements"].forEach((itemel, i) => {
                /** Add Question title if not present */
                if (questionsData[index]["elements"][i].hasOwnProperty('title')) {
                    if (typeof questionsData[index]["elements"][i]['title'] === 'object') {
                        surveyLocales.forEach((item) => {
                            if (questionsData[index]["elements"][i]['title'][item === "" ? "default" : item] !== undefined) {
                                // questionsData[index]["elements"][i]['title'][item === "" ? "default" : item] = stripHtml(questionsData[index]["elements"][i]['title'][item === "" ? "default" : item]).result || "";
                            }

                        })
                    }
                }
            })
        }
    })
    surveyJson.pages = questionsData
    return surveyJson
}

/**
 * Truncates a string if it is longer than the specified length.
 *
 * @param {string} str - The string to be truncated.
 * @param {number} num - The maximum length of the truncated string.
 * @return {string} The truncated string.
 */
export const truncateString = (str, num) => {
    if (str.length <= num) {
        return str;
    }
    return str.slice(0, num) + '...';
}

export const surveyDynamicChange = (questions = {}, identiFier = {}) => {
    // console.log(identiFier);
    let questionsData = questions.pages || [];
    questionsData.forEach((page, index) => {
        if (questionsData[index] && questionsData[index].hasOwnProperty("elements") && questionsData[index]["elements"].length > 0) {
            questionsData[index]["elements"].forEach((item, i) => {
                /** Add Question title if not present */
                if (questionsData[index]["elements"][i].hasOwnProperty('isdynamicNameChange')) {
                    if (identiFier[questionsData[index]["elements"][i]['name']]) {
                        questionsData[index]["elements"][i]['title'] = questionsData[index]["elements"][i]['title'].replace("{label}", identiFier[questionsData[index]["elements"][i]['name']]);
                    }
                }

            })
        }
    })
    questions.pages = questionsData
    return questions
}
