import sanitizeHtml from "sanitize-html";

class AppFormData extends FormData {

    /**
     * 
     * @param {String} field 
     * @param {Mixed} value 
     * @param {Object} options
     * 
     */
    append(field, value, options = {}) {
        //let safeValue = this.validateInput(value, options)
        super.append(field, value)
    }

    /**
     * Default Bypass Json and File Data. || Validate Json if Option is Set to validate
     * @param {Mixed} value 
     * @param {Object} options
     * @returns Mixed Purified Value
     */
    validateInput(value, options = {}) {
        let finalVal = value;
        if (options.purifyJson && !(value instanceof File || value instanceof FileList)) {
            finalVal = this.removeXss(value)
        } else if (value instanceof File || value instanceof FileList) {
            finalVal = value
        } else {
            finalVal = sanitizeHtml(value, {
                allowedAttributes: {
                    '*': ['href', 'align', 'alt', 'center', 'bgcolor', 'style', 'class', 'id']
                }
            })
        }
        return finalVal;
    }

    /**
     * Check if value has JSON structure
     * @param {Mixed} str
     * @returns True/False based on checks
     */
    hasJsonStructure(str = null) {
        if (typeof str !== 'string') return false;
        try {
            const result = JSON.parse(str);
            const type = Object.prototype.toString.call(result);
            return type === '[object Object]' || type === '[object Array]';
        } catch (err) {
            return false;
        }
    }

    /**
     * Removed XSS Data from string
     * @param {Mixed} str
     * @returns Safe JSON String
     */
    removeXss(data) {
        let cleanData = JSON.parse(JSON.stringify(data, this.stripXSS, 2));
        return cleanData;
    }

    /**
     * Malformed Data Remover
     * @param {Mixed} str
     * @returns Safe JSON String
     */
    stripXSS(key, value) {
        // eslint-disable-next-line
        return typeof value !== 'string' ? value : value.replace(/<[^>]*>/g, '').replace(/background(-image)?\s*\:\s*url\s*\(\s*[\"\']?(?!https:\/\/)[^\)]*\)/, ';_split_;').split(/_split_;/)[0];
    }
}

export default AppFormData