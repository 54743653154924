import React, { useState, useRef, useContext, useEffect } from "react";
import './UserProfile.scss';
import ViewUserProfile from "./ViewUserProfile";
import useForm from 'react-hook-form';
import Axios from "utility/Axios";
import configURL from "config/config";
import { toast } from "react-toastify";
import AppContext from 'store/AppContext';
import * as AppActions from "store/actions";
import { connect } from "react-redux";
import FormData from 'utility/AppFormData';
import DatePicker from 'react-date-picker';
import { AiTwotoneMail, AiOutlineQrcode } from "react-icons/ai";
import { BsFillChatLeftFill, BsWhatsapp } from "react-icons/bs";
import { decryptData, encryptData } from 'utility/helper';
import { useHistory, useLocation } from "react-router-dom";


const FeedbackChangePassword = () => {
    const { EziLoader, languageObj } = useContext(AppContext)
    let localStore = decryptData(localStorage.getItem("appState"));
    const userDetail = localStore ? localStore?.user : {};
    const { register, handleSubmit, errors, getValues, setValue } = useForm()
    const [editProfile, setEditProfile] = useState(false);
    const [viewUserData, setViewUserData] = useState(userDetail);
    const [userData, setUserData] = useState(userDetail);
    const [editPwd, setEditPwd] = useState(false);
    const [jobDetails, SetJobDetails] = useState();
    const [openSurveyCount, SetOpenSurveyCount] = useState();
    const imageRef = useRef(null)
    // const [value, onChange] = useState(new Date());
    const appStore = decryptData(localStorage.getItem("appState"));
    const date = appStore?.current_app_detail || { created_at: new Date() };
    let timezone = appStore.user.timezone;
    // var todayDate = new Date();
    // let time = todayDate.getHours() + ":" + todayDate.getMinutes() + ":" + todayDate.getSeconds();
    let utcTime = new Date().getTimezoneOffset("en-US", { timeZone: timezone });
    let timeZoneString = utcTime.toString();
    const history = useHistory();



    const handleChangePassword = (data) => {
        EziLoader.show()

        let formData = new FormData();
        formData.append("old_password", data.old_password)
        formData.append("new_password", data.new_password)
        formData.append("confirmed_password", data.confirmed_password)

        Axios.post(configURL.feedbackChangePassword, formData).then(res => {
            EziLoader.hide()
            if (res.data.success === true) {
                toast.success(res.data.message || "Password changed successfully.")
                setTimeout(() =>history.push("/login"), 1500);
                
            } else {
                toast.warn(res.data.message.toString() || languageObj.translate('report_section.something_went_wrong'))
            }
        }).catch(err => {
            EziLoader.hide()
        })
    }

    

    return (
        <React.Fragment>
            <section className="Page-UserProfile" >
                <form onSubmit={handleSubmit(handleChangePassword)}>
                    <div className="security-wrap">
                        <h1>{languageObj.translate('user.security')}</h1>
                            <div className="security-card">
                                <div className="pass-fld-wrap">
                                    <label>{languageObj.translate('user.current_password')}</label>
                                    <div className="pass-val-control">
                                        <input type="password" className="fld-pass-input" name="old_password" ref={register({ required: true })} />
                                        {errors.old_password && errors.old_password.type === 'required' && <span className={`error-message`}>{languageObj.translate('user.you_must_specify_a_old_password')}</span>}
                                    </div>
                                </div>
                                <div className="pass-fld-wrap">
                                    <label>{languageObj.translate('user.new_password')}</label>
                                    <div className="pass-val-control">
                                        <input type="password" className="fld-pass-input" name="new_password" ref={register({ required: true, minLength: 8 })} />
                                        {errors.new_password && errors.new_password.type === 'required' && <span className={`error-message`}>{languageObj.translate('user.you_must_specify_a_new_password')}</span>}
                                        {errors.new_password && errors.new_password.type === 'minLength' && <span className={`error-message`}>{languageObj.translate('user.new_password_should_be_atleast_8_character_long')}</span>}
                                    </div>
                                </div>
                                <div className="pass-fld-wrap">
                                    <label>{languageObj.translate('user.confirm_new_password')}</label>
                                    <div className="pass-val-control">
                                        <input type="text" className="fld-pass-input" name="confirmed_password" ref={register({
                                            required: true,
                                            validate: value => (value === getValues()["new_password"])
                                        })} />
                                        {errors.confirmed_password && errors.confirmed_password.type === 'required' && <span className={`error-message`}>{languageObj.translate('user.you_must_specify_a_confirmation_password')}</span>}
                                        {errors.confirmed_password && errors.confirmed_password.type === 'validate' && <span className={`error-message`}>{languageObj.translate('settings.the_passwords_do_not_match')}</span>}
                                    </div>
                                </div>
                            </div>
                    </div>
                     <div className="profile-save-wrap">
                        <button className="ezi-btn btn-ripple  ezi-pink-btn profile-save" type="submit">{languageObj.translate('Save.1')}</button>
                        <button className="ezi-btn profile-cancel" onClick={() => {
                            history.push("/");
                        }}>{languageObj.translate('settings.cancel')}</button>
                    </div>
                </form>
            </section>
        </React.Fragment>
    )
}
const mapDispatchToProps = dispatch => {
    return {
        updateUser: () => dispatch(AppActions.updateUser())
    }
}
export default connect(null, mapDispatchToProps)(FeedbackChangePassword);