import React, { useState, useContext } from 'react';
import { Spinner } from 'react-bootstrap';
import Axios from 'utility/Axios';
import configURL from 'config/config';
import { toast } from 'react-toastify';
import { useParams } from "react-router-dom";
import useForm from 'react-hook-form';
import FormData from 'utility/AppFormData';
import AppContext from 'store/AppContext';

const RawDataUpload = () => {
    const [loading, setLoading] = useState(false);
    const { register, handleSubmit, errors } = useForm()
    const urlParams = useParams()
    const { languageObj } = useContext(AppContext)
    /**
     * Upload Raw Data
     * 
     * @param {Object} data 
     */
    const handleSubmitForm = async (data = {}) => {
        try {
            setLoading(true)
            let formData = new FormData()
            formData.append("survey_id", urlParams.survey_id)
            formData.append("file", data.file[0])
            let rawDataDownload = await Axios.post(configURL.raw_data_upload, formData)
            if (rawDataDownload.data.success === true) {
                toast.success(rawDataDownload.data.message || languageObj.translate('settings.data_uploaded'))
            } else {
                toast.warn(rawDataDownload.data.message || languageObj.translate('report_section.something_went_wrong'))
            }
            setLoading(false)
        } catch (error) {
            console.log(error);
            toast.warn(languageObj.translate('report_section.something_went_wrong'))
            setLoading(false)
        }
    }

    /**
     * Download template
     * 
     * @param {Object} data 
     */
    const handleDownloadTemplate = async () => {
        try {
            let formData = new FormData()
            formData.append("survey_id", urlParams.survey_id)
            await Axios.post(configURL.raw_data_download, formData, { responseType: 'blob' }).then(res => {
                if (res?.headers["content-type"] !== "application/json") {
                    const href = URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', urlParams.survey_id + '-' + new Date().toString() + '.xlsx'); //or any other extension
                    document.body.appendChild(link);
                    link.click();

                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                }
                else {
                    toast.warn(languageObj.translate('report_section.file_not_found'))
                }

            })
        } catch (error) {
            console.log(error);
            toast.warn(languageObj.translate('report_section.something_went_wrong'))
        }
    }
    return (
        <div className="raw_data_upload">
            <form onSubmit={handleSubmit(handleSubmitForm)}>
                <p className="">{languageObj.translate('settings.upload_raw_data_for_survey')}</p>
                <div className="raw_data_upload_fields">
                    <input type="file" name="file" ref={register({ required: true })} placeholder={languageObj.translate('settings.raw_data_file')} />
                    {errors.file && <span className={`error-message`}>{languageObj.translate('settings.please_select_file_to_upload')}</span>}
                </div>
                <div className="raw_data_upload_fields">
                    <button type="submit" disabled={loading}>
                        Upload {loading && <Spinner animation="border" />}
                    </button>
                    <button type="button" onClick={handleDownloadTemplate}>{languageObj.translate('DownloadTemplate.1')}</button>
                </div>
            </form>

        </div>
    )
}

export default RawDataUpload;