import React, { useContext, useEffect, useState } from 'react';
import '../assets/scss/navbar.scss';
import { Dropdown } from 'react-bootstrap';
import { useHistory, useLocation } from "react-router-dom";
import AppContext from 'store/AppContext';
import { connect } from "react-redux";
import * as AppActions from "store/actions";
import NavbarNotifications from './NavbarNotifications'
import Axios from "utility/Axios";
import configURL from 'config/config';
import { toast } from 'react-toastify';
import { decryptData } from 'utility/helper'
import GreetUser from '../pages/Dashboard/GreetUser';
import HumburgerMenu from '../assets/images/sidebar/Qznx_humburger_menu.svg';
import DummyUser from '../assets/images/dummy_user.png';
import User from '../assets/images/user.png';


const Navbar = ({ navbarPara, appState, logOutUser, vendorInfo, buValue, BuValues, surveyId, platform }) => {
    const history = useHistory();
    const { languageObj, setSurveyCurrentID ={}, isElectron } = useContext(AppContext);
    let queryParams = new URLSearchParams(window.location.search);
    let header_hide = queryParams.get('header_hide');
    const appStore = decryptData(localStorage.getItem("appState"));
    let userRoles = appStore?.current_app_detail?.tplButton;
    const [newTabData, setNewTabData] = useState("");
    const [surveyData, setSurveyData] = useState(false);
    const [buData, setBuData] = useState([])
    const location = useLocation()
    const [selectedBuValue, setSelectedBuValue] = useState();
    let reportPage = location?.pathname?.includes('/customer-experience');
    /**
     * Logout User
     */
    const handleLogout = () => {
        Axios.post(platform === "feedback" ? configURL.feedbackLogout :configURL.logoutURL).then(response => {
            // if (response.data.success !== undefined && response.data.success === true) {
                localStorage.removeItem('isLogin');
                localStorage.removeItem('appState');
                logOutUser()
                history.push("/login");
                toast.success(response.data.message || languageObj.translate('survey.user_has_been_logout_successfully'));
            // } else {
            //     toast.warn(response.data.message || languageObj.translate('report_section.something_went_wrong'));
            // }
        }).catch(error => {
            let messageWrong = languageObj.translate('report_section.something_went_wrong')
            if (error.response && error.response.status) {
                const { data = {} } = error.response
                toast.warn(data.message || messageWrong)
            }
        })

    }

    let dashboardCEO = appState?.user?.assigned_roles || {};
    /**
     * CEO Dashboard
     */
    const handleCEODashboard = () => {
        Axios.post(configURL.company_token_third_party_login).then(response => {
            if (response.data.success !== undefined && response.data.success === true) {
                let clientURL = response?.data?.client_Url
                let tocken = response?.data?.data
                let newTab = newTabData;
                if (!newTabData || newTabData.closed) {
                    newTab = window.open(`${clientURL + tocken}`, 'sso')
                } else {
                    newTab.focus();
                }
                setNewTabData(newTab);
                // window.location.reload()
                // history.push("/login");
                // toast.success(response.data.message || "");
            } else {
                toast.warn(response.data.message || "User not Found");
            }
        }).catch(error => {
            let messageWrong = languageObj.translate('report_section.something_went_wrong')
            if (error.response && error.response.status) {
                const { data = {} } = error.response
                toast.warn(data.message || messageWrong)
            }
        })

    }
    const handleDefaultBuData = (value) => {
        BuValues({ buValue: value });
    };
    
    const handleBuHeaders = () => {
        let formData = new FormData();
        formData.append("survey_id", surveyId);
        Axios.post(configURL.bu_response, formData).then(response => {
            if (response.data.success !== undefined && response.data.success === true) {
                const data = response?.data?.data || [];
                setSelectedBuValue(data[0]);
                handleDefaultBuData(data[0]); // Set default value
                setBuData(data);
            }
        });
    };
    
    useEffect(() => {
        if (!surveyId === undefined || !reportPage) {
            setSelectedBuValue();   
            setBuData([])
            setSurveyCurrentID("");
            return;
        }
        if(surveyId && reportPage)
        {
            setSurveyData(surveyId);
            handleBuHeaders();
        }
       
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [surveyId, reportPage]);
    
    useEffect(() => {
        BuValues({ buValue: selectedBuValue });
    }, [selectedBuValue, BuValues]);
    
    return (
        <React.Fragment>
            { (appStore?.platform ==="feedback") && (header_hide !== 'true') ? <header className="header feedback-header">
                <div className="menu-wrap">
                    <div className="logo-wrap">
                        { (!(dashboardCEO?.length === 1 &&  dashboardCEO[0]?.includes("CEO")) && platform !== "feedback") ? <div className="humberger">
                            <div className="humberger-inner" onClick={navbarPara.sidebarCollapse}>
                                <img alt="" src={HumburgerMenu} className="header-humburger-icon" />
                                {/* <img className="avtar" alt="" src={(appState && appState?.user && appState?.user?.profile_pic_url) ? appState?.user?.profile_pic_url : require(`../assets/images/user.png`)} /> */}
                            </div>
                        </div>
                    :
                    <div className="humberger">
                    <div className="humberger-inner" onClick={() => history.push("/")}>
                        <img alt="" src={HumburgerMenu} className="header-humburger-icon" />
                        {/* <img className="avtar" alt="" src={(appState && appState?.user && appState?.user?.profile_pic_url) ? appState?.user?.profile_pic_url : require(`../assets/images/user.png`)} /> */}
                    </div>
                </div>
                    }
                        <div className="logo">
                            {/* <img alt="" src={vendorInfo.logo || require(`../assets/images/logo.png`)} className="logo-img" /> */}
                            <GreetUser />
                        </div>
                        <audio id="qaizenx-notification">
                            <source src="/Qaizenx-notification.ogg" type="audio/ogg" />
                        </audio>
                    </div>
                    <div className='bu-header'>
                        {(
                            (surveyData && buData?.length > 0) && <div className="filter-BU">
                                <Dropdown alignRight>
                                    <Dropdown.Toggle id="dropdown-basic">
                                        <div className="user-avtar">
                                            <span>{selectedBuValue || buData[0]}</span>
                                        </div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {buData?.map((item, index) => (
                                            <Dropdown.Item className="dropdown-item-filter-bu" key={index} active={item === selectedBuValue} onClick={() => {setSelectedBuValue(item)}}>
                                                {item}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        )}

                        <div className="notification-info">
                            { (!(dashboardCEO?.length === 1 &&  dashboardCEO[0]?.includes("CEO")) && platform !== "feedback") && <NavbarNotifications />}
                        </div>
                        {/* <div className="languageDropdown">
                            <Dropdown alignRight>
                                <Dropdown.Toggle id="dropdown-basic">
                                    <div className="flag-avtar-wrap">
                                        <label className="country-flag-label">{languageObj.curLang || "En"}</label>
                                    </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {languageObj.languages.length > 0 && languageObj.languages.map((item, index) => (
                                        <Dropdown.Item key={item.id}>
                                            <div className="flag-img-wrap" onClick={() => languageObj.changeLang(item.lang_code)}>
                                                <label className="country-flag-label">{item.lang_code}</label>
                                            </div>
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div> */}
                        <div className="notification-info">
                            <span className='noti-icon' onClick = {() => history.push('/live-chat')}><span className='dot'></span></span>
                        </div>
                        {!isElectron && <div className="user-info">
                            <div className="user-detail">
                                <img className="avtar" alt="" src={DummyUser} />
                                <span className="test"></span>
                                {/* <label>{(appState?.user && appState?.user.name) || "User"}</label>
                                <span>{(appState?.current_app_detail && appState?.current_app_detail.name) || "Unknown"}</span> */}
                            </div>
                            <div className="userInfoDropdown">
                                <Dropdown alignRight>
                                    <Dropdown.Toggle id="dropdown-basic">
                                        <div className="user-avtar">
                                            <span>{languageObj.translate("welcome_back.1")}</span>
                                            <label>{(appState?.user && appState?.user.name) || "User"}</label>
                                            {/* <span>{(appState?.current_app_detail && appState?.current_app_detail.name) || "Unknown"}</span> */}
                                        </div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {platform !== "feedback" ? <Dropdown.Item onClick={() => history.push("/user-profile")}>{languageObj.translate('Profile.1')}</Dropdown.Item>
                                        :
                                        <Dropdown.Item onClick={() => history.push("/change-password")}>{languageObj.translate('ChangePassword.1')}</Dropdown.Item>
                                        }
                                        {/* <Dropdown.Item>{languageObj.translate('ChangeEmail.1')}</Dropdown.Item> */}
                                        {userRoles === "true" && <Dropdown.Item onClick={() => { handleCEODashboard() }}>{languageObj.translate("Switch_to_CEO_Dashboard.1")}</Dropdown.Item>}
                                        <Dropdown.Item onClick={handleLogout}><span className="user-indication">{languageObj.translate('Not.1')} {(appState?.user && appState?.user?.name) || "User"}?</span> {languageObj.translate('SignOut.1')}</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>}
                    </div>
                    <div className="responsive-menu-wrap">
                        <div className="userInfoDropdown">
                            <Dropdown alignRight>
                                <Dropdown.Toggle id="dropdown-basic">
                                    <div className="user-avtar">
                                        <img className="avtar" alt="" src={(appState && appState?.user && appState?.user?.profile_pic_url) ? appState?.user.profile_pic_url : User} />
                                        <span className="test"></span>
                                    </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => history.push("/user-profile")}>{languageObj.translate('Profile.1')}</Dropdown.Item>
                                    <Dropdown.Item onClick={handleLogout}><span className="user-indication">{languageObj.translate('Not.1')} {(appState?.user && appState?.user.name) || "User"}?</span> {languageObj.translate('SignOut.1')}</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </header>:
             <header className="header">
             <div className="menu-wrap">
                 <div className="logo-wrap">
                     <div className="humberger">
                     {platform !== "feedback" ? <div className="humberger-inner" onClick={navbarPara.sidebarCollapse}>
                             <img alt="" src={HumburgerMenu} className="header-humburger-icon" />
                             {/* <img className="avtar" alt="" src={(appState && appState?.user && appState?.user?.profile_pic_url) ? appState?.user?.profile_pic_url : require(`../assets/images/user.png`)} /> */}
                         </div>
                         :
                         <div className="humberger-inner">
                         <img alt="" src={HumburgerMenu} className="header-humburger-icon" />
                         {/* <img className="avtar" alt="" src={(appState && appState?.user && appState?.user?.profile_pic_url) ? appState?.user?.profile_pic_url : require(`../assets/images/user.png`)} /> */}
                         </div>
                         }
                     </div>
                     <div className="logo">
                         {/* <img alt="" src={vendorInfo.logo || require(`../assets/images/logo.png`)} className="logo-img" /> */}
                         <GreetUser />
                     </div>
                     <audio id="qaizenx-notification">
                         <source src="/Qaizenx-notification.ogg" type="audio/ogg" />
                     </audio>
                 </div>
                 <div className='bu-header'>
                     {(
                         (surveyData && buData?.length > 0) && <div className="filter-BU">
                             <Dropdown alignRight>
                                 <Dropdown.Toggle id="dropdown-basic">
                                     <div className="user-avtar">
                                         <span>{selectedBuValue || buData[0]}</span>
                                     </div>
                                 </Dropdown.Toggle>
                                 <Dropdown.Menu>
                                     {buData?.map((item, index) => (
                                         <Dropdown.Item className="dropdown-item-filter-bu" key={index} active={item === selectedBuValue} onClick={() => {setSelectedBuValue(item)}}>
                                             {item}
                                         </Dropdown.Item>
                                     ))}
                                 </Dropdown.Menu>
                             </Dropdown>
                         </div>
                     )}

                     {platform !== "feedback" && <div className="notification-info">
                         <NavbarNotifications />
                     </div>
                     }
                     {/* <div className="languageDropdown">
                         <Dropdown alignRight>
                             <Dropdown.Toggle id="dropdown-basic">
                                 <div className="flag-avtar-wrap">
                                     <label className="country-flag-label">{languageObj.curLang || "En"}</label>
                                 </div>
                             </Dropdown.Toggle>
                             <Dropdown.Menu>
                                 {languageObj.languages.length > 0 && languageObj.languages.map((item, index) => (
                                     <Dropdown.Item key={item.id}>
                                         <div className="flag-img-wrap" onClick={() => languageObj.changeLang(item.lang_code)}>
                                             <label className="country-flag-label">{item.lang_code}</label>
                                         </div>
                                     </Dropdown.Item>
                                 ))}
                             </Dropdown.Menu>
                         </Dropdown>
                     </div> */}
                     <div className="user-info">
                         <div className="user-detail">
                             <img className="avtar" alt="" src={DummyUser} />
                             <span className="test"></span>
                             {/* <label>{(appState?.user && appState?.user.name) || "User"}</label>
                             <span>{(appState?.current_app_detail && appState?.current_app_detail.name) || "Unknown"}</span> */}
                         </div>
                         <div className="userInfoDropdown">
                             <Dropdown alignRight>
                                 <Dropdown.Toggle id="dropdown-basic">
                                     <div className="user-avtar">
                                         <span>Welcome back</span><br></br>
                                         <label>{(appState?.user && appState?.user.name) || "User"}</label>
                                         {/* <span>{(appState?.current_app_detail && appState?.current_app_detail.name) || "Unknown"}</span> */}
                                     </div>
                                 </Dropdown.Toggle>
                                 <Dropdown.Menu>
                                     {platform !== "feedback" ? <Dropdown.Item onClick={() => history.push("/user-profile")}>{languageObj.translate('Profile.1')}</Dropdown.Item>
                                     :
                                     <Dropdown.Item onClick={() => history.push("/change-password")}>{languageObj.translate('ChangePassword.1')}</Dropdown.Item>
                                     }
                                     {/* <Dropdown.Item>{languageObj.translate('ChangeEmail.1')}</Dropdown.Item> */}
                                     {userRoles === "true" && <Dropdown.Item onClick={() => { handleCEODashboard() }}>Switch to CEO Dashboard</Dropdown.Item>}
                                     <Dropdown.Item onClick={handleLogout}><span className="user-indication">{languageObj.translate('Not.1')} {(appState?.user && appState?.user?.name) || "User"}?</span> {languageObj.translate('SignOut.1')}</Dropdown.Item>
                                 </Dropdown.Menu>
                             </Dropdown>
                         </div>
                     </div>
                 </div>
                 <div className="responsive-menu-wrap">
                     <div className="userInfoDropdown">
                         <Dropdown alignRight>
                             <Dropdown.Toggle id="dropdown-basic">
                                 <div className="user-avtar">
                                     <img className="avtar" alt="" src={(appState && appState?.user && appState?.user?.profile_pic_url) ? appState?.user.profile_pic_url : User} />
                                     <span className="test"></span>
                                 </div>
                             </Dropdown.Toggle>
                             <Dropdown.Menu>
                                 <Dropdown.Item onClick={() => history.push("/user-profile")}>{languageObj.translate('Profile.1')}</Dropdown.Item>
                                 <Dropdown.Item onClick={handleLogout}><span className="user-indication">{languageObj.translate('Not.1')} {(appState?.user && appState?.user.name) || "User"}?</span> {languageObj.translate('SignOut.1')}</Dropdown.Item>
                             </Dropdown.Menu>
                         </Dropdown>
                     </div>
                 </div>
             </div>
         </header>
            }
        </React.Fragment>
    )
}

const mapStateToProps = state => {
    return {
        appState: state.app.appState,
        vendorInfo: state.app.vendorInfo,
        buValue: state.report.buValue
    }
}

const mapDispatchToProps = dispatch => {
    return {
        logOutUser: () => dispatch(AppActions.logOutUser()),
        BuValues: (data) => dispatch(AppActions.buValue(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);